import {
    Alert,
    // Button,
    DatePicker,
    Input,
    InputNumber,
    Table,
    FormItem,
    Option,
    Icon,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    RadioGroup,
    Radio,
    Cascader,
    CheckboxGroup,
    Checkbox,
    Switch,
    Message,
    Modal,
    Page,
    Row,
    Tree,
    Tabs,
    TabPane,
    Collapse,
    Panel,
    Badge,
    CellGroup,
    Cell,
    Progress,
    Menu,
    MenuItem,
    Card,
    TimePicker,
    Submenu
} from 'view-design';
import Select from './components/Select.vue';
import Form from './components/Form.vue';
import Col from './components/Col.vue';
import AuthButton from "@/components/AuthButton";
import AuthDropdownItem from "@/components/AuthDropdownItem";
const Button = AuthButton;
// const DropdownItem = AuthDropdownItem;

InputNumber.props = {
    ...InputNumber.props,
    value: {
        type: Number,
        default: 0
    },
    activeChange: {
        type: Boolean,
        default: false,
    }
};

export default [
    Alert,
    Card,
    CellGroup,
    Cell,
    Button,
    Submenu,
    {
        name: 'DatePicker',
        extends: DatePicker
    },
    {
        name: 'TimePicker',
        extends: TimePicker
    },
    Input,
    InputNumber,
    Table,
    Form,
    FormItem,
    Select,
    {
        name: 'Option',
        extends: Option
    },
    Icon,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    RadioGroup,
    Radio,
    Cascader,
    CheckboxGroup,
    Checkbox,
    {
        name: 'ISwitch',
        extends: Switch
    },
    Message,
    Modal,
    Page,
    Row,
    Badge,
    Col,
    Tree,
    Tabs,
    TabPane,
    Collapse,
    Panel,
    Progress,
    Menu,
    MenuItem,

];
