import httpRequest from "../utils/httpRequest";
import Plugin from "@/plugins";

const SERVER_NAME = "base";

/**
 * 登录
 * @param {Object} data
 * @param {string} data.username - 用户名
 * @param {string} data.password - 密码
 */
export function login(data) {
    return httpRequest({
        url: "/user/login",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 数据字典-搜索字典
 * @param {Object} data
 * @param {string | Null} [data.name] - 字典名称
 */
export function queryDictionary(data) {
    return httpRequest(
        {
            url: "/dictionary/query",
            serverName: SERVER_NAME,
            method: "post",
            data,
        },
        {
            closeErrorMessage: true,
        }
    );
}
/**
 * 数据字典-搜索字典
 * @param {Object} data
 * @param {string | Null} [data.name] - 字典名称
 * @param {string | Null} [data.code] - 字典名称
 */
export function dictionarySave(data) {
    return httpRequest({
        url: "/dictionary/save",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}
/**
 * 数据字典-搜索字典
 * @param {Object} params
 * @param {string | Null} params.id - 字典id
 */
export function dictionaryDelete(params) {
    return httpRequest({
        url: "/dictionary/delete",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 数据字典-查询字典详情
 * @param {Object} data
 * @param {string} data.dictionaryId - 字典id
 * @param {number} data.pageNum - 页码
 * @param {number} data.pageSize - 条目数
 */
export function queryDictionaryValue(data) {
    return httpRequest({
        url: "/dictionary-value/query",
        serverName: SERVER_NAME,
        method: "post",
        headers: {
            applicationCode: "1048",
        },
        data,
    });
}

/**
 * 数据字典-保存
 * @param {Object} data
 * @param {string} data.applicationId - 应用id
 * @param {string} [data.code] - 字典值code
 * @param {string} data.id - 字典值id
 * @param {string} data.value - 字典值code
 * @param {string} data.dictionaryId - 字典id
 * @param {string} data.name - 字典值name
 * @param {string} data.dictionaryCode - 字典code
 * @param {string} data.dictionaryName - 字典name
 * @param {number} data.pageNum - 页码
 * @param {number} data.pageSize - 条目数
 */
export function queryDictionarySave(data) {
    return httpRequest({
        url: "/dictionary-value/save",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 数据字典-保存
 * @param {Object} params
 * @param {string} params.id - 字典值id
 */
export function queryDictionaryDelete(params) {
    return httpRequest({
        url: "/dictionary-value/delete",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 获取往来单位
 * @param {Object} params
 * @param {string} params.unitId 往来单位id
 * @return {Promise<AxiosResponse<any>>}
 */
export function getUnitByUnitName(params) {
    return httpRequest({
        url: "/unit/getByUnitName",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 获取往来单位明细
 * @param {string} id - 单位id
 * @return {Promise<AxiosResponse<any>>}
 */
export function getUnitDetail(id) {
    return httpRequest({
        url: `/unit/get/${id}`,
        serverName: SERVER_NAME,
        method: "get",
    });
}

/**
 * 获取往来单位账户
 * @param {string} params.unitId 往来单位id
 */
export function getUnitAccountByUnitId(params) {
    return httpRequest({
        url: "/unitAccount/queryByUnitId",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 用户登录
 * @param {Object} data
 * @param {string} data.userName - 用户名
 * @param {string} data.password - 密码
 * @return {Promise<*>|*}
 */
export function userLogin(data) {
    return httpRequest(
        {
            url: "/user/login",
            serverName: SERVER_NAME,
            method: "post",
            headers: {
                applicationCode: "1048",
            },
            data,
        },
        {
            resolveError: true,
        }
    );
}

/**
 * 用户注册
 * @param {Object} data
 * @param {string} data.userName - 用户名
 * @param {string} data.password - 密码
 * @param {string} data.phone - 手机号
 * @param {string} data.verifyCode - 验证码
 * @return {Promise<*>|*}
 */
export function userRegister(data) {
    return httpRequest({
        url: "/user/register",
        serverName: SERVER_NAME,
        method: "post",
        headers: {
            applicationCode: "1048",
        },
        data,
    });
}

/**
 * 获取短信验证码
 * @param {Object} data
 * @param {string} data.phone - 手机号
 */
export function getCode(data) {
    return httpRequest({
        url: "/user/getSmsVerCode",
        serverName: SERVER_NAME,
        method: "post",
        headers: {
            applicationCode: "1048",
        },
        data,
    });
}

/**
 * 加入公司
 * @param {Object} params
 * @param {string} params.inviteCode - 邀请码
 * @param {string} params.userId - 用户id
 */
export function joinCompany(params) {
    return httpRequest({
        url: "/employee/bindEmployee",
        serverName: SERVER_NAME,
        method: "get",
        headers: {
            applicationCode: "1048",
        },
        params,
    });
}

/**
 * 重置密码
 * @param {Object} params
 * @param {string} params.password - 密码：123456
 * @param {string} params.id - 用户id
 */
export function resetPassword(params) {
    return httpRequest({
        url: "/user/resetPassword",
        serverName: SERVER_NAME,
        method: "get",
        headers: {
            applicationCode: "1048",
        },
        params,
    });
}

/**
 * 退出登录
 */
export function logout() {
    return httpRequest({
        url: "/user/logout",
        serverName: SERVER_NAME,
        method: "get",
        headers: {
            applicationCode: "1048",
        },
    });
}

/**
 * 获取验证码
 * @return {Promise<*>|*}
 */
export function getVerifyCode() {
    return httpRequest({
        url: "/user/getVerifyCode",
        method: "get",
        serverName: SERVER_NAME,
        headers: {
            applicationCode: "1048",
        },
    });
}

/**
 * 切换公司
 * @param {Object} params
 * @param {string} params.applicationCode - 应用code(erp为1000)
 * @param {string} params.orgId - 机构id
 */
export function switchCompany(params) {
    return httpRequest({
        url: "/user/switchCompany",
        method: "get",
        serverName: SERVER_NAME,
        headers: {
            applicationCode: "1048",
        },
        params,
    });
}
/**
 * 查询业务员
 * @param {Object} data
 * @param {string} [data.nameAllLike] - 名称模糊搜索
 * @param {string} [data.code] - code精确查询
 * @return {Promise<*>|*}
 */
export function getBusinessUser(data) {
    data.orgId = Plugin.store.state.comon.userInfo.orgId;
    return httpRequest({
        url: "/employee/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 查询认证信息-列表
 * @param {Object} params
 * @return {Promise<*>|*}
 */
export function getAuthenticateList(data) {
    return httpRequest({
        url: "/unitAuthenticate/audit/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 查询认证信息详情
 * @param {Object} params
 * @return {Promise<*>|*}
 */
export function getAuthenticateById(params) {
    return httpRequest({
        url: "/unitAuthenticate/audit/get",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 企业认证
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function unitAuthenticateAudit(data) {
    return httpRequest({
        url: "unitAuthenticate/audit/submit",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 应用管理-列表
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function getApplicationPage(data) {
    return httpRequest({
        url: "/application/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 应用管理-新增
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function applicationAdd(data) {
    return httpRequest({
        url: "/application/add",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 应用管理-修改
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function applicationUpdate(data) {
    return httpRequest({
        url: "/application/update",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 应用管理-禁用/启用
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function applicationEnable(data) {
    return httpRequest({
        url: "/application/startAndStop",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 应用管理-删除应用
 * @param {Object} params
 * @return {Promise<*>|*}
 */
export function applicationDelete(params) {
    return httpRequest({
        url: "/application/delete",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 应用管理-列表
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function getApplicationList(data) {
    return httpRequest({
        url: "/application/query",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 应用管理-列表
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function getAllApplication(data) {
    return httpRequest({
        url: "/versions/getAllApplication",
        serverName: SERVER_NAME,
        method: "get",
        data,
    });
}

/**
 * 资源管理-树形列表
 * @param {Object} params
 * @return {Promise<*>|*}
 */
export function getAllResourceTree(params) {
    return httpRequest({
        url: "/role/template/roleTemplateResource",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 资源管理-树形列表
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function getResourceTree(data) {
    return httpRequest({
        url: "/resource/queryResourceAll",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 资源管理-查询应用下的树形列表
 * @param {Object} params
 * @return {Promise<*>|*}
 */
export function getApplicationResource(params) {
    return httpRequest({
        url: "/resource/queryTree",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 资源管理-新增
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function resourceAdd(data) {
    return httpRequest({
        url: "/resource/add",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 资源管理-修改
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function resourceUpdate(data) {
    return httpRequest({
        url: "/resource/update",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 资源管理-删除
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function resourceDelete(params) {
    return httpRequest({
        url: "/resource/delete",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 角色管理-列表
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function getRoleTemplatePage(data) {
    return httpRequest({
        url: "/role/template/query",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 角色管理-新增
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function roleTemplateAdd(data) {
    return httpRequest({
        url: "/role/template/save",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 角色管理-修改
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function roleTemplateUpdate(data) {
    return httpRequest({
        url: "/role/template/update",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 角色管理-删除
 * @param {Object} params
 * @return {Promise<*>|*}
 */
export function roleTemplateDelete(params) {
    return httpRequest({
        url: "/role/template/delete",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 角色管理-新增权限
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function addRoleResourceTemplate(data) {
    return httpRequest({
        url: "/role/template/addRoleResourceTemplate",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 公司管理-获取公司列表
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function queryUnitPage(data) {
    return httpRequest({
        url: "/unit/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 公司管理-启用/停用
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function updateStatus(data) {
    return httpRequest({
        url: "/unit/startAndStop",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 应用管理-获取单位下的应用权限信息
 * @param {Object} params
 * @return {Promise<*>|*}
 */
export function getApplicationByUnitId(params) {
    return httpRequest({
        url: "/application/getApplicationByUnitId",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 公司管理-编辑应用权限
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function editPermission(data) {
    return httpRequest({
        url: "/unitAuthenticate/editPermission",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 登录日志-列表查询
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function logLoginQuery(data) {
    return httpRequest({
        url: "/log-login/query",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 登录日志-批量删除
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function batchRemoveByIds(data) {
    return httpRequest({
        url: "/log-login/batchRemoveByIds",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 登录日志-清空日志
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function logLoginClear() {
    return httpRequest({
        url: "/log-login/clear",
        serverName: SERVER_NAME,
        method: "get",
    });
}

/**
 * 登录日志-导出excel
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function logLoginExport(data) {
    return httpRequest({
        url: "/log-login/export",
        serverName: SERVER_NAME,
        responseType: "blob",
        method: "post",
        data: data,
    });
}

/**
 * 公司管理-获取用户列表
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function queryUserPage(data) {
    return httpRequest({
        url: "/user/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 公司管理-查看详情
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function queryUserById(params) {
    return httpRequest({
        url: "/user/queryById",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 接口管理-查询
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function interfaceSearch(data) {
    return httpRequest({
        url: "/interface/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 接口管理-新增
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function interfaceAdd(data) {
    return httpRequest({
        url: "/interface/interfaceAdd",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 接口管理-修改
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function interfaceUpdate(data) {
    return httpRequest({
        url: "/interface/edit",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 接口管理-删除
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function interfaceDelete(params) {
    return httpRequest({
        url: "/interface/delete",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 资源管理-条件查询
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function queryResourceList(data) {
    return httpRequest({
        url: "/resource/queryList",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 权限集管理-查询
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function permissionSetSearch(data) {
    return httpRequest({
        url: "/permissionSet/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 权限集管理-新增
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function permissionSetAdd(data) {
    return httpRequest({
        url: "/permissionSet/add",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 权限集管理-修改
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function permissionSetUpdate(data) {
    return httpRequest({
        url: "/permissionSet/edit",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 权限集管理-删除
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function permissionSetDelete(params) {
    return httpRequest({
        url: "/permissionSet/delete",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}
/**
 * 权限集管理-根据id查询
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function permissionSetGet(params) {
    return httpRequest({
        url: "/permissionSet/get",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 员工认证审核-获取用户列表
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function queryEmployeePage(data) {
    return httpRequest({
        url: "/employee/queryEmployeePage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 员工认证审核-根据id查询详情
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function getEmployeeById(params) {
    return httpRequest({
        url: "/employee/getById",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 员工认证审核-审核操作
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function auditEmployeePage(data) {
    return httpRequest({
        url: "/employee/audit",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 操作日志-列表查询
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function logOperationQuery(data) {
    return httpRequest({
        url: "/opreateLog/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 权限操作日志-列表查询
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function logAuthQuery(data) {
    return httpRequest({
        url: "/operatePermissionRoleLog/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 获取登录验证码 有则显示
 * @return {Promise<*>|*}
 */
export async function getSmsCode(params) {
    return httpRequest(
        {
            url: "/user/getGraphVerifyCode",
            serverName: SERVER_NAME,
            method: "get",
            params,
        },
        {
            closeErrorMessage: true,
            resolveError: true,
        }
    );
}
/**
 * 验证验证码
 * @param {Object} data
 * @param {string} data.phone - 手机号
 * @param {string} data.verifyCode - 验证码
 * @return {Promise<*>|*}
 */
export function validateCode(data) {
    return httpRequest({
        url: "/shopping/sms/verifyCode",
        serverName: SERVER_NAME,
        method: "POST",
        headers: {
            applicationCode: data.applicationCode || "1025",
        },
        data,
    });
}

/**
 * 确认协议
 * @return {Promise<*>|*}
 */
export async function editUserAgreementConfirmationFlag(params) {
    return httpRequest({
        url: "/user/editUserAgreementConfirmationFlag",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 公司管理 -- 获取历史开通列表
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function getHistory(params) {
    return httpRequest({
        url: "/orgApplication/getHistory",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 公司管理 -- 新增开通应用权限
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function addApplication(data) {
    return httpRequest({
        url: "/orgApplication/add",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 公司管理 -- 设置用户数
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function allowUsers(params) {
    return httpRequest({
        url: "/org/edit/allowUsers",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 公司管理 -- 根据UnitId查询允许员工数
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function getByUnitId(params) {
    return httpRequest({
        url: "/org/getAllUsersByUnitId",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}
// 获取公司列表
export function getCompany(data) {
    return httpRequest({
        url: "/org/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}
