import httpRequest from "../utils/httpRequest";
const SERVER_NAME = 'platform';

/**
 * 品牌商家-分页查询
 * @param {Object} data
 */
export function pageQuery(data = {}) {
    return httpRequest({
        url: '/brand-merchant-manage/pageQuery',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 品牌商家-新增
 * @param {Object} data
 */
export function addBrandMerchant(data = {}) {
    return httpRequest({
        url: '/brand-merchant-manage/add',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 品牌商家-编辑
 * @param {Object} data
 */
export function updateBrandMerchant(data = {}) {
    return httpRequest({
        url: '/brand-merchant-manage/update',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 品牌商家-操作展示和不展示
 * @param {Object} data
 */
export function showOrCancelShow(data = {}) {
    return httpRequest({
        url: '/brand-merchant-manage/showOrCancelShow',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 品牌商家-查看详情
 * @param {Object} data
 */
export function particulars(params = {}) {
    return httpRequest({
        url: '/brand-merchant-manage/particulars',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 品牌商家-删除
 * @param {Object} data
 */
export function deleteBrandMerchant(params = {}) {
    return httpRequest({
        url: '/brand-merchant-manage/delete',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 品牌商家-获取当前最大排序
 * @param {Object} data
 */
export function getMaxSort(params = {}) {
    return httpRequest({
        url: '/brand-merchant-manage/getMaxSort',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 品牌商家-获取卖家权限的列表
 * @param {Object} data
 */
export function getUnitByApplicationCode(data = {}) {
    data.applicationCode = '1030';
    return httpRequest({
        url: '/unit/queryUnitByApplicationCode',
        serverName: 'base',
        method: 'post',
        data
    });
}

/**
 * 品牌商家-根据id查询关注列表
 * @param {Object} data
 */
export function queryByBrandMerchantId(params = {}) {
    return httpRequest({
        url: '/brand-merchant-attention/queryByBrandMerchantId',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}