var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-wrap"},_vm._l((_vm.list),function(item){return _c('div',{key:item.index,class:[
            'step-item',
            { 'step-item-pass': _vm.getStatus(item.index) === 'pass' },
            { 'step-item-error': _vm.getStatus(item.index) === 'error' },
            { 'step-item-progress': _vm.getStatus(item.index) === 'progress' } ]},[_c('div',{staticClass:"step-item-hd"},[(_vm.type === 'number')?_c('div',{staticClass:"item-mark item-mark-number",style:({
                    width: _vm.size + 'px',
                    height: _vm.size + 'px',
                    lineHeight: (_vm.size-4) + 'px'
                })},[(_vm.getStatus(item.index) === 'progress' || item.index > _vm.value)?_c('span',[_vm._v(_vm._s(item.index))]):_vm._e(),(_vm.getStatus(item.index) === 'pass')?_c('Icon',{attrs:{"type":"md-checkmark","size":_vm.size-4,"color":"#fff"}}):_vm._e(),(_vm.getStatus(item.index) === 'error')?_c('Icon',{attrs:{"type":"md-close","size":_vm.size-4,"color":"#fff"}}):_vm._e()],1):_c('div',{staticClass:"item-mark item-mark-circle"},[_c('div',{staticClass:"mark-circle-point"})]),(item.index !== _vm.list.length)?_c('i',{staticClass:"item-mark-line"}):_vm._e()]),_c('p',{staticClass:"step-item-bd"},[_vm._v(_vm._s(item.title))]),(item.subTitle)?_c('p',{staticClass:"step-item-ft"},[_vm._v(_vm._s(item.subTitle))]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }