/* eslint-disable space-before-function-paren */
import {isEmpty} from "./func";
import Store from '../plugins/Store';

export default class Comon {
    /**
     * @description 获取对象类型
     * @param {Any} o 被检查的对象
     * @returns 数据类型
     */
    getType(o) {
        return Object.prototype.toString.call(o).slice(8, -1);
    }

    /**
     * @description 检查是否为数字
     * @param {Any} v 被检查的数据
     * @returns true: 数字, false: 非数字
     */
    isNumber(value) {
        let v = value;

        if (this.getType(v) === 'String') v = v.trim();
        return !isNaN(v) && v !== null && v !== '';
    }

    /**
     * @description 检查是否为空
     * @param {Any} value 被检查的数据
     * @param {Boolean} strict 是否开启严格检查（0、[]、{}、'null'、'undefined'都会视为空）
     * @returns true: 非空, false: 空
     */
    isNotEmpty(value, strict) {
        let v = value;

        const { getType } = this;

        if (getType(v) === 'String') v = v.trim();
        if (strict) {
            switch (true) {
            case getType(v) === 'Array':
                return v.length;
            case getType(v) === 'Object':
                return Object.keys(v).length;
            default:
                return v && v !== '0' && v !== 'null' && v !== 'undefined';
            }
        } else return v || v === 0;
    }

    /**
     * @description 深度拷贝
     * @param {*} o 原对象
     * @returns 新对象
     */
    deepCopy(o) {
        let m;
        const { getType, deepCopy } = this;

        switch (true) {
        case getType(o) === 'Object':
            m = {};
            let key, val;
            for ([key, val] of Object.entries(o)) {
                m[key] = deepCopy(val);
            }
            break;
        case getType(o) === 'Array':
            m = [];
            o.forEach(tmp => {
                m.push(deepCopy(tmp));
            });
            break;
        default:
            m = o;
        }
        return m;
    }

    /**
     * @description 防抖函数
     * @param {*} fn 接受
     * @param {*} delay
     */
    debounce(fn, delay = 128) {
        let timer;

        return function() {
            const self = this,
                args = arguments;

            timer && clearTimeout(timer);
            timer = setTimeout(function() {
                fn.apply(self, args);
            }, delay);
        };
    }

    /**
     * 获取字典值
     * @param {string} dictName - 字典名称
     * @param {string} [dictCode] - 字典值
     */
    $dict(dictName, dictCode) {
        const dict = Store.state.comon.dictData[dictName] || [],
            cur = dict.find(m => m.value === dictCode) || {};

        if (isEmpty(dictCode)) return dict;
        return cur.name;
    }
}
