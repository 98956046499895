import httpRequest from "../utils/httpRequest";
const SERVER_NAME = 'entrance';
// const SERVER_NAME = '';

/**
 * 获取库存汇总列表
 * @param {Object} data
 */
export function getStockPool(data) {
    return httpRequest({
        url: '/stockSummary/configQuery',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取库存流水列表
 * @param {Object} data
 */
export function getStockFlow(data) {
    return httpRequest({
        url: '/stockRecord/configQuery',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取批次库存列表
 * @param {Object} data
 */
export function getStockBatch(data) {
    return httpRequest({
        url: '/stockBatch/configQuery',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取供应商、货主自动完成框
 * @param {Object} data
 */
export function getUnitAll(data) {
    return httpRequest({
        url: '/contactsUnit/queryList',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    },{
        closeErrorMessage: true
    });
}