<script>
import {DropdownItem} from "view-design";

export default {
    name: "DropdownItem",
    props: {
        ...DropdownItem.props,
        authSymbol: {
            type: String,
            default: null
        }
    },
    render(createElement, context) {
        const authMap = this.AUTH_MAP[this.$route.path] || {};
        const btnSymbolInfo = authMap[this.authSymbol];
        if (this.authSymbol && !btnSymbolInfo) return null;
        const btnText = btnSymbolInfo?.resourceName || this.$scopedSlots.default();
        return <DropdownItem on={this.$listeners} props={this.$props} attrs={this.$attrs}>{btnText}</DropdownItem>;
    }
};
</script>

