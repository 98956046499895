<!--基础Form表单-->
<template>
    <Form
        ref="FormRef"
        :model="myFormData"
        v-bind="myFormProps"
        style="padding-top: 20px;"
    >
        <FormItem
            v-for="(item, index) of myFormList"
            v-show="
                item.isShow || (item.isShow == null && item.isShow !== false)
            "
            :key="item.key"
            :prop="item.key"
            v-bind="item.itemProps"
        >
            <div v-if="$slots[item.formItemLeftSlot]" class="inline-block">
                <slot :name="item.formItemLeftSlot" />
            </div>
            <Select
                v-if="item._type === 'Select'"
                v-model="myFormData[item.key]"
                v-bind="item.props"
                clearable
                :size="(item.props && item.props.size) || 'small'"
                filterable
                :style="{
                    width: `${(item.props && item.props.width) || 180}px`
                }"
                :remote-method="
                    item.remote
                        ? v =>
                              remoteSelect({
                                  value: v,
                                  item
                              })
                        : undefined
                "
                :loading="selectLoading[item.key]"
                @on-change="
                    change({
                        value: $event,
                        key: item.key,
                        index,
                        item
                    })
                "
            >
                <Option
                    v-for="ele in item.options"
                    :key="ele[item.valueKey || 'value']"
                    :value="ele[item.valueKey || 'value']"
                    >{{ ele[item.labelKey || 'label'] }}</Option
                >
            </Select>
            <DatePicker
                v-else-if="item._type === 'DatePicker'"
                v-model="myFormData[item.key]"
                v-bind="item.props"
                clearable
                :size="(item.props && item.props.size) || 'small'"
                :style="{
                    width: `${(item.props && item.props.width) || 180}px`
                }"
                @on-change="
                    change({
                        value: $event,
                        key: item.key,
                        index,
                        item
                    })
                "
            />
            <RadioGroup
                v-else-if="item._type === 'RadioGroup'"
                v-model="myFormData[item.key]"
                v-bind="item.props"
                clearable
                :size="(item.props && item.props.size) || 'small'"
                :style="{
                    width: `${(item.props && item.props.width) || 180}px`
                }"
                @on-change="
                    change({
                        value: $event,
                        key: item.key,
                        index,
                        item
                    })
                "
            >
                <Radio
                    v-for="ele in item.options"
                    :key="ele[item.valueKey || 'value']"
                    :label="ele[item.valueKey || 'value']"
                    :value="ele[item.valueKey || 'value']"
                >
                    <Icon v-if="ele.iconProps"></Icon>
                    <span v-text="ele[item.labelKey || 'label']"></span>
                </Radio>
            </RadioGroup>
            <Cascader
                v-else-if="item._type === 'Cascader'"
                v-model="myFormData[item.key]"
                v-bind="item.props"
                clearable
                :size="(item.props && item.props.size) || 'small'"
                filterable
                :data="item.options"
                :style="{
                    width: `${(item.props && item.props.width) || 180}px`,
                    marginTop: `${(item.props && item.props.marginTop) || 4}px`
                }"
                @on-change="
                    change({
                        value: $event,
                        key: item.key,
                        index,
                        item
                    })
                "
            ></Cascader>
            <CheckboxGroup
                v-else-if="item._type === 'CheckboxGroup'"
                v-model="myFormData[item.key]"
                v-bind="item.props"
                clearable
                :size="(item.props && item.props.size) || 'small'"
                :style="{
                    width: `${(item.props && item.props.width) || 180}px`
                }"
                @on-change="
                    change({
                        value: $event,
                        key: item.key,
                        index,
                        item
                    })
                "
            >
                <Checkbox
                    v-for="ele in item.options"
                    :key="ele[item.valueKey || 'value']"
                    :label="ele[item.valueKey || 'value']"
                    :value="ele[item.valueKey || 'value']"
                >
                    <Icon v-if="ele.iconProps"></Icon>
                    <span v-text="ele[item.labelKey || 'label']"></span>
                </Checkbox>
            </CheckboxGroup>
            <ISwitch
                v-else-if="item._type === 'Switch'"
                v-model="myFormData[item.key]"
                v-bind="item.props"
                clearable
                :size="(item.props && item.props.size) || 'small'"
                @on-change="
                    change({
                        value: $event,
                        key: item.key,
                        index,
                        item
                    })
                "
            />
            <Input
                v-else
                v-model="myFormData[item.key]"
                v-bind="item.props"
                clearable
                :size="(item.props && item.props.size) || 'small'"
                :style="{
                    width: `${(item.props && item.props.width) || 180}px`
                }"
                @on-change="
                    change({
                        value: $event.target.value,
                        key: item.key,
                        index,
                        item
                    })
                "
                @on-enter="$emit('on-enter')"
            />
            <div v-if="$slots[item.formItemRightSlot]" class="inline-block">
                <slot :name="item.formItemRightSlot" />
            </div>
        </FormItem>
        <FormItem v-if="myBtns.length && hasBtns">
            <Button
                v-for="item of myBtns"
                :key="item.name"
                :size="(item.props && item.props.size) || 'small'"
                v-bind="item.props"
                :style="{ marginLeft: `${item.marginLeft}px` }"
                @click="click(item)"
            >
                {{ item.name }}
                <Icon v-if="item.iconProps" v-bind="item.iconProps" />
            </Button>
        </FormItem>
        <FormItem v-if="$slots.formItem">
            <slot name="formItem" />
        </FormItem>
    </Form>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
            type: Object
        },
        formList: {
            required: true,
            type: Array
        },
        formProps: {
            type: Object
        },
        btns: {
            type: Array
        },
        hasBtns: {
            type: Boolean,
            default: true
        }
    },
    data() {
        const initMyFormData = () => {
            let old, props;
            const myFormData = {},
                { formList, value } = this;

            for (let i = formList.length - 1, item; i >= 0; i--) {
                item = formList[i];
                old = value[item.key];
                props = item.props || {};
                switch (true) {
                case item._type === 'Cascader' ||
                        item._type === 'DatePicker' ||
                        item._type === 'CheckboxGroup' ||
                        (item._type === 'Select' && props.multiple):
                    myFormData[item.key] = old || [];
                    break;
                case item._type === 'Switch':
                    myFormData[item.key] =
                            old === props.trueValue ? old : props.falseValue;
                    break;
                default:
                    myFormData[item.key] = old || null;
                }
            }
            return myFormData;
        };

        return {
            myFormData: initMyFormData(),
            selectLoading: {}
        };
    },
    computed: {
        myFormProps() {
            return Object.assign(
                {
                    inline: true,
                    labelWidth: 110
                },
                this.formProps
            );
        },
        myFormList() {
            return this.formList;
        },
        myBtns() {
            return Object.assign(
                [
                    {
                        name: '查询',
                        _type: 'submit',
                        type: 'primary',
                        iconProps: {
                            type: 'md-search'
                        }
                    },
                    {
                        name: '重置',
                        _type: 'reset',
                        iconProps: {
                            type: 'md-refresh'
                        },
                        marginLeft: 8
                    }
                ],
                this.btns
            );
        }
    },
    watch: {
        value() {
            this.updateMyFormData();
        }
    },
    mounted() {
        this.updateMyFormData();
        this.$emit('input', this.myFormData);
    },
    methods: {
        updateMyFormData() {
            const { myFormData, value } = this;

            this.myFormData = Object.assign(myFormData, value);
        },
        change(o) {
            const {
                    $comon: { getType }
                } = this,
                { key, value, item } = o;

            let val = value;

            switch (true) {
            case getType(val) === 'Array':
                val = (val.filter(ele => ele).length && val) || undefined;
                break;
            case getType(val) === 'String':
                val = val.trim();
                break;
            case getType(val) === 'Object' &&
                    item.props &&
                    item.props.labelInValue:
                o.label = val.label;
                val = val.value;
            }
            this.myFormData[key] = val;
            o.value = val;
            this.$emit('change', o);
            this.$emit('input', this.myFormData);
        },
        getRef() {
            return this.$refs.FormRef.getRef();
        },
        click(item) {
            const { _type } = item,
                { getRef } = this;

            if (_type === 'reset') {
                getRef().resetFields();
            }

            this.$emit(_type, { getRef, item });
        },
        remoteSelect({ value, item }) {
            let r,
                _item = item;
            const { remoteCofing, key } = _item,
                { api, data, queryKey, resultLink } = remoteCofing,
                handleRes = () => {
                    _item = Object.assign(_item, {
                        options: r || []
                    });
                    this.selectLoading[key] = false;
                };

            if (value) {
                this.selectLoading[key] = true;
                api({
                    ...data,
                    [queryKey]: value
                })
                    .then(res => {
                        r = res || {};
                        resultLink.split('.').forEach(k => {
                            r = r[k] || {};
                        });
                        handleRes();
                    })
                    .catch(() => {
                        handleRes();
                    });
            }
        }
    }
};
</script>
