<template>
    <Menu
        ref="MenuRef"
        class="sider-menu"
        :active-name="activeName"
        theme="light"
        :class="menuitemClasses"
        :open-names="openNames"
        accordion
        @on-select="
            $router.push({
                path: $event
            })
        "
    >
        <template v-for="item of routers">
            <template v-if="item.children">
                <MenuGroup
                    :key="item.name"
                    :title="item.title"
                >
                    <template v-for="ele of item.children">
                        <MenuItem 
                            v-if="!ele.hidden"
                            :key="ele.name"
                            :name="ele.path"
                        >
                            <Icon :type="ele.icon"></Icon>
                            <div class="inline-block" v-text="ele.title"></div>
                        </MenuItem>
                    </template>
                </MenuGroup>
            </template>
            <MenuItem
                v-else-if="!item.hidden"
                :key="item.name"
                :name="item.path"
            >
                <Icon :type="item.icon"></Icon>
                <div class="inline-block" v-text="item.title"></div>
            </MenuItem>
        </template>
    </Menu>
</template>

<script>
import { Menu, MenuItem, MenuGroup } from 'view-design';
import { mapGetters } from 'vuex';

export default {
    components: { Menu, MenuItem, MenuGroup },
    props: {
        isCollapsed: {
            required: true,
            type: Boolean
        }
    },
    computed: {
        ...mapGetters({
            routers: 'comon/routers',
            menuBtns: 'comon/menuBtns'
        }),
        rotateIcon() {
            return ['menu-icon', this.isCollapsed ? 'rotate-icon' : ''];
        },
        menuitemClasses() {
            return ['menu-item', this.isCollapsed ? 'collapsed-menu' : ''];
        },
        activeName() {
            return this.$route.path;
        },
        openNames() {
            const r = [],
                activeName = this.activeName,
                routers = this.$router.getRoutes();

            for (let i = routers.length - 1, item; i >= 0; i--) {
                item = routers[i];
                if (item.parent && item.path === activeName) {
                    r.push(item.parent.path);
                    break;
                }
            }

            return r;
        }
    }
};
</script>

<style lang="less" scoped>
.menu-item span {
    display: inline-block;
    overflow: hidden;
    width: 69px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width 0.2s ease 0.2s;
}
.menu-item i {
    transform: translateX(0);
    transition: font-size 0.2s ease, transform 0.2s ease;
    vertical-align: middle;
    font-size: 16px;
}
.collapsed-menu span {
    width: 0;
    transition: width 0.2s ease;
}
.collapsed-menu i {
    transform: translateX(5px);
    transition: font-size 0.2s ease 0.2s, transform 0.2s ease 0.2s;
    vertical-align: middle;
    font-size: 22px;
}
</style>
