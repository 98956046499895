<template>
    <div class="tree-wrap">
        <div v-if="useHandle" class="tree-hd d-flex flex-center-between">
            <GlobalButton type="add" :disabled="!handleRole.includes('add')" @click="onHandle('add')">新增类目</GlobalButton>
            <GlobalButton type="edit" :disabled="!handleRole.includes('edit')" @click="onHandle('edit')">编辑</GlobalButton>
            <GlobalButton type="del" :disabled="!handleRole.includes('del')" @click="onHandle('del')">删除</GlobalButton>
        </div>
        <div class="tree-bd">
            <Tree
                :data="treeData"
                select-node
                @on-select-change="onSelectChange"
                @on-toggle-expand="onToggleExpand"
            />
        </div>
    </div>
</template>
<script>
import { Tree } from 'view-design';
import GlobalButton from "@/components/GlobalButton";

export default {
    name: 'HandleTree',
    components: {GlobalButton},
    props: {
        ...Tree.props,
        treeData: {
            type: Array,
            default: () => []
        },
        useHandle: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            selectRow: []
        };
    },
    computed: {
        handleRole() {
            if (!this.selectRow.length) {
                return [];
            }
            if (this.selectRow[0].handleRole) {
                return this.selectRow[0].handleRole;
            }
            return [];
        }
    },
    methods: {
        setSelectRow(row) {
            this.selectRow = row;
        },
        onSelectChange(row) {
            if (row.length) {
                this.selectRow = row;
                this.$emit('on-select-change', row[0]);
            }
        },
        onHandle(type) {
            const row = this.selectRow[0];

            this.$emit('on-handle-click', { type, row });
        },
        /**
         * 展开/收起子列表
         * @param {Object} currentNode
         */
        onToggleExpand(currentNode) {
            this.$emit('on-expand', currentNode);
            const parent = currentNode.parent;
            const currentLevelList = parent.children || [];
            if (!parent.classId) return;
            currentLevelList.forEach(item => {
                if (currentNode.classId !== item.classId) {
                    this.$set(item, 'expand', false);
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
.tree-wrap {
    padding: 0 12px;
    .tree-hd {
        padding: 12px 0;
    }
    .ivu-btn {
        padding: 0;
    }
    /deep/.ivu-tree-title {
        width: 100%;
    }
}
</style>
