import axios from "axios";
import API from '../api';
import { Message } from 'view-design';
import Plugins from '@/plugins';
import {getLocal,isObject} from "@/utils/func";
import {filterXSS} from "xss";
const env = window.VUE_APP_ENV || process.env.VUE_APP_ENV;
/**
 * 公共请求方法
 * @param {Object} options 请求配置
 * @param {string} options.url - 请求地址
 * @param {string} [options.method] - 请求方式
 * @param {Object} [options.params] - 请求参数(get)
 * @param {Object} [options.data] - 请求参数(post)
 * @param {string} [options.serverName] - 服务名
 * @param {Object} [exOptions] - 额外配置
 * @param {boolean} [exOptions.useGlobalLoading] - 是否启用全局loading
 * @param {boolean} [exOptions.closeErrorMessage] - 是否关闭错误提示
 * @param {Object} [exOptions.headers] - 额外的请求头
 * @param {boolean} [exOptions.disabledXss] - 禁用xss过滤（防止富文本内容中的部分标签失效）
 */
function httpRequest(options, exOptions = {}) {
    if (env === 'noRequest') return Promise.reject(new Error('当前为无请求模式~')).catch(error => error);
    const httpInstance = axios.create({
        baseURL: API.server + options.serverName,
        headers: {
            PlatformId: 'SF',
            applicationCode: '1048',
        }
    });

    httpInstance.interceptors.request.use(config => {
        const token = localStorage.getItem('token');
        // const { orgId, userCode, departmentId } = Plugins.store.state.comon.userInfo;
        const { orgId, userCode, departmentId } = getLocal('userInfo', true, {});
        config.headers = {
            ...config.headers,
            orgId,
            userCode,
            departmentId,
            createUserCode: userCode
        };
        if (!!token) {
            config.headers['Authorization'] = token;
        }
        if (exOptions.headers) {
            config.headers = {...config.headers, ...exOptions.headers};
        }
        // const urlLast = config.url.split('/').pop().toLowerCase();
        // if (urlLast.startsWith('query') || urlLast.endsWith('query')) {
        //     const orgId = Plugins.store.state.comon.userInfo.orgId;
        //     if (config.method === 'post') {
        //         config.data.orgId = config.data.orgId || orgId;
        //     } else {
        //         config.params.orgId = config.params.orgId || orgId;
        //     }
        // }
        // const arr = ['add', 'update', 'edit', 'get'];
        // const index = arr.findIndex(item => urlLast.includes(item));
        // if (index !== -1) {
        //     const { orgId, userCode, departmentId } = Plugins.store.state.comon.userInfo;
        //     if (config.method === 'post') {
        //         config.data = {
        //             ...config.data,
        //             userCode,
        //             departmentId,
        //             orgId: config.data.orgId || orgId,
        //             createUserCode: config.data.createUserCode || userCode
        //         };
        //     } else {
        //         config.params = {
        //             ...config.params,
        //             userCode,
        //             departmentId,
        //             orgId: config.params.orgId || orgId,
        //             createUserCode: config.params.createUserCode || userCode
        //         };
        //     }
        // }
        return config;
    }, error => {
        throw error;
    });
    httpInstance.interceptors.response.use(response => {
        let data = response.data;
        if (!exOptions.disabledXss) {
            if (isObject(data)) {
                data = filterXSS(JSON.stringify(data));
                data = JSON.parse(data);
            } else {
                data = filterXSS(data || '');
            }
        }
        if (data.success) {
            return Promise.resolve(data);
        }
        if (!exOptions.closeErrorMessage) {
            Message.error(data.message || '服务异常');
        }
        // token过期
        if (data.code === 'TK03' && window.$vueInstance) {
            window.$vueInstance.$store.dispatch('comon/exit');
        }

        if (exOptions.resolveError) {
            return Promise.resolve(data);
        }

        return Promise.reject(data.message);
    }, error => {
        if (!exOptions.closeErrorMessage) {
            Message.error(error || '服务异常');
        }
        return Promise.reject(error || '网络异常');
    });
    return httpInstance.request({
        ...options,
        method: options.method || 'post'
    });
}

export default httpRequest;
