<template>
    <div v-if="visible" class="prompt-bar">
        <div class="prompt-bar-content">
            <Icon type="md-alert" class="bar-content-icon" />
            <slot name="content">
                <span class="bar-content-text">{{ content }}</span>
            </slot>
        </div>
        <Icon v-if="showClose" type="md-close" class="prompt-bar-close" @click="visible = false" />
    </div>
</template>
<script>
export default {
    props: {
        content: {
            type: String,
            default: ''
        },
        showClose: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            visible: true
        };
    }
};
</script>
<style lang="less" scoped>
.prompt-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    background: #fff3f2;
    border: 1px solid #fde6e6;
    color: #7a7979;
    .prompt-bar-content {
        .bar-content-icon {
            font-size: 14px;
            margin-right: 8px;
            color: #cf3028;
        }
        .bar-content-text {
            font-size: 12px;
        }
    }
    .prompt-bar-close {
        font-size: 14px;
        cursor: pointer;
    }
}
</style>