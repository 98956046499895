<template>
    <Icon :custom="`iconfont ${iconTypeList[type]}`" :size="size" />
</template>

<script>
export default {
    name: "CustomIcon",
    props: {
        type: {
            type: String,
            default: 'shezhi'
        },
        size: {
            type: [Number, String],
            default: '22'
        }
    },
    data() {
        return {
            // iconfont枚举
            iconTypeList: {
                setting: 'icon-shezhi', // 设置
                'expand-menu': 'icon-zhankaicaidan1', // 展开菜单
                'close-menu': 'icon-shouqicaidan1', // 收起菜单
                note: 'icon-zhushi', // 注释
                search: 'icon-icon-sousuo', // 注释
                reset: 'icon-icon-zhongzhi', // 重置
                checked: 'icon-duoxuan-yixuan', // 多选-已选
                halfChecked: 'icon-duoxuan-feiquanxuan', // 多选-已选
                del: 'icon-icon-shanchu', // 删除
                'arrow-right': 'icon-icon-sanjiao', // 三角-右
                'arrow-down': 'icon-icon-xiasanjiao', // 三角-下
                edit: 'icon-icon-bianji', // 编辑
                add: 'icon-icon', // +号
                help: 'icon-icon-bangzhu', // 帮助(?)
                menu: 'icon-biaotoupaixu', // 表头排序
            }
        };
    }
};
</script>

<style scoped>

</style>
