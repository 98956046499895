import Vue from 'vue';
import Vuex from 'vuex';
import comon from './comon';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        comon
    }
});
