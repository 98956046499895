<template>
    <div class="btns nowrap bg-white flex-center-between">
        <div class="scroll">
            <Button
                v-for="(item, index) of menuBtns"
                :key="item.routerName"
                :type="item.isActive ? 'primary' : 'default'"
                class="btn"
                size="small"
                @click.prevent="$router.push({ name: item.routerName })"
                @mouseleave.native="delName = null"
                @mouseover.native="delName = item.routerName"
            >
                <span v-text="item.name"></span>
                <Icon
                    v-show="delName === item.routerName"
                    v-if="item.needDelIcon"
                    type="md-close"
                    color="#ed4014"
                    @click="close(index)"
                />
            </Button>
        </div>
        <Dropdown
            v-show="menuBtns.length > 2"
            transfer
            style="margin-right: 10px;"
            placement="left-end"
            @on-click="dropdownClick"
        >
            <Icon
                type="ios-options"
                :color="optsColor"
                @mouseleave.native="hasOptsColor = false"
                @mouseover.native="hasOptsColor = true"
            ></Icon>
            <DropdownMenu slot="list">
                <DropdownItem
                    v-for="item of dropdownMenu"
                    :key="item"
                    :name="item"
                    v-text="item"
                ></DropdownItem>
            </DropdownMenu>
        </Dropdown>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            hasOptsColor: false,
            delName: null,
            dropdownMenu: ['关闭全部', '关闭其他']
        };
    },
    computed: {
        ...mapGetters({
            menuBtns: 'comon/menuBtns'
        }),
        optsColor() {
            return this.hasOptsColor ? '#ff9900' : '';
        }
    },
    methods: {
        ...mapActions({
            updateMenuBtns: 'comon/updateMenuBtns'
        }),
        close(i) {
            let index = i;

            const { menuBtns, updateMenuBtns, $router } = this,
                [{ isActive }] = menuBtns.splice(index--, 1);

            updateMenuBtns(menuBtns);
            this.delName = null;
            if (isActive) {
                $router.push({
                    name: menuBtns[i].routerName
                });
            }
        },
        dropdownClick(v) {
            const { menuBtns, updateMenuBtns, $router } = this;

            this.delName = null;
            switch (v) {
            case '关闭全部':
                updateMenuBtns(menuBtns.slice(0, 1));
                $router.push({
                    name: 'home'
                });
                break;
            case '关闭其他':
                updateMenuBtns(
                    menuBtns.filter(
                        item => item.isActive || item.routerName === 'home'
                    )
                );
            }
        }
    }
};
</script>

<style lang="less" scoped>
@10px: 10px;
.btns {
    & > div {
        margin-left: @10px;
    }
    .btn {
        margin-top: 4px;
        margin-bottom: 4px;
        margin-left: @10px;
        &:nth-child(1) {
            margin-left: 0;
        }
    }
}
</style>
