import Vue from 'vue';
import App from './App.vue';
import Plugins from './plugins';
import globalMixins from './mixins/global';
import Comon from './utils/comon';
import RemoteSelect from '@/components/RemoteSelect/RemoteSelect';
import DictSelect from '@/components/DictSelect';
import SiderMenu from "./components/layout/SiderMenu";
import GlobalDateRangePicker from '@/components/GlobalDateRangePicker';
import GlobalInputNumber from '@/components/GlobalInputNumber';
import { Cascader, Input } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import { Input } from 'iview';

import moment from 'moment';
import { amountColumns, weightColumns, moneyColumns, settlementWeightColumns } from '@/utils/columnsConfig';

import './components';
import './utils/permission';

import './assets/styles/global.less';
import {queryDictionaryValue} from "./api/base";
import {getFlowByCode} from "./api/setting";
import {groupBy, fixedDecimalString} from "./utils/func";

Vue.config.productionTip = false;
Vue.prototype.$comon = new Comon();
Vue.component('RemoteSelect', RemoteSelect);
Vue.component('DictSelect', DictSelect);
Vue.component('SiderMenu', SiderMenu);
Vue.component('GlobalDateRangePicker', GlobalDateRangePicker);
Vue.component('GlobalInputNumber', GlobalInputNumber);
Vue.component('el-cascader', Cascader);
Vue.component('el-input', Input);
import { mapState } from "vuex";
// 全局混入字典枚举 (code => value)
Vue.mixin({
    data () {
        return {
            globalFlowState: false,
        };
    },
    computed: {
        ...mapState({
            AUTH_MAP: state => state.comon.authMap
        }),
        DICT_ENUM() {
            const dict = this.$store.state.comon.dictData || [],
                dictObj = {};

            Object.keys(dict).forEach(key => {
                dictObj[key] = {};
                dict[key].forEach(item => {
                    dictObj[key][item.code] = item.value;
                });
            });
            return dictObj;
        }
    },
    methods: {
        async _getFlowByCode(code, field) {
            const res = await getFlowByCode({code:code}),
                state = res.data === 'true';

            if (typeof field === 'string') {
                this.globalFlowStateObj[field] = state;
            } else {
                this.globalFlowState = state;
            }
        }
    }
});
// 时间过滤器
Vue.filter('formatDate', function(value, formatStr) {
    const str = formatStr || 'yyyy-MM-DD';

    return value ? moment(value).format(str) : '';
});
Vue.filter('formatDatetime', function(value, formatStr) {
    const str = formatStr || 'yyyy-MM-DD HH:mm:ss';

    return value ? moment(value).format(str) : '';
});
Vue.filter('formatDateMinute', function(value, formatStr) {
    const str = formatStr || 'yyyy-MM-DD HH:mm';

    return value ? moment(value).format(str) : '';
});
// 数量过滤器
Vue.filter('formatAmount', (value, unit='') => fixedDecimalString(value, amountColumns.toFixed) + unit);
// 重量过滤器
Vue.filter('formatWeight', (value, unit='') => fixedDecimalString(value, weightColumns.toFixed) + unit);
// 金额过滤器
Vue.filter('formatMoney', (value, unit='') => fixedDecimalString(value, moneyColumns.toFixed) + unit);
// 字典过滤器
Vue.filter('formatDict', (value, dict) => value ? new Comon().$dict(dict, value) : '');
async function getDictList(){
    const VueInstance =  new Vue({
            ...Plugins,
            mixins: [globalMixins],
            render: h => h(App)
        }),
        res = await queryDictionaryValue({
            pageNum: 1,
            pageSize: -1
        }).catch(() => {
            VueInstance.$mount('#app');
            window.$vueInstance = VueInstance;
        });
    document.querySelector('#empty-page').style.display = 'none';
    let list = [],
        obj = {};

    if (res && res.success) {
        list = res.data;
        obj = groupBy(list, item => item.dictionaryCode);
        Plugins.store.commit('comon/setDictData', obj);
    }
    VueInstance.$mount('#app');
    window.$vueInstance = VueInstance;
}

getDictList();
