<template>
    <IForm ref="FormRef" v-bind="$attrs" v-on="$listeners"><slot /></IForm>
</template>

<script>
import { Form as IForm } from 'view-design';

export default {
    name: 'Form',
    components: { IForm },
    methods: {
        getRef() {
            return this.$refs.FormRef;
        }
    }
};
</script>
