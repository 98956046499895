<template>
    <div class="layout-header flex-center-between base-h color-white">
        <div class="company d-flex flex-center base-h" :style="{ width: isCollapsed ? `${width}px` : '100px' }">
            <img src="@/assets/pictures/logo.png" alt="">
        </div>
        <div class="content-header flex-1">
            <div class="welcome flex-1">
                <!-- <img src="@/assets/svg/sf-logo.svg" alt=""> -->
                <span class="crumbs-title">{{crumbs}}</span>
            </div>
            <OrgInfo />
            <Button icon="ios-search" type="text">搜索</Button>
            <Button icon="ios-help-circle-outline" type="text">帮助</Button>
            <UserInfo />
        </div>
    </div>
</template>

<script>
import UserInfo from './UserInfo';
import OrgInfo from "@/components/layout/OrgInfo";

export default {
    components: {
        OrgInfo,
        UserInfo
    },
    props: {
        isCollapsed: {
            required: true,
            type: Boolean
        },
        width: {
            type: Number,
            default: 100,
        },
        crumbs: {
            type: String,
            default: "首页",
        }
    },
};
</script>

<style lang="less" scoped>
.layout-header {
    color: inherit;
    background-color: #fff;
    .company {
        position: relative;
        width: 100px;
        background: linear-gradient(180deg, #d0322a 0%, #bd0c03 100%);
        transition: width 0.2s linear;
        .word-color {
            color: #e7e7e7;
        }
        img {
            width: 50px;
        }
        .name {
            font-size: 20px;
            font-weight: bold;
        }
        .english {
            font-size: 10px;
            transform: scale(0.8);
        }
    }
    .welcome {
        >img {
            width: 78px;
            margin-left: 20px;
        }
    }
    .content-header {
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebedf2;
    }
    .crumbs-title {
        padding: 0 30px;
        color: #474f66;
        font-size: 14px;
    }
}
</style>
