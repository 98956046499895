<template>
    <div class="editable-table">
        <!-- 顶部搜索表单 -->
        <div v-if="useSearchForm" class="search-row" :class="{ 'search-row-flex': searchFormFlex }">
            <div class="search-form">
                <GlobalForm
                    ref="searchForm"
                    v-model="searchFormResult"
                    :form-list="searchFormOption"
                    :no-reset-disabled="noResetDisabled"
                    use-show-more
                    use-search-mode
                    :is-show-more="isShowMore"
                    :show-more-index="showMoreIndex"
                    mode="horizontal"
                    class="search-form-content"
                >
                    <template v-for="tmp in searchFormOptionSlot" :slot="tmp.searchFormSlot" slot-scope="{ item, value }">
                        <slot :name="tmp.searchFormSlot" v-bind="{ item, value }"></slot>
                    </template>
                </GlobalForm>
            </div>
            <div class="search-btn-box">
                <!-- <div class="search-item" />
                <div class="search-item" /> -->
                <div class="search-item">
                    <Button
                        v-if="searchFormOption.find((m) => m.hideFormItem) || searchFormOption.length > showMoreIndex"
                        class="search-btn more"
                        type="text"
                        :loading="loading"
                        @click="isShowMore = !isShowMore"
                        >{{ isShowMore ? '收起' : '更多' }}筛选 <Icon :type="`ios-arrow-${isShowMore ? 'up' : 'down'}`"
                    /></Button>
                    <Button class="search-btn" type="default" :loading="loading" @click="resetSearch">重置</Button>
                    <Button class="search-btn" type="primary" :loading="loading" @click="toSearch">查询</Button>
                </div>
            </div>
        </div>
        <div v-if="useTotalBar" class="total-row" :class="totalBarClass">
            <div class="total-row-content">
                <span class="total-label">合计</span>
                <slot name="totalBar"></slot>
            </div>
            <div class="total-row-handle">
                <GlobalButton v-if="useColumnSetting" type="setting" class="column-edit" @click="columnEditModal = true">列表设置</GlobalButton>
                <slot name="otherSetting"></slot>
            </div>
        </div>
        <!-- tab插槽 -->
        <div v-if="useTabBar">
            <slot name="tabBar">
                <Tabs type="card" class="table-top-tabs" @on-click="topTabClick">
                    <TabPane v-for="tmp in tabBarList" :key="tmp.name" :name="tmp.name" :label="tmp.label" />
                </Tabs>
            </slot>
        </div>
        <!-- 顶部按钮 -->
        <div v-if="useTopBtn" class="option-row">
            <slot name="optionRow">
                <GlobalButton
                    v-for="(item, index) in optionBtnList"
                    :key="index"
                    :type="item.type"
                    :auth-symbol="item.authSymbol"
                    btn-type="default"
                    use-normal-content
                    v-show="!setBtnHidden(item.hidden)"
                    :disabled="setBtnDisabled(item.disabled)"
                    v-on="{
                        click: item.onClick || ((event) => btnClick(event, item)),
                    }"
                >
                    <template v-if="item.text">{{ item.text }}</template>
                </GlobalButton>
            </slot>
            <GlobalButton v-if="useColumnSetting && !useTotalBar" type="setting" class="column-edit" @click="columnEditModal = true">列表设置</GlobalButton>
        </div>
        <!-- <div v-if="useTopBtn" class="option-bottom-bar"></div> -->

        <!-- Table组件 -->
        <div class="table-box">
            <Table
                ref="table"
                :columns="insideColumns"
                :data="customData"
                size="small"
                :highlight-row="isRadio"
                :loading="loading"
                v-bind="$props"
                border
                v-on="$listeners"
                :summary-method="$props.summaryMethod || handleSummary"
                @on-current-change="currentChange"
                @on-column-width-resize="columnWidthResize"
            >
                <template v-for="item in slotList" :slot="item.slot" slot-scope="{ row, index, column }">
                    <Tooltip v-if="item.tooltip" class="ivu-table-cell-tooltip" :content="row[column.key]" :key="item.key">
                        <slot
                            :name="item.slot"
                            v-bind="{
                                row,
                                index: setDynamicIndex(index),
                                column,
                            }"
                        ></slot>
                    </Tooltip>
                    <slot v-else :name="item.slot" v-bind="{ row, index: setDynamicIndex(index), column }"></slot>
                </template>
            </Table>
        </div>

        <!-- 分页组件 -->
        <div v-if="showPage" class="page-options">
            <Page
                :total="useAutoPageSize ? data.length : pageOptions.total"
                :current="useAutoPageSize ? currentPageOption.pageNum : pageOptions.pageNum"
                :page-size="useAutoPageSize ? useAutoPageSize : pageOptions.pageSize"
                :page-size-opts="[10, 20, 30, 40, 50, 100, 150, 200]"
                show-total
                :show-sizer="!showSizer ? false : !useAutoPageSize"
                size="small"
                @on-change="pageChange"
                @on-page-size-change="pageSizeChange"
            />
        </div>

        <!-- 编辑弹窗 -->
        <Modal v-if="!useWideEditModal" v-model="editModal" :title="editModalTitle" width="400px">
            <GlobalForm ref="editForm" v-model="editForm" :form-list="editFormList" mode="vertical" form-item-flex="left" style="width: 360px" />
            <template #footer>
                <Button type="default" :loading="editModalLoading" @click="editModalCancel">取消</Button>
                <Button type="primary" :loading="editModalLoading" @click="editModalOk">确定</Button>
            </template>
        </Modal>
        <Modal v-else v-model="editModal" :title="editModalTitle" width="1100px">
            <GlobalForm ref="editForm" v-model="editForm" :form-list="editFormList" form-item-flex="left" />
            <template #footer>
                <Button type="default" :loading="editModalLoading" @click="editModalCancel">取消</Button>
                <Button type="primary" :loading="editModalLoading" @click="editModalOk">确定</Button>
            </template>
        </Modal>

        <!-- 自定义列弹窗 -->
        <EditColumnModal v-if="useColumnSetting" v-model="columnEditModal" :data="copyColumns" @confirm="editColumnConfirm" />
    </div>
</template>

<script>
import { Table } from 'view-design';
import GlobalForm from '@/components/GlobalForm';
import EditColumnModal from './EditColumnModal';
import { deepCopy, getLocal, isArray, isNotEmpty, isEmpty, setLocal, fixedDecimalString, isObject, fixedDecimal, addDecimal, formatAllTypeParams } from '../utils/func';
import GlobalButton from '@/components/GlobalButton';
import moment from 'moment';
import { mapState } from 'vuex';
import { amountColumns, moneyColumns, weightColumns } from '@/utils/columnsConfig';

export default {
    name: 'EditableTable',
    components: { GlobalButton, EditColumnModal, GlobalForm },
    model: {
        prop: 'data',
    },
    props: {
        ...Table.props,
        columns: {
            type: Array,
            default() {
                return [];
            },
        },
        data: {
            type: Array,
            default() {
                return [];
            },
        },
        showPage: {
            type: Boolean,
            default: true,
        },
        showSizer: {
            type: Boolean,
            default: true,
        },
        // 自定义编辑弹窗关闭
        customEditModalClose: {
            type: Boolean,
            default: false,
        },
        // 是否单选
        isRadio: {
            type: Boolean,
            default: false,
        },
        // 显示右侧编辑按钮
        showEditColumn: {
            type: Boolean,
            default: false,
        },
        // 主键
        rowKey: {
            type: [String, Number],
        },
        // 页面配置
        pageOptions: {
            type: Object,
            default() {
                return {
                    total: 0,
                    pageNum: 1,
                    pageSize: 10,
                };
            },
        },
        // 是否为多选
        isCheckbox: {
            type: Boolean,
            default: false,
        },
        // 原始列设置的版本号
        columnVersion: {
            type: String,
            default: 'v0.01',
        },
        // 加载中
        loading: {
            type: Boolean,
            default: false,
        },
        // 顶部按钮
        exTopBtnList: {
            type: Array,
            default() {
                return [];
            },
        },
        // 使用顶部按钮
        useTopBtn: {
            type: Boolean,
            default: true,
        },
        // 使用列表设置
        useColumnSetting: {
            type: Boolean,
            default: true,
        },
        // 使用搜索栏
        useSearchForm: {
            type: Boolean,
            default: true,
        },
        // 是否开启所有列可拖拽列宽
        columnResizable: {
            type: Boolean,
            default: true,
        },
        // 列设置名称, 用于对当前列自定义设置的索引key
        columnSaveKey: {
            type: String,
            default: 'column1',
        },
        // 是否使用宽的弹窗
        useWideEditModal: {
            type: Boolean,
            default: false,
        },
        // 使用合计栏
        useTotalBar: {
            type: Boolean,
            default: false,
        },
        // 合计栏自定义class
        totalBarClass: {
            type: [String, Array],
            default: '',
        },
        // 使用自动分页
        useAutoPageSize: {
            type: Number,
            default: null,
        },
        // 使用tabs bar
        useTabBar: {
            type: Boolean,
            default: false,
        },
        // tabs bar列表
        tabBarList: {
            type: Array,
            default() {
                return [];
            },
        },
        // tab选中后的key
        tabKey: {
            type: String,
            default: 'tabName',
        },
        // 已经被选中的id集合
        selectedIdList: {
            type: Array,
            default() {
                return [];
            },
        },
        showMoreIndex: {
            type: Number,
            default: 8,
        },
        // 当查询条件较少时，将查看条件和按钮放到一行
        searchFormFlex: {
            type: Boolean,
            default: false,
        },
        // 初始化时是否自动查询
        useInitSearch: {
            type: Boolean,
            default: true,
        },
        // 是否由表格自动判断合计列
        useAutoFigureOut: {
            type: Boolean,
            default: false,
        },
        // 不重置禁用项
        noResetDisabled: {
            type: Boolean,
            default: false,
        },
        // 自动设置左侧固定列
        useAutoLeftFixedColumn: {
            type: Boolean,
            default: false,
        },
        // 左侧固定列的配置
        autoFixedColumn: {
            type: Array,
            default: () => ['品名', '材质', '规格', '产地'],
        },
        // 展示自定义合计，用于合计全部的值
        customTotalData: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            columnEditModal: false, // 列设置弹窗
            editFormList: [], // 需要编辑的列
            editForm: {}, // 编辑内容结果
            editModal: false, // 编辑弹窗
            editIndex: null, // 编辑的行索引

            // 缓存翻页参数
            currentPageOption: {
                pageNum: 1,
                pageSize: 10,
            },
            optionBtnList: [],
            // 内部列设置
            insideColumns: [],
            // 复制列，用于自定义设置弹窗用
            copyColumns: [],
            // 搜索栏
            searchFormOption: [],
            // 搜索值集合
            searchFormResult: {},
            // 弹窗类型
            editModalType: 'add',
            // 弹窗loading
            editModalLoading: false,
            // 编辑弹窗标题
            editModalTitle: '编辑',
            // 是否展示更多
            isShowMore: false,
            // 当前tab选中项
            currentTab: null,
        };
    },
    computed: {
        slotList() {
            if (!Array.isArray(this.columns)) return [];
            return this.columns.filter((m) => m.slot);
        },
        searchFormOptionSlot() {
            if (!Array.isArray(this.searchFormOption)) return [];
            return this.searchFormOption.filter((m) => m.searchFormSlot);
        },
        customData() {
            if (this.useAutoPageSize) {
                const currentPage = this.currentPageOption.pageNum - 1;
                let currentData = this.data.slice(currentPage * this.useAutoPageSize, this.currentPageOption.pageNum * this.useAutoPageSize);
                if (currentData.length === 0) {
                    currentData = this.data.slice((currentPage - 1) * this.useAutoPageSize, this.currentPageOption.pageNum * this.useAutoPageSize);
                }
                return currentData;
            }
            if (this.selectedIdList.length > 0 && this.rowKey) {
                return this.data.map((m) => {
                    const hasData = this.selectedIdList.includes(m[this.rowKey]);

                    return {
                        ...m,
                        _disabled: hasData,
                    };
                });
            }
            return this.data;
        },
        ...mapState({
            userInfo: (state) => state.comon.userInfo,
        }),
        // 用户id
        userId() {
            return this.userInfo.userId;
        },
    },

    watch: {
        editModal(status) {
            if (status) {
                this.$nextTick(() => {
                    this.$refs.editForm.init();
                });
            }
        },
        data(list) {
            if (list) {
                this.editIndex = null;
            }
        },
    },
    mounted() {
        if (this.showPage && this.useInitSearch) {
            const queryForm = { ...this.currentPageOption };

            if (this.useTabBar && this.tabBarList.length > 0) {
                queryForm[this.tabKey] = this.tabBarList[0].name;
            }
            this.$emit('handleSearch', queryForm);
        }
        this.init();
        this.initTopBtnList();
    },
    methods: {
        /**
         * 导出csv
         * @param {Object} params
         * @param {string} [params.filename] - 文件名, 默认为table.csv
         * @param {boolean} [params.original] - 是否导出为原始数据, 默认为true
         * @param {boolean} [params.noHeader] - 不显示表头, 默认为false
         * @param {Object[]} [params.columns] - 自定义导出的列数据
         * @param {Object[]} [params.data] - 自定义导出的行数据
         */
        exportCsv(params) {
            this.$refs.table.exportCsv(params);
        },
        /**
         * 默认的合计方法
         * @param {Object} option
         * @param {Object[]} option.data - 当前表格数据
         * @param {Object[]} option.columns - 当前列设置
         * @return {Object}
         */
        handleSummary(option) {
            const { columns } = option;
            // 使用自动合计时，将整个data数据进行统计，普通合计时使用当前渲染数据合计
            const data = this.useAutoFigureOut ? this.data : option.data;
            const sums = {};
            columns.forEach((column, index) => {
                const key = column.key;
                if (index === 0 && !column.isSummary) {
                    sums[key] = {
                        key,
                        value: '合计',
                    };
                    return;
                }
                const values = data.map((item) => Number(item[key]));
                if (!values.every((value) => isNaN(value)) && column.isSummary) {
                    const columnOption = {
                        money: {
                            digit: moneyColumns.toFixed,
                            unit: '元',
                        },
                        weight: {
                            digit: weightColumns.toFixed,
                            unit: '吨',
                        },
                        amount: {
                            digit: amountColumns.toFixed,
                            unit: '',
                        },
                    };
                    const currentOption = columnOption[column.summaryOption];
                    let v = 0;
                    // 有传入自定义合计
                    if (!Object.keys(this.customTotalData).length) {
                        v = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                if (currentOption) {
                                    return fixedDecimal(addDecimal(prev, curr), currentOption.digit);
                                }
                                return addDecimal(prev, curr);
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        const cVal = this.customTotalData[key];
                        if (!isNaN(cVal)) v = currentOption ? fixedDecimal(cVal || 0, currentOption.digit) : cVal;
                        else v = 0;
                    }
                    const unit = currentOption ? currentOption.unit : '';
                    sums[key] = {
                        key,
                        value: `${fixedDecimalString(v, currentOption.digit)}${unit}`,
                    };
                } else {
                    sums[key] = {
                        key,
                        value: '-',
                    };
                }
            });

            return sums;
        },
        /**
         * 初始化搜索条件
         * @param {Object} params
         */
        initSearchParams(params) {
            this.$nextTick(() => {
                if (this.$refs.searchForm) {
                    this.$refs.searchForm.initFormData(params);
                }
            });
        },
        /**
         * 全选/反选
         * @param {boolean} status
         */
        selectAll(status) {
            this.$refs.table.selectAll(status);
        },
        /**
         * 设置自动分页下标
         * @param {number} index
         */
        setDynamicIndex(index) {
            if (!this.useAutoPageSize) return index;
            return this.useAutoPageSize * (this.currentPageOption.pageNum - 1) + index;
        },
        /**
         * 顶部tab点击事件
         * @param {string | number} name - 返回name或下标
         */
        topTabClick(name) {
            this.currentTab = name;
            this.$emit('handleSearch', {
                ...this.currentPageOption,
                ...formatAllTypeParams(this.searchFormResult),
                [this.tabKey]: name,
            });
        },
        /**
         * 切换列的显示
         * @param {string[]} keyList
         * @param {boolean} visibleStatus
         */
        changeColumnVisible(keyList, visibleStatus) {
            if (visibleStatus) {
                this.init(this.columns);
            } else {
                this.init(this.columns.filter((m) => !keyList.includes(m.key)));
            }
        },
        // 顶部翻页-上一页
        prevPageChange() {
            this.$emit('handleSearch', {
                ...this.currentPageOption,
                pageNum: this.currentPageOption.pageNum - 1,
            });
        },
        // 顶部翻页-下一页
        nextPageChange() {
            this.$emit('handleSearch', {
                ...this.currentPageOption,
                pageNum: this.currentPageOption.pageNum + 1,
            });
        },
        // 表头列拖动
        columnWidthResize(newWidth, oldWidth, column) {
            column.width = parseInt(newWidth);
        },
        /**
         * 顶部按钮配置
         */
        initTopBtnList() {
            this.optionBtnList = [...this.optionBtnList, ...this.exTopBtnList];
        },
        // 搜索
        toSearch() {
            this.currentPageOption.pageNum = 1;
            this.$emit('handleSearch', {
                ...this.currentPageOption,
                ...formatAllTypeParams(this.searchFormResult),
                [this.tabKey]: this.currentTab,
            });
        },
        /**
         * 重置
         * @param {Boolean} [noReSearch] - 是否不调用搜索回调
         */
        resetSearch(noReSearch) {
            this.currentPageOption.pageNum = 1;
            this.$refs.searchForm.reset();
            this.$emit('handleReset');
            if (!noReSearch) {
                this.$emit('handleSearch', this.currentPageOption);
            }
        },
        // 根据用户id存储自定义列设置
        saveUserColumnSetting() {
            const userColumnSetting = getLocal('userColumnSetting', true, {});

            userColumnSetting[this.userId] = userColumnSetting[this.userId] || {};
            userColumnSetting[this.userId][this.$route.path] = userColumnSetting[this.userId][this.$route.path] || {};
            userColumnSetting[this.userId][this.$route.path][this.columnSaveKey] = userColumnSetting[this.userId][this.$route.path][this.columnSaveKey] || {};
            userColumnSetting[this.userId][this.$route.path][this.columnSaveKey] = {
                version: this.columnVersion,
                data: this.copyColumns.map((m) => {
                    delete m.__id;
                    return m;
                }),
            };
            setLocal('userColumnSetting', userColumnSetting);
        },
        // 初始化检查
        init(list, isSave) {
            const that = this;
            let columns = deepCopy(this.columns);

            const userSettingAll = getLocal('userColumnSetting', true, {}),
                userSettingForUser = userSettingAll[this.userId] || {},
                userSettingForPage = userSettingForUser[this.$route.path] || {},
                userSetting = userSettingForPage[this.columnSaveKey] || {};

            if (isNotEmpty(userSetting) && userSetting.version === this.columnVersion) {
                columns = userSetting.data.map((item) => {
                    const obj = { ...item },
                        listItem = columns.find((n) => n.key === item.key) || {};

                    Object.keys(listItem).forEach((key) => {
                        if (listItem[key] instanceof Object) {
                            obj[key] = listItem[key];
                        }
                    });
                    return obj;
                });
            }
            if (isArray(list)) {
                columns = list;
            }

            // 自动填充isShow字段，用于用户自定义列设置是否展示
            columns = columns.map((m) => {
                let render = m.render;

                // m.tooltip = true;
                // 字典集成
                // if (!m.render && isNotEmpty(m.dict)) {
                //     render = (h, {row, column}) => <span>{this.$comon.$dict(m.dict, row[column.key])}</span>;
                // }

                // 是否开启resize
                if (this.columnResizable && isEmpty(m.resizable)) {
                    m.resizable = true;
                }
                if (isNotEmpty(m.toFixed) && typeof m.toFixed === 'number') {
                    if (m.type === 'money' && !m.renderHeader) {
                        m.renderHeader = (h) => {
                            if (!m.title?.includes('元')) return <span>{`${m.title}(元)`}</span>;
                            return <span>{m.title}</span>;
                        };
                    }
                    if (m.type === 'weight' && !m.renderHeader) {
                        m.renderHeader = (h) => {
                            if (!m.title?.includes('吨')) return <span>{`${m.title}(吨)`}</span>;
                            return <span>{m.title}</span>;
                        };
                    }
                }

                // 表格特殊数据处理
                if (!m.render && !m.slot) {
                    // 时间类型
                    if (m.inputType && m.inputType.indexOf('Date') !== -1) {
                        render = (h, { row }) => {
                            const value = row[m.key];

                            if (isEmpty(value)) return '';
                            return <span>{moment(value).format(`YYYY-MM-DD ${m.renderTime ? 'HH:mm:ss' : ''}`)}</span>;
                        };
                        // 字典类型
                    } else if (m.inputProps && isNotEmpty(m.inputProps.dict)) {
                        render = (h, { row }) => {
                            const value = row[m.key],
                                dictName = m.inputProps.dict;

                            if (isEmpty(value)) return '';
                            return <span>{this.$comon.$dict(dictName, value)}</span>;
                        };
                        // 启用-禁用状态特殊列
                    } else if (m.type === 'status') {
                        render = (h, { row, column, index }) => (
                            <div class="d-flex" style="width: 120px">
                                <div style="margin-right: 12px;">{row[column.key] ? '已启用' : '已禁用'}</div>
                                <i-switch
                                    size="small"
                                    vModel={this.data[index][column.key]}
                                    props={this.setInputData(column.inputProps, { row, column, index })}
                                    on={this.setInputData(column.inputOn, {
                                        row,
                                        column,
                                        index,
                                    })}
                                    style="margin-top: 4px;"
                                    class="custom-switch"
                                />
                            </div>
                        );
                        // 需要保留小数时
                    } else if (isNotEmpty(m.toFixed) && typeof m.toFixed === 'number') {
                        render = (h, { row, column }) => {
                            const result = fixedDecimalString(row[column.key], m.toFixed, m.useThousands, m.skipEmpty);
                            return <span>{result}</span>;
                        };
                        // 链接跳转
                    } else if (m.isLink && m.onLinkClick) {
                        render = (h, { row, column }) => (
                            <a
                                onClick={() => {
                                    m.onLinkClick(row);
                                }}
                            >
                                {row[column.key]}
                            </a>
                        );
                    }
                }

                if (isNotEmpty(m.isShow)) {
                    m.isShow = m.isShow instanceof Function ? m.isShow() : m.isShow;
                } else {
                    m.isShow = true;
                }
                // console.log(m.title, m.renderHeader);
                if (typeof m.key === 'string') {
                    if (m.key.toLowerCase().includes('orgname') || m.key.toLowerCase().includes('bankaccount')) {
                        m.tooltip = true;
                    }
                }
                if (typeof m.title === 'string' && !m.slot) {
                    if (m.title.includes('客户') || (m.title && m.title.includes('供应商'))) {
                        m.tooltip = true;
                    }
                }
                // 判断类型为重量和金额的列需要进行自动合计
                if (this.useAutoFigureOut) {
                    const moneyTitles = ['金额', '费', '价格', '总额', '余额', '补退', '收付', '税额', '成本'];
                    const weightTitles = ['重量', '吨数'];
                    const amountTitles = ['数量'];
                    const originTitle = m.title || '';
                    const isWeightAmount = originTitle === '数量(吨)';
                    const title = originTitle.replace(/\([^)]*\)/g, '');
                    if (isWeightAmount) {
                        m.isSummary = true;
                        m.summaryOption = 'weight';
                    } else if (!m.summaryOption) {
                        moneyTitles.forEach((str) => {
                            if (title && title.endsWith(str)) {
                                m.isSummary = true;
                                m.summaryOption = 'money';
                            }
                        });
                        weightTitles.forEach((str) => {
                            if (title && title.endsWith(str)) {
                                m.isSummary = true;
                                m.summaryOption = 'weight';
                            }
                        });
                        amountTitles.forEach((str) => {
                            if (title && title.endsWith(str)) {
                                m.isSummary = true;
                                m.summaryOption = 'amount';
                            }
                        });
                    }
                }
                // 判断是否需要自动设置左侧固定列
                if (this.useAutoLeftFixedColumn) {
                    const autoSetTitles = this.autoFixedColumn || [];
                    const originTitle = m.title || '';
                    const title = originTitle.replace(/\([^)]*\)/g, '');
                    if (autoSetTitles.includes(title)) {
                        m.fixed = 'left';
                    }
                }
                return {
                    ...m,
                    render,
                    isShow: m.isShow,
                };
            });

            // 复制列设置，用于用于自定义列设置
            this.copyColumns = deepCopy(columns);
            // 设置搜索配置
            this.searchFormOption = columns
                .filter((m) => m.isSearch === true)
                .map((m) => {
                    return {
                        ...m,
                        inputType: m.searchType || m.inputType,
                        slotName: m.searchFormSlot || m.slotName,
                    };
                });
            // 判断是否需要插入一列多选列
            if (this.isCheckbox) {
                columns.unshift({
                    type: 'selection',
                    width: 65,
                    align: 'center',
                    fixed: 'left',
                });
            }
            const renderColumns = columns.filter((tmp) => tmp.render instanceof Function && this.useAutoPageSize);
            renderColumns.forEach((m) => {
                const render = m.render;
                // 修改自动分页index
                if (render instanceof Function && this.useAutoPageSize) {
                    m.render = function (h, option) {
                        option.index = that.setDynamicIndex(option.index);
                        return render(h, option);
                    };
                }
            });
            this.insideColumns = columns.filter((m) => {
                if (m.isShow instanceof Function) m.isShow = m.isShow();
                return m.isShow !== false;
            });
            if (list && isSave) {
                this.saveUserColumnSetting();
            }
        },
        // 动态设置顶部按钮禁用条件
        setBtnDisabled(disabled) {
            if (disabled instanceof Function) {
                return disabled();
            }
            return disabled;
        },
        // 动态设置顶部按钮禁用条件
        setBtnHidden(hidden) {
            if (hidden instanceof Function) {
                return hidden();
            }
            return hidden;
        },
        // 插入顶部按钮
        addBtn(list) {
            if (!Array.isArray(list)) return;
            this.optionBtnList = [...this.optionBtnList, ...list];
        },
        // 单选
        currentChange(currentRow, oldCurrentRow) {
            if (!currentRow) return;
            if (this.isRadio || this.showEditColumn) {
                this.editForm = currentRow;
                if (this.rowKey) {
                    this.editIndex = this.data.findIndex((m) => m[this.rowKey] === currentRow[this.rowKey]);
                    this.data.forEach((item, index) => {
                        item._highlight = index === this.editIndex;
                    });
                }
            }
            this.$emit('on-current-change', currentRow, oldCurrentRow);
        },
        // 默认顶部按钮点击事件
        btnClick(event, item) {
            this.$emit('top-btn-click', item);
        },
        // 编辑表单校验
        validate(cb) {
            if (!this.$refs.editForm) return false;
            return this.$refs.editForm.validate(cb);
        },
        // 设置inputOn/inputProps的data
        setInputData(input, data) {
            if (typeof input !== 'object') return {};
            const obj = {};

            let key;
            for (key in input) {
                if (typeof input[key] === 'function') {
                    obj[key] = function () {
                        input[key](data);
                    };
                }
            }
            return obj;
        },
        /**
         * 打开编辑弹窗
         * @param {string} type 弹窗类型(add, edit)
         * @param {function(form: Object): void} [callback] 打开弹窗的回调函数
         * @param {string} [customTitle] - 自定义标题
         */
        callEdit(type, callback, customTitle) {
            this.editFormList = this.columns
                .filter((m) => {
                    let editable = m.editable;

                    if (m.editable instanceof Function) {
                        editable = m.editable(m);
                    }
                    return editable;
                })
                .map((m) => {
                    return {
                        ...m,
                        inputType: m.editType || m.inputType,
                    };
                });
            this.editModalType = type;
            if (type === 'edit') {
                this.editModalTitle = customTitle || '编辑';
                // if (!Object.keys(this.editForm).length) return;
                // if (!this.isRadio || !this.$listeners['on-current-change']) return;
                this.editForm = this.data[this.editIndex];
            }
            if (type === 'add') {
                this.editModalTitle = customTitle || '新增';
                const editObj = {};

                this.editFormList.forEach((item) => {
                    editObj[item.key] = null;
                });
                this.editForm = editObj;
            }
            this.editModal = true;

            this.$nextTick(() => {
                if (type === 'add') {
                    this.$refs.editForm.reset();
                }
                if (callback) callback(this.editForm);
                this.$refs.editForm.initFormData(this.editForm);
            });
        },
        // 编辑弹窗确认
        async editModalOk() {
            const valid = await this.$refs.editForm.validate();

            if (!valid) return;
            const close = () => {
                    this.editModal = false;
                    this.editModalLoading = false;
                    this.currentPageOption.pageNum = 1;
                    this.$emit('handleSearch', {
                        ...this.currentPageOption,
                        ...formatAllTypeParams(this.searchFormResult),
                        [this.tabKey]: this.currentTab,
                    });
                },
                content = {
                    ...this.editForm,
                    _highlight: true,
                };

            if (this.customEditModalClose) {
                this.editModalLoading = true;
                this.$emit('on-edit-modal-close', {
                    editForm: content,
                    type: this.editModalType,
                    close,
                    closeLoading: () => {
                        this.editModalLoading = false;
                    },
                });
                return;
            }
            if (this.editIndex > -1) {
                this.$set(this.data, this.editIndex, content);
            } else {
                this.customData.push(content);
            }
            close();
        },
        // 编辑弹窗取消
        editModalCancel() {
            this.editModal = false;
        },
        // 重置选中项
        resetSelect() {
            this.$refs.table.clearCurrentRow();
            this.editForm = {};
            this.$emit('on-selection-change', []);
            this.$emit('on-current-change', {});
        },
        // 翻页变化
        pageChange(pageNum) {
            this.resetSelect();
            this.currentPageOption.pageNum = pageNum;
            this.$emit('handleSearch', {
                pageNum,
                pageSize: this.pageOptions.pageSize || this.currentPageOption.pageSize,
                ...formatAllTypeParams(this.searchFormResult),
                [this.tabKey]: this.currentTab,
            });
        },
        // 页码变化
        pageSizeChange(pageSize) {
            this.resetSelect();
            this.currentPageOption.pageSize = pageSize;
            this.$emit('handleSearch', {
                pageSize,
                pageNum: this.pageOptions.pageNum || this.currentPageOption.pageNum,
                ...formatAllTypeParams(this.searchFormResult),
                [this.tabKey]: this.currentTab,
            });
        },
        // 列设置确认
        editColumnConfirm(data) {
            this.init(data, true);
        },
        // 设置查询表单默认值
        setSearchFormData(data) {
            this.$refs.searchForm.initFormData(data);
        },
    },
};
</script>

<style scoped></style>
