let server = "http://47.103.130.166:8008/api/",
    downloadUrl = "http://8.144.162.99:9021/attach/download?path=";
const xxlJobUrl = "http://116.62.126.47:7070/xxl-job-admin",
    monitorUrl = "http://47.110.56.139:8769";
const env = window.VUE_APP_ENV || process.env.VUE_APP_ENV;
let mainUrl;
switch (env) {
case "custom":
    server = "http://localhost:8008/api/";
    break;
case "dev":
    // server = 'http://192.168.0.100:8008/api/';
    server = "http://testcms.31scm.com/api/";
    // server = "http://testcms.31scm.com/api/";
    // server = "http://deverp.31scm.com/api/";
    downloadUrl = "http://192.168.0.111:9021/attach/download?path=";
    // server = "http://82.157.104.84:8008/api/";
    // server = 'http://192.168.0.103:8008/api/';
    mainUrl = "https://erp.31scm.com";
    break;
case "test":
    server = "http://testerp.31scm.com:8008/api/";
    downloadUrl = "http://testerp.31scm.com:9021/attach/download?path=";
    mainUrl = "http://testerp.31scm.com";
    break;
case "mit":
    server = "/api/";
    downloadUrl = "/attach/download?path=";
    mainUrl = "http://testerp.31scm.com";
    break;
case "uat":
    server = "http://47.103.87.1:8008/api/";
    mainUrl = "http://uaterp.31scm.com";
    break;
case "prod":
    server = "http://aliscp.xingyun361.com/api/";
    break;
case "pre":
    server = "http://8.144.162.99:8008/api/";
    downloadUrl = "http://8.144.162.99:9021/attach/download?path=";
    break;
case "pro":
    server = "/api/";
    downloadUrl = "/attach/download?path=";
    mainUrl = "https://erp.31scm.com";
    break;
}

export const prefixUrl = downloadUrl;

export default {
    server,
    xxlJobUrl,
    monitorUrl,
    mainUrl,
};
