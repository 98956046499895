import { router, store } from '../plugins';
const packageInfo = require('../../package.json');

router.beforeEach((to, from, next) => {
    const { state, getters, dispatch } = store,
        { name, meta } = to,
        menuBtns = getters['comon/menuBtns'],
        ignoreBtns = ['error'],
        ignoreDelIconBtns = ['home'];

    if (name) {
        let has = false;

        for (let ij = menuBtns.length - 1, item; ij >= 0; ij--) {
            if (~ignoreBtns.indexOf(name)) continue;
            item = menuBtns[ij];
            if (item.isActive) item.isActive = false;
            if (item.routerName === name) {
                item.isActive = true;
                has = true;
            }
        }
        if (has) dispatch('comon/updateMenuBtns', menuBtns);
        else {
            if (!~ignoreBtns.indexOf(name)) {
                dispatch(
                    'comon/updateMenuBtns',
                    menuBtns.concat({
                        name: meta.title,
                        routerName: name,
                        isActive: true,
                        needDelIcon: ignoreDelIconBtns.indexOf(name) === -1
                    })
                );
            }
        }
    }
    if (meta.title) document.title = `${packageInfo.title}-${meta.title}`;
    // const userInfo = getters['comon/userInfo'] || {};
    // info = JSON.parse(userInfo);
    let userInfo = state.comon.userInfo;

    if (Object.keys(userInfo).length === 0) {
        userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo ? JSON.parse(userInfo) : {};
    }
    if (!userInfo.token && to.path !== '/login') {
        return next('/login');
    }
    // 编辑或详情页面需要新开浏览器tab页面，其他页面走router跳转
    if ((to.path.endsWith('-edit') || to.path.endsWith('-detail') || to.path.endsWith('-add')) && !window.isFirstLoad) {
        const arr = [];
        Object.keys(to.query).forEach(key => {
            arr.push(`${key}=${to.query[key]}`);
        });
        window.open(`${window.location.origin}${to.path}?${arr.join('&')}`);
    }else {
        window.isFirstLoad = false;
        next();
    }
});

// 发生code-split模块读取错误时，使用重定向
router.onError(err => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = err.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});
