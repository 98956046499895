<template>
    <div class="ivu-input-wrapper global-date-picker">
        <DatePicker
            v-model="reactiveValue"
            class="date-picker"
            :format="dateFormat"
            :type="showTime ? 'datetimerange' : 'daterange'"
            v-bind="$attrs"
            v-on="$listeners"
            @on-change="onChange"
        ></DatePicker>
        <Button class="input-wrapper-btn" @click="setTime(0)">今</Button>
        <Button class="input-wrapper-btn" @click="setTime(1)">昨</Button>
        <Button class="input-wrapper-btn" @click="setTime(7)">近7天</Button>
        <Button class="input-wrapper-btn" @click="setTime(30)">近30天</Button>
    </div>
</template>
<script>
import Moment from 'moment';
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
        format: {
            type: String,
            default: ''
        },
        // 是否显示时间
        showTime: {
            type: Boolean,
            default: false
        },
        // 是否显示底部控制栏
        confirm: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        reactiveValue: {
		    set(val) {
			    this.$emit('input', val);
		    },
		    get() {
			    return this.value;
		    }
	    },
        dateFormat() {
            if (this.format) return this.format;
            return this.showTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd';
        }
    },
    methods: {
        onChange() {
            // if (isNotEmpty(this.reactiveValue[0])) {
            //     this.reactiveValue[0] = moment(this.reactiveValue[0]);
            // }
            this.$emit('on-change', this.reactiveValue);
        },
        // 设置时间
        setTime(day) {
            let value = [];
            if (day === 1) {
                const start = Moment().subtract(day, 'day'),
                    endDate = Moment().subtract(1, 'day');
                value = [start.toDate(), endDate.toDate()];

            } else {
                const start = Moment().subtract(day, 'days');
                value = [start.toDate(), Moment().toDate()];
            }
            this.reactiveValue = value;
            this.$emit('on-change', value);
        }
    }
};
</script>
<style lang="less" scoped>
.global-date-picker {
    display: flex;
    .date-picker {
        flex: 1;
    }
    .input-wrapper-btn {
        margin-left: 4px;
    }
}
</style>