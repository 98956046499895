/*
 * 表格常用列配置
*/

// 数量配置
export const amountColumns = {
    align: 'right',
    toFixed: 0,
    useThousands: true
};

// 金额配置
export const moneyColumns = {
    align: 'right',
    toFixed: 2,
    useThousands: true
};

// 重量配置
export const weightColumns = {
    align: 'right',
    toFixed: 3,
    useThousands: true
};

// 库存货权方/货主/货源配置
export const minusoneColumns = {
    render(h, { row, column }) {
        const value = row[column.key];

        return <span>{ value === '-1' ? '-' : value }</span>;
    }
};