<template>
    <div>
        <template v-for="(item, index) in getMenu(children)">
            <Submenu
                v-if="Array.isArray(item.children) && (getMenu(item.children)).length > 0"
                :key="index"
                :name="item.path || ''"
                class="sub-menu-box"
            >
                <template #title>
                    <span class="sub-menu-title">{{ item.resourceName }}</span>
                </template>
                <tree-menu :children="getMenu(item.children)" />
            </Submenu>
            <MenuItem
                v-else
                :key="index"
                :name="item.path || ''"
                class="tree-menu-item">
                <div class="menu-item-title" @click="toPath(item)">{{ item.resourceName }}</div>
            </MenuItem>
        </template>
    </div>
</template>

<script>
export default {
    name: "TreeMenu",
    inject: ['rootMenu'],
    props: {
        children: {
            type: Array,
            default() {
                return [];
            }
        },
        isTop: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        /**
         * 过滤按钮
         * @param {Object[]} list
         * @return {Array}
         */
        getMenu(list) {
            if (Array.isArray(list)) return list.filter(tmp => tmp.resourceType !== '3' && !tmp.hide);
            return [];
        },
        /**
         * 跳转
         * @param {Object} item
         */
        toPath(item) {
            if (Array.isArray(item.children)) {
                this.$store.commit('comon/setCurrentAuthBtn', item.children.filter(tmp => tmp.resourceType === '3'));
            }
            this.$router.push(item.path);
        }
    }
};
</script>

<style lang="less" scoped>
.sub-menu-box {
    /deep/.ivu-menu-submenu-title {
        padding: 8px 10px;
    }
    /deep/.ivu-menu-submenu-title-icon {
        left: 14px;
        right: auto;
        top: 18px;
    }
    .sub-menu-title {
        padding-left: 22px;
    }
}
.tree-menu-item {
    padding: 0 !important;
    .menu-item-title {
        padding-left: 24px;
    }
}
</style>
