import { getWarehouseAll } from "@/api/warehouse";
import { getUnitAll } from "@/api/stock";
import {
    queryDictionary,
    getUnitByUnitName,
    getUnitAccountByUnitId,
    getCompany,
} from "@/api/base";
import { goodsBase, goodsBrand } from "@/api/goods";
import { contractsUnitQueryPage, getBaseUnit } from "@/api/entrance";
import { getBusinessUser } from "@/api/base";
import { getProjectList } from "@/api/engineering";
import { getProductClass } from "@/api/foundation";
import { queryAnalysisModel } from "@/api/report";
import { getUnitByApplicationCode } from '@/api/brandMerchants';

export default {
    // 商品品名
    goodsBrand: {
        method: goodsBrand,
        label: "brandName",
        value: "brandId",
    },
    // 商品材质/规格/资产
    goodsBase: {
        method: goodsBase,
        label: "baseName",
        value: "baseId",
    },
    // 商品品名搜索
    goodsBrandSearch: {
        method: goodsBrand,
        label: "brandName",
        value: "brandName",
    },
    // 商品材质/规格/资产搜索
    goodsBaseSearch: {
        method: goodsBase,
        label: "baseName",
        value: "baseName",
    },
    // 仓库
    warehouse: {
        method: getWarehouseAll,
        label: "warehouseName",
        value: "warehouseId",
    },
    // 仓库-值为名称
    warehouseName: {
        method: getWarehouseAll,
        label: "warehouseName",
        value: "warehouseName",
    },
    // 供应商、货源方-值为名称
    unitName: {
        method: getUnitAll,
        label: "unitName",
        value: "unitName",
    },
    // 供应商、货源方-值为id
    unitId: {
        method: getUnitAll,
        label: "unitName",
        value: "contactsUnitId",
    },
    // 字典名称搜索
    dictionary: {
        method: queryDictionary,
        label: "name",
        value: "id",
    },
    // 获取基础表公司信息
    baseOrgCompany: {
        method: getCompany,
        label: "orgName",
        value: "orgId",
    },
    // 获取基础表公司信息
    baseUnit: {
        method: getBaseUnit,
        label: "unitName",
        value: "unitName",
    },
    // 获取单位
    departmentUnit: {
        method: getBaseUnit,
        label: "unitName",
        value: "unitId",
    },
    // 会员体系单位
    unit: {
        method: getUnitByUnitName,
        label: "unitName",
        value: "unitId",
    },
    // 往来单位
    contractsUnit: {
        method: contractsUnitQueryPage,
        label: "contactsUnitName",
        value: "contactsUnitId",
    },
    // 会员体系单位
    vipUnitName: {
        method: getUnitByUnitName,
        label: "unitName",
        value: "unitName",
    },
    // 获取往来单位账户
    unitAccount: {
        method: getUnitAccountByUnitId,
        label: "unitId",
        value: "unitName",
    },
    // 获取工程项目
    project: {
        method: getProjectList,
        label: "projectName",
        value: "projectId",
    },
    // 业务员查询
    businessUser: {
        method: getBusinessUser,
        label: "employeeName",
        value: "employeeCode",
    }, // 业务员查询-值为id
    businessUserId: {
        method: getBusinessUser,
        label: "employeeName",
        value: "employeeId",
    },
    // 商品品类查询
    getProductClass: {
        method: getProductClass,
        label: "className",
        value: "classId",
    },
    // 分析模型查询
    getAnalysisModel: {
        method: queryAnalysisModel,
        label: "modelName",
        value: "analysisModelId",
    },
    getUnitByApplicationCode: {
        method: getUnitByApplicationCode,
        label: "unitName",
        value: "unitId",
    }
};
