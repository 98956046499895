import Store from "../plugins/Store";
import httpRequest from "../utils/httpRequest";
const SERVER_NAME = 'entrance';

/**
 * 获取工程项目列表
 * @param {Object} data
 */
export function getEngineeringProject(data) {
    return httpRequest({
        url: '/project/configQuery',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取工程项目详情
 * @param {Object} data
 */
export function getEngineeringProjectDetail(data) {
    return httpRequest({
        url: '/project/get',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 新增工程项目
 * @param {Object} data
 */
export function addEngineeringProject(data) {
    return httpRequest({
        url: '/project/add',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改工程项目
 * @param {Object} data
 */
export function editEngineeringProject(data) {
    return httpRequest({
        url: '/project/update',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}


/**
 * 工程项目信息
 * @param {Object} data
 * @param {string} data.projectName - 查询信息
 * @param {string} data.projectId - 项目id
 */
export function getProjectList(data) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/project/queryList',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    }, {
        closeErrorMessage: true
    });
}



/**
 * 获取销售需求计划列表-需求计划视图
 * @param {Object} data
 */
export function querySaleDemandPage(data) {
    return httpRequest({
        url: '/trading/saleDemandPlan/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取销售需求计划列表-商品视图
 * @param {Object} data
 */
export function querySaleDemandSkuPage(data) {
    return httpRequest({
        url: '/trading/saleDemandPlan/queryProductSkuPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取销售需求计划详情
 * @param {Object} params
 */
export function getSaleDemandPlanDetail(params) {
    return httpRequest({
        url: '/trading/saleDemandPlan/get',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 新增销售需求计划
 * @param {Object} data
 */
export function addSaleDemandPlan(data) {
    return httpRequest({
        url: '/trading/saleDemandPlan/add',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改销售需求计划
 * @param {Object} data
 */
export function editSaleDemandPlan(data) {
    return httpRequest({
        url: '/trading/saleDemandPlan/edit',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}


/**
 * 完结销售需求计划
 * @param {Object} params
 */
export function overSaleDemandPlan(params) {
    return httpRequest({
        url: '/trading/saleDemandPlan/overDemandPlan',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 签章推送成功
 * @param {Object} params
 */
export function pushSignatureStatus(params) {
    return httpRequest({
        url: '/trading/saleDemandPlan/pushSignatureStatus',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 验证销售需求计划
 * @param {Object} params
 */
export function checkSaleDemandPlanOther(params) {
    return httpRequest({
        url: '/trading/saleDemandPlan/checkSaleDemandPlanOther',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 获取采购需求计划列表-需求计划视图
 * @param {Object} data
 */
export function queryPurchaseDemandPage(data) {
    return httpRequest({
        url: '/purchase/purchaseDemandPlan/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取采购需求计划列表-商品视图
 * @param {Object} data
 */
export function queryPurchaseDemandSkuPage(data) {
    return httpRequest({
        url: '/purchase/purchaseDemandPlan/queryProductSkuPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取采购需求计划详情
 * @param {Object} params
 */
export function getPurchaseDemandPlanDetail(params) {
    return httpRequest({
        url: '/purchase/purchaseDemandPlan/get',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}


/**
 * 获取销售订单列表-订单视图
 * @param {Object} data
 */
export function queryEngineerSaleOrderPage(data) {
    return httpRequest({
        url: '/trading/engineeringSaleOrder/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取销售订单列表-商品视图
 * @param {Object} data
 */
export function queryEngineerSaleOrderSkuPage(data) {
    return httpRequest({
        url: '/trading/engineeringSaleOrder/queryProductSkuPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取销售订单可结算订单数量
 * @param {Object} data
 */
export function getEngineerSaleOrderNum(data) {
    return httpRequest({
        url: '/trading/engineeringSaleOrder/countOrderSettlementNum',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 新增销售订单
 * @param {Object} data
 */
export function addEngineerSaleOrder(data) {
    return httpRequest({
        url: '/trading/engineeringSaleOrder/add',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改销售订单
 * @param {Object} data
 */
export function editEngineerSaleOrder(data) {
    return httpRequest({
        url: '/trading/engineeringSaleOrder/edit',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 以下都是采购订单的方法
 * 获取采购订单列表-订单视图
 * @param {Object} data
 */
export function queryEngineerPurchaseOrderPage(data) {
    return httpRequest({
        url: '/purchase/engineeringPurchaseOrder/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取采购订单列表-商品视图
 * @param {Object} data
 */
export function queryEngineerPurchaseOrderSkuPage(data) {
    return httpRequest({
        url: '/purchase/engineeringPurchaseOrder/queryProductSkuPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取采购订单可结算订单数量
 * @param {Object} data
 */
export function getEngineerPurchaseOrderNum(data) {
    return httpRequest({
        url: '/purchase/engineeringPurchaseOrder/countOrderSettlementNum',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

// 工程签收单
/**
 * 获取工程签收单列表
 * @param {Object} data
 */
export function getEngineeringSign(data) {
    return httpRequest({
        url: '/projectSign/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取工程签收单详情
 * @param {Object} params
 */
export function getEngineeringSignDetail(params) {
    return httpRequest({
        url: '/projectSign/get',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 新增签收单
 * @param {Object} data
 */
export function addEngineeringSign(data) {
    return httpRequest({
        url: '/projectSign/add',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改签收单
 * @param {Object} data
 */
export function editEngineeringSign(data) {
    return httpRequest({
        url: '/projectSign/edit',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 删除签收单
 * @param {Object} params
 */
export function delEngineeringSign(params) {
    return httpRequest({
        url: '/projectSign/delete',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 获取工程签收结算明细列表
 * @param {Object} data
 */
export function getEngineeringSignSettlement(data) {
    return httpRequest({
        url: '/projectSignDetail/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 获取工程签收明细结算--项目名称列表
 * @param {Object} data
 */
export function getEngineeringSignProject(data) {
    return httpRequest({
        url: '/projectSignDetail/queryList',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 新增签收单明细结算
 * @param {Object} data
 */
export function addEngineeringSignSettlement(data) {
    return httpRequest({
        url: '/project/settlement/add',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改签收单明细结算
 * @param {Object} data
 */
export function editEngineeringSignSettlement(data) {
    return httpRequest({
        url: '/projectSignDetail/edit',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 核对签收单明细结算
 * @param {Object} data
 */
export function checkEngineeringSignSettlement(data) {
    return httpRequest({
        url: '/projectSignDetail/editCheckStatus',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}
