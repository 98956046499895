<template>
    <div>
        <div
            v-for="(item, index) in Object.keys(formGroup)"
            :key="index"
            class="default-form-section"
        >
            <GlobalForm
                :ref="`form_${item}`"
                v-model="formData[item]"
                v-bind="getForms"
                :form-list="getForms.formGroup[item]"
            />
        </div>
    </div>
</template>

<script>
import GlobalForm from "@/components/GlobalForm";
import {groupBy} from "@/utils/func";
export default {
    name: "GroupForm",
    components: {GlobalForm},
    props: {
        // 表单配置
        formList: {
            type: Array,
            default() {
                return [];
            }
        },
        // 表单值，绑定v-model用
        value: {
            type: Object,
            default() {
                return {};
            }
        },
        // 横向(vertical)，纵向模式(horizontal)
        mode: {
            type: String,
            default: 'horizontal'
        },
        // 一行展示几个表单元素
        oneLineNum: {
            type: Number,
            default: 3,
        },
        // 标题(label)的宽度
        labelWidth: {
            type: Number,
            default: 84
        },
        // 是否开启纯文本显示
        useTextView: {
            type: Boolean,
            default: false,
        },
        // 是否禁用整个表单
        disabled: {
            type: Boolean,
            default: false
        },
        // 展示更多
        useShowMore: {
            type: Boolean,
            default: false,
        },
        // 展示更多开关
        isShowMore: {
            type: Boolean,
            default: false,
        },
        showMoreIndex: {
            type: Number,
            default: 8
        },
        // 表单左/右对齐
        formItemFlex: {
            type: String,
            default: 'left'
        },
        // 是否使用查询模式样式
        useSearchMode: {
            type: Boolean,
            default: false,
        },
        // 是否使用FormTitle组件进行分组
        useFormTitleGroup: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            groupList: {},
            formData: {}, // 表单值
        };
    },
    computed: {
        /**
         * formData拥有的key
         * @return {Object<Array>}
         */
        formKeys() {
            const obj = groupBy(this.formList, item => item.groupTitle),
                keyObj = {};

            Object.keys(obj).forEach(key => {
                keyObj[key] = obj[key].map(tmp => tmp.key);
            });
            return keyObj;
        },
        getForms() {
            const props = {},
                formGroup = groupBy(this.formList, item => item.groupTitle);

            Object.keys(this.$props).forEach(key => {
                if (key !== 'formList' && key !== 'value') {
                    props[key] = this.$props[key];
                }
            });
            props.formGroup = formGroup;
            return props;
        },
        formGroup() {
            return groupBy(this.formList, tmp => tmp.groupTitle);
        }
    },
    watch: {
        value: {
            handler(newVal) {
                this.setFormData(newVal);
            },
            deep: true,
            immediate: true,
        },
        formData: {
            handler(newVal) {
                const obj = {};

                Object.keys(newVal).forEach(key => {
                    Object.keys(newVal[key]).forEach(subKey => {
                        obj[subKey] = newVal[key][subKey];
                    });
                });
                this.$emit('input', obj);
            },
            deep: true,
        }
    },
    methods: {
        /**
         * 获取formData
         * @param {string} [key] 组名
         */
        getFormData(key) {
            if (key) {
                return this.formData[key];
            }
            const obj = {};

            Object.keys(this.formData).forEach(name => {
                obj[name] = this.formData[name];
            });
            return obj;
        },
        /**
         * 外部设置的formData
         * @param {Object} form
         */
        setFormData(form) {
            Object.keys(form).forEach(formKey => {
                Object.keys(this.formKeys).forEach(insideKey => {
                    if (this.formKeys[insideKey].includes(formKey)) {
                        this.formData[insideKey] = this.formData[insideKey] || {};
                        this.formData[insideKey][formKey] = form[formKey];
                        this.$nextTick(() => {
                            this.$set(this.$refs[`form_${insideKey}`][0].formData, formKey, form[formKey]);
                        });
                    }
                });
            });
        }
    },
};
</script>

<style scoped>

</style>
