<template>
    <div class="ellipsis" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
        <Tooltip
            class="ivu-table-cell-tooltip"
            v-if="isTextOverFlow"
            effect="dark"
            :transfer="transfer"
            :max-width="maxWidth"
            placement="bottom"
            :disabled="!showTooltip"
        >
            <div class="ellipsis">
                <slot>
                    <span ref="tooltipContent">{{ content }}</span>
                </slot>
            </div>
            <template #content>
                <slot>
                    <span>{{ content }}</span>
                </slot>
            </template>
        </Tooltip>
        <span v-else ref="tooltipContent">
            <slot>
                <span>{{ content }}</span>
            </slot>
        </span>
    </div>
</template>
<script>
export default {
    props: {
        // 内容
        content: {
            type: [String, Number],
            default: ''
        },
        maxWidth: {
            type: Number,
            default: 300
        },

        transfer: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // 是否超出最大宽度，超出则加载tooltip组件
            isTextOverFlow: false,
            // 是否显示tooltip
            showTooltip: false
        };
    },
    methods: {
        onMouseOver () {
            const parentWidth = this.$refs.tooltipContent?.parentNode?.offsetWidth,
                contentWidth = this.$refs.tooltipContent?.offsetWidth,
                result = contentWidth > parentWidth;

            if (!this.isTextOverFlow) {
                this.isTextOverFlow = result;
            }
            this.showTooltip = result;
        },
        onMouseLeave() {
            if (this.showTooltip) {
                this.showTooltip = false;
            }
        }
    }
};
</script>
