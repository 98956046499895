<template>
    <transition>
        <MainLayout>
            <router-view :key="routerViewKey" />
        </MainLayout>
    </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { queryDictionaryValue} from "./api/base";
import {getLocal, groupBy} from "./utils/func";
import MainLayout from "./components/layout/MainLayout";
import eventBus from "@/utils/eventBus";
import {injectEnv} from "@/inject/env";

export default {
    name: 'App',
    components: {MainLayout},
    data() {
        return {
            routerViewKey: 0,
        };
    },
    computed: {
        ...mapGetters({
            excludeCache: 'comon/excludeCache',
            userInfo: 'comon/userInfo'
        })
    },
    created() {

        this.getDictList();
        let user = localStorage.getItem('userInfo');

        user = user ? JSON.parse(user) : {};

        if (Object.keys(user).length > 0) {
            this.updateUserInfo(user);
        }
        // mockMenu().then(res => {
        //     sortOutMenu.call(this, {
        //         data: res
        //     });
        // });

    },
    mounted() {
        this.refreshView();
        this.getStorageMenuList();
        // let user = localStorage.getItem('userInfo');

        // user = user ? JSON.parse(user) : {};

        // if (Object.keys(user).length > 0) {
        //     this.updateUserInfo(user);
        // }
    },
    methods: {
        ...mapActions('comon', ['updateUserInfo']),
        getStorageMenuList() {
            const userInfo = getLocal('userInfo', true, {});
            if (Array.isArray(userInfo.resourceList)) {
                // this.$store.dispatch('comon/updateRouters', userInfo.resourceList);
            }
        },
    	async getDictList() {
    		const res = await queryDictionaryValue({
			    pageNum: 1,
			    pageSize: -1
		    }),
			 	 list = res.data || [],
		     obj = groupBy(list, item => item.dictionaryCode);

    		this.$store.commit('comon/setDictData', obj);
	    },
        refreshView() {
            eventBus.$on('org-change', item => {
                this.$store.commit('comon/setUserInfo', {
                    ...this.userInfo,
                    orgId: item.orgId,
                    orgName: item.orgName,
                    unitId: item.unitId,
                    ...item.employee
                });
                this.routerViewKey++;
            });
        },
        // refreshView() {
        //     eventBus.$on('org-change', item => {
        //         this.$store.commit('comon/setUserInfo', {...this.userInfo, orgId: item.orgId, orgName: item.orgName});
        //         this.routerViewKey++;
        //     });
        // }
    }
};
</script>
