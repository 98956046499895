<template>
    <div class="step-wrap">
        <div v-for="item in list" :key="item.index" 
            :class="[
                'step-item',
                { 'step-item-pass': getStatus(item.index) === 'pass' },
                { 'step-item-error': getStatus(item.index) === 'error' },
                { 'step-item-progress': getStatus(item.index) === 'progress' },
            ]"
        >
            <div class="step-item-hd">
                <div 
                    v-if="type === 'number'" 
                    class="item-mark item-mark-number"
                    :style="{
                        width: size + 'px',
                        height: size + 'px',
                        lineHeight: (size-4) + 'px'
                    }"
                >
                    <span v-if="getStatus(item.index) === 'progress' || item.index > value">{{ item.index }}</span>
                    <Icon v-if="getStatus(item.index) === 'pass'" type="md-checkmark" :size="size-4" color="#fff" />
                    <Icon v-if="getStatus(item.index) === 'error'" type="md-close" :size="size-4" color="#fff" />
                </div>
                <div v-else class="item-mark item-mark-circle">
                    <div class="mark-circle-point"></div>
                </div>
                <i v-if="item.index !== list.length" class="item-mark-line"></i>
            </div>
            <p class="step-item-bd">{{ item.title }}</p>
            <p v-if="item.subTitle" class="step-item-ft">{{ item.subTitle }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        /**
         * @typedef {Object} stepOption - 步骤配置项
         * @property {string} stepOption.title - 步骤标题
         * @property {string} stepOption.subTitle - 步骤副标题
         */
        stepList: {
            type: Array,
            default: () => []
        },
        // 大小
        size: {
            type: Number,
            default: 20
        },
        // 当前进度
        value: {
            type: Number,
            default: 0
        },
        // 当前进度状态，可选progress、error
        status: {
            type: String,
            default: 'progress'
        },
        // 步骤条类型，可选number、circle
        type: {
            type: String,
            default: 'number'
        }
    },
    computed: {
        list() {
            return this.stepList.map((item, index) => {
                return { index: index+1, ...item };
            });
        }
    },
    methods: {
        getStatus(index) {
            if (index === this.value) {
                return this.status ? this.status : 'pass';
            } else if(index < this.value) {
                return 'pass';
            } else {
                return '';
            }
        }
    }
};
</script>
<style lang="less" scoped>
.step-wrap {
    display: flex;
    .step-item-hd {
        position: relative;
        .item-mark {
            position: relative;
            width: 20px;
            height: 20px;
            border: 2px solid #999;
            border-radius: 100%;
            background-color: #fff;
            box-sizing: border-box;
            z-index: 2;
        }
        .item-mark-circle {
            padding: 2px;
            .mark-circle-point {
                height: 12px;
                border-radius: 100%;
                background-color: #999;
            }
        }
        .item-mark-number {
            line-height: 16px;
            text-align: center;
            i {
                font-size: 16px;
            }
            span {
                color: #999;
                font-size: 14px;
            }
        }
        .item-mark-line {
            display: block;
            position: absolute;
            height: 2px;
            top: 50%;
            left: 50%;
            right: -50%;
            transform: translateY(-50%);
            background-color: #999;
            z-index: 1;
        }
    }
    .step-item {
        flex: 1;
        align-items: center;
        justify-content: center;
        .step-item-hd {
            display: flex;
            justify-content: center;
        }
        .step-item-bd {
            line-height: 14px;
            font-size: 14px;
            font-weight: bold;
            color: #999;
            margin-top: 20px;
            text-align: center;
        }
        .step-item-ft {
            line-height: 10px;
            color: #999;
            font-size: 12px;
            margin-top: 20px;
            text-align: center;
        }
    }
    .step-item-pass,
    .step-item-progress {
        .item-mark-circle {
            border-color: #d0322a;
        }
        .item-mark-number {
            border-color: #d0322a;
            background-color: #d0322a;
            span {
                color: #fff;
            }
        }
    }
    .step-item-error,
    .step-item-progress {
        .step-item-bd {
            color: #d0322a;
        }
    }
    .step-item-pass {
        .item-mark-circle {
            .mark-circle-point {
                background-color: #d0322a;
            }
        }
        .item-mark-line {
            background-color: #d0322a;
        }
        .step-item-bd {
            color: #d0322a;
        }
    }
    .step-item-error {
        .item-mark-circle {
            border-color: #f00;
        }
        .item-mark-number {
            border-color: #f00;
            background-color: #f00;
            span {
                color: #fff;
            }
        }
    }
}
</style>