import httpRequest from "../utils/httpRequest";
const SERVER_NAME = 'entrance';
const SERVER_NAMES = 'third';
/**
 * 业务统计数据
 * @param {Object} data
 */
export function queryBusinessStatistics(data) {
    return httpRequest({
        url: '/platform/queryBusinessStatistics',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 毛利统计表
 * @param {Object} data
 */
export function queryPlatformSaleGrossProfit(data) {
    return httpRequest({
        url: '/platform/saleReport/queryPlatformSaleGrossProfit',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 利率贡献
 * @param {Object} data
 */
export function querySaleGrossProfitDetail(data) {
    return httpRequest({
        url: '/platform/saleReport/querySaleGrossProfitDetail',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 库存
 * @param {Object} data
 */
export function configQuery(data) {
    return httpRequest({
        url: '/platform/stockSummary/configQuery',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 百度统计各图表
 * @param {Object} data
 */
export function chartDisplayData(data) {
    return httpRequest({
        url: '/baidu/statistical/trand/chartDisplayData',
        serverName: SERVER_NAMES,
        method: 'post',
        data,
    });
}

/**
 * 转化率分析
 * @param {Object} data
 */
export function transRatio(data) {
    return httpRequest({
        url: '/baidu/statistical/trand/transRatio',
        serverName: SERVER_NAMES,
        method: 'post',
        data,
    });
}

/**
 * 流量分析
 * @param {Object} data
 */
export function pvCount(data) {
    return httpRequest({
        url: '/baidu/statistical/trand/pvCount',
        serverName: SERVER_NAMES,
        method: 'post',
        data,
    });
}

/**
 * 用户集中度
 * @param {Object} data
 */
export function ipCount(data) {
    return httpRequest({
        url: '/baidu/statistical/trand/ipCount',
        serverName: SERVER_NAMES,
        method: 'post',
        data,
    });
}


/**
 * 用户集中度趋势-按周-报表
 * @param {Object} data
 */
export function chartDisplayDataWeek(data) {
    return httpRequest({
        url: '/baidu/statistical/trand/chartDisplayData/week',
        serverName: SERVER_NAMES,
        method: 'post',
        data,
    });
}

/**
 * 用户集中度趋势-按周-表格
 * @param {Object} data
 */
export function chartIpCountData(data) {
    return httpRequest({
        url: '/baidu/statistical/trand/chartIpCountData/week',
        serverName: SERVER_NAMES,
        method: 'post',
        data,
    });
}

/**
 * 客户满意度-统计图显示查询
 * @param {Object} data
 */
export function getPercentInfo(params) {
    return httpRequest({
        url: '/platform/questionnaire/getPercentInfo',
        serverName: SERVER_NAME,
        method: 'get',
        params,
    });
}

/**
 * 客户满意度-列表显示查询
 * @param {Object} data
 */
export function queryList(data) {
    return httpRequest({
        url: '/platform/questionnaire/queryList',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 行为分析获取分析数据
 * @param {Object} data
 */
export function queryAnalysisData(data) {
    return httpRequest({
        url: '/analysisModel/queryAnalysisData',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 行为分析获取分析模型
 * @param {Object} data
 */
export function queryAnalysisModel(data) {
    return httpRequest({
        url: '/analysisModel/queryPage',
        serverName: 'platform',
        method: 'post',
        data,
    });
}