<template>
    <div class="empty-wrap">
        <img src="@/assets/pictures/bg-404.png" alt="">
    </div>
</template>
<script>
export default {
    name: 'EmptyView'
};
</script>
<style lang="less" scoped>
.empty-wrap {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
</style>