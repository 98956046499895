import Store from "../plugins/Store";
import httpRequest from "../utils/httpRequest";
const SERVER_NAME = 'platform';
const orgId = '0000';

/**
 * 获取类目列表
 * @param {*} data
 */
export function getClassify(data={}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/productClass/getProductClassTree',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取类目列表
 * @param {*} data
 */
export function getClassifyFixOrg(data={}) {
    data.orgId = orgId;
    return httpRequest({
        url: '/productClass/getProductClassTree',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取类目列表
 * @param {*} data
 */
export function getClassifyBySynchro(data={}) {
    return httpRequest({
        url: '/productClass/getProductClassTree',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取类目列表用于视频同步
 * @param {*} data
 */
export function getClassifyBySynchroClass(data={}) {
    return httpRequest({
        url: '/productClass/getClassifyBySynchroClass',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 根据等级和id获取类目
 * @param {Object} data
 */
export function getGlassifyById(data) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.enableFlag = true;
    return httpRequest({
        url: '/productClass/queryList',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 新增类目
 * @param {*} data
 */
export function addClassify(data) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/productClass/add',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 新增类目固定orgId
 * @param {*} data
 */
export function addClassifyFixOrgId(data) {
    data.orgId = orgId;
    return httpRequest({
        url: '/productClass/add',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改类目
 * @param {*} data
 */
export function editClassify(data) {
    return httpRequest({
        url: '/productClass/edit',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 删除类目
 * @param {*} data
 */
export function delClassify(params) {
    return httpRequest({
        url: '/productClass/delete',
        serverName: SERVER_NAME,
        method: 'get',
        params,
    });
}

/**
 * 获取商品列表
 * @param {Object} data
 */
export function getGoods(data) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/productSku/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取商品列表商品资料库列表使用
 * @param {Object} data
 */
export function getGoodsFixOrg(data) {
    data.orgId = orgId;
    return httpRequest({
        url: '/productSku/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取商品详情
 * @param {Object} params
 */
export function getGoodsDetail(params) {
    return httpRequest({
        url: '/productSku/get',
        serverName: SERVER_NAME,
        method: 'get',
        params,
    });
}

/**
 * 新增商品
 * @param {*} data
 */
export function addGoods(data) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/productSku/add',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 新增商品固定orgId
 * @param {*} data
 */
export function addGoodsFixOrg(data) {
    data.orgId = orgId;
    return httpRequest({
        url: '/productSku/add',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改商品
 * @param {*} data
 */
export function editGoods(data) {
    return httpRequest({
        url: '/productSku/edit',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

// /**
//  * 修改商品固定orgId
//  * @param {*} data
//  */
// export function editGoodsFixOrgId(data) {
//     return httpRequest({
//         url: '/productSku/edit',
//         serverName: SERVER_NAME,
//         method: 'post',
//         data,
//     });
// }

/**
 * 批量修改商品
 * @param {*} data
 */
export function editBatchGoods(data) {
    return httpRequest({
        url: '/productSku/editBatch',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 删除商品
 * @param {*} data
 */
export function delGoods(params) {
    return httpRequest({
        url: '/productSku/delete',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 批量删除商品
 * @param {*} data
 */
export function delBatchGoods(data) {
    return httpRequest({
        url: '/productSku/deleteBacth',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 启用禁用商品
 * @param {String} skuId 商品id
 * @param {Boolean} enableFlag 商品状态
 */
export function disableGoods(data) {
    return httpRequest({
        url: '/productSku/editEnableFlag',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 商品同步
 * @param {*} data
 */
export function syncGoods(data) {
    return httpRequest({
        url: '/productSku/synchroProduct',
        serverName: 'foundation',
        method: 'post',
        data
    });
}

/**
 * 获取商品属性列表
 * @param {Object} data
 */
export function getGoodsAttr(data) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/productBase/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取商品属性详情
 * @param {Object} params
 */
export function getGoodsAttrDetail(params) {
    return httpRequest({
        url: '/productBase/get',
        serverName: SERVER_NAME,
        method: 'get',
        params,
    });
}

/**
 * 新增商品属性
 * @param {*} data
 */
export function addGoodsAttr(data) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/productBase/add',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改商品属性
 * @param {*} data
 */
export function editGoodsAttr(data) {
    return httpRequest({
        url: '/productBase/edit',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 删除商品属性
 * @param {*} data
 */
export function delGoodsAttr(params) {
    return httpRequest({
        url: '/productBase/delete',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 启用禁用商品属性
 * @param {String} baseId 商品属性id
 * @param {Boolean} enableFlag 商品属性状态
 */
export function disableGoodsAttr(data) {
    return httpRequest({
        url: '/productBase/editEnableFlag',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 获取商品品名列表
 * @param {Object} data
 */
export function getGoodsName(data) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/productBrand/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取商品品名详情
 * @param {Object} params
 */
export function getGoodsNameDetail(params) {
    return httpRequest({
        url: '/productBrand/get',
        serverName: SERVER_NAME,
        method: 'get',
        params,
    });
}

/**
 * 新增商品品名
 * @param {*} data
 */
export function addGoodsName(data) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/productBrand/add',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改商品品名
 * @param {*} data
 */
export function editGoodsName(data) {
    return httpRequest({
        url: '/productBrand/edit',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 删除商品品名
 * @param {*} data
 */
export function delGoodsName(params) {
    return httpRequest({
        url: '/productBrand/delete',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 启用禁用商品品名
 * @param {Object} data 商品品名对象
 */
export function disableGoodsName(data) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/productBrand/editEnableFlag',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 商品基础信息
 * @param {Object} data
 * @param {string} data.categoryType - 类型：01材质，02规格，03产地
 * @param {string} data.baseName - 查询信息
 * @param {string} data.classId - 分类id
 */
export function goodsBase(data) {
    data.orgId = data.orgId || Store.getters['comon/userInfo'].orgId;
    data.enableFlag = true;
    return httpRequest({
        url: '/productBase/queryList',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    }, {
        closeErrorMessage: true
    });
}

/**
 * 商品材质信息
 * @param {Object} data
 * @param {string} data.baseName - 查询信息
 * @param {string} data.classId - 分类id
 */
export function goodsBrand(data) {
    data.orgId = data.orgId || Store.getters['comon/userInfo'].orgId;
    data.enableFlag = true;
    return httpRequest({
        url: '/productBrand/queryList',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    }, {
        closeErrorMessage: true
    });
}
