import httpRequest from "../utils/httpRequest";
import API from '@/api';
import Store from "@/plugins/Store";

const SERVER_NAME = 'foundation';

/**
 * 同步sku信息
 * @param {Object} params
 */
export function syncSku(params) {
    return httpRequest({
        url: '/productSku/syncAllSkuToDotax',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 文件上传
 * @param {FormData | FileList} data
 * @return {Promise<never>|Promise<AxiosResponse<any>>}
 */
export function attachUpload(data) {
    return httpRequest({
        url: '/attach/upload',
        serverName: SERVER_NAME,
        method: 'post',
        data
    }, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
}

/**
 *分类商品属性值列表
 * @param {Object} data
 */
export function getProductAttributeList( data= {}) {
    return httpRequest({
        url: '/productAttribute/list',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 获取二级分类
 * @param data
 */
export function getProductClass(data) {
    data.enableFlag = true;
    return httpRequest({
        url: '/productClass/list',
        serverName: 'foundation',
        method: 'post',
        data,
    }, {
        closeErrorMessage: true
    });
}

export const pictureView = `${API.server}/${SERVER_NAME}/public/user/picture/view?path=`;
