<template>
    <div class="global-input-number-wrap ivu-input-wrapper" :class="{ 'global-input-number-focus': isFocus }">
        <div 
            id="global-input-number-prepend" 
            class="input-number-pend input-number-prepend"
            :class="{ 'input-number-pend-disabled': disabled }"
        >
            <span v-if="prepend" class="number-pend-text">{{ prepend }}</span>
            <slot name="prepend"></slot>
        </div>
        <InputNumber
            v-model="currentValue"
            :min="min"
            :max="max"
            :readOnly="readOnly"
            :disabled="disabled"
            :precision="precision"
            :activeChange="activeChange"
            :placeholder="placeholder"
            :formatter="formatter"
            :parser="parser"
            @on-change="onChange"
            @on-focus="onFocus"
            @on-blur="onBlur"
            ref="inputNumber"
            class="input-number"
        />
        <div 
            id="global-input-number-append" 
            class="input-number-pend input-number-append"
            :class="{ 'input-number-pend-disabled': disabled }"
        >
            <span v-if="append" class="number-pend-text">{{ append }}</span>
            <slot name="append"></slot>
        </div>
    </div>
</template>
<script>
import { InputNumber } from 'view-design';
export default {
    components: {
        InputNumber
    },
    props: {
        value: {
            type: Number,
            default: 0
        },
        // 输入框头部文字
        prepend: {
            type: String
        },
        // 输入框尾部文字
        append: {
            type: String
        },
        // 最小值
        min: {
            type: Number
        },
        // 最大值
        max: {
            type: Number,
        },
        // 自动聚焦
        autofocus: {
            type: Boolean,
            default: false
        },
        // 只读
        readOnly: {
            type: Boolean,
            default: false
        },
        // 禁用
        disabled: {
            type: Boolean,
            default: false
        },
        // 数值精度
        precision: {
            type: Number
        },
        // 是否实时响应数据
        activeChange: {
            type: Boolean,
            default: false
        },
        // 占位文本
        placeholder: {
            type: String
        },
        // 指定输入框展示值的格式
        formatter: {
            type: Function
        },
        // 指定从 formatter 里转换回数字的方式，和 formatter 搭配使用
        parser: {
            type: Function
        }
    },
    computed: {
        currentValue: {
            set(val) {
                this.$emit('input', val);
            },
            get() {
                return this.value;
            }
        },
    },
    data() {
        return {
            isFocus: false
        };
    },
    methods: {
        onChange(e) {
            this.$emit('on-change', e);
        },
        onFocus(e) {
            this.isFocus = true;
            this.$emit('on-focus', e);
        },
        onBlur(e) {
            this.isFocus = false;
            this.$emit('on-blur', e);
        },
    }
};
</script>
<style lang="less" scoped>
.global-input-number-wrap {
    display: flex;
    border: 1px solid #dcdee2;
    border-radius: 2px;
    .input-number {
        flex: 1;
        border: 0;
    }
    .input-number-pend {
        background-color: #fff;
        display: flex;
        align-items: center;
        .number-pend-text {
            color: #666666;
            padding: 0 10px;
            font-size: 12px;
            font-weight: 400;
        }
    }
    .input-number-pend-disabled {
        background-color: #f3f3f3;
        color: #808695;
    }
}
.global-input-number-focus {
    border-color: #dcdee2;
    box-shadow: 0 0 5px rgba(107, 116, 143, .2);
}
.ivu-form-item-error {
    .global-input-number-wrap {
        border-color: #f56c6c;
    }
    .global-input-number-focus {
        border-color: #ed4014;
        box-shadow: 0 0 0 2px rgba(237, 64, 20, .2);
    }
}
</style>
<style lang="less">
.global-input-number-wrap {
    .ivu-input-number-handler-wrap {
        display: none;
    }
}
</style>
