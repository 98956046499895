export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
    // 双向绑定弹框显示状态
        showModal: {
            set(value) {
                this.$emit('input', value);
            },
            get() {
                return this.value;
            },
        },
    },
};
