// 导出页面为PDF格式
import html2Canvas from 'html2canvas';
import JsPDF from 'jspdf';

export default {
    install(Vue) {
        Vue.prototype.$getPDF = function(domClass, title = `sf_${new Date().getTime()}`) {
            const element = document.querySelector(domClass); // 这个dom元素是要导出pdf的div容器
            html2Canvas(element).then(function(canvas) {
                const realStyles = window.getComputedStyle(element);
                const contentWidth = parseFloat(realStyles.width);
                const contentHeight = parseFloat(realStyles.height);

                //一页pdf显示html页面生成的canvas高度;
                // let pageHeight = contentWidth / 592.28 * 841.89;
                //未生成pdf的html页面高度
                // let leftHeight = contentHeight;
                //页面偏移
                // let position = 0;
                //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
                // let imgWidth = 555.28;
                // let imgHeight = 592.28 / contentWidth * contentHeight;

                const pageData = canvas.toDataURL('image/jpeg', 1.0);

                //        let pdf = new JsPDF('', 'pt', 'a4');
                const pdf = new JsPDF('', 'pt', 'a4'); //不分页
                pdf.addImage(pageData, 'JPEG', 0, 0, contentWidth, contentHeight);

                // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
                // 当内容未超过pdf一页显示的范围，无需分页
                // if (leftHeight < pageHeight) {
                //     pdf.addImage(pageData, 'JPEG', 20, 0, imgWidth, imgHeight);
                // } else {
                //     while (leftHeight > 0) {
                //         pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                //         leftHeight -= pageHeight;
                //         position -= 841.89;
                //         //避免添加空白页
                //         if (leftHeight > 0) {
                //             pdf.addPage();
                //         }
                //     }
                // }
                pdf.save(title + '.pdf');
            });
        };
    }
};
