<template>
    <div style="margin-right: 20px">
        <Avatar icon="ios-person" style="margin-right: 4px;" size="18" />
        <Dropdown @on-click="dropdownClick">
            <span v-text="userInfo.employeeName || ''"></span>
            <Icon type="ios-arrow-down" />
            <DropdownMenu slot="list">
                <!-- <DropdownItem>修改密码</DropdownItem> -->
                <!--<DropdownItem name="reset">重置密码</DropdownItem>-->
                <DropdownItem name="logout">退出登录</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    </div>
</template>

<script>
import { Avatar } from 'view-design';
import { mapGetters, mapActions } from 'vuex';
import { resetPassword, logout } from '@/api/base';
import md5 from 'md5';

export default {
    components: {
        Avatar
    },
    computed: {
        ...mapGetters({
            userInfo: 'comon/userInfo'
        })
    },
    methods: {
        ...mapActions('comon', [
            'updateUserInfo',
        ]),
        dropdownClick(name) {
            if (name === 'reset') {
                this.$Modal.confirm({
                    title: '提示',
                    content: '您确定要重置密码吗？',
                    onOk: () => {
                        this.resetPassword();
                    }
                });
            } else if (name === 'logout') {
                this.$Modal.confirm({
                    title: '提示',
                    content: '您确定要退出登录吗？',
                    onOk: () => {
                        this.logout();
                    }
                });
            }
        },
        goLogin() {
            this.updateUserInfo({});
            this.$router.push('/login');
        },
        async resetPassword() {
            await resetPassword({
                id: this.userInfo.id,
                password: md5('123456')
            });

            this.$Message.success('重置密码成功，密码为123456');
            this.goLogin();
        },
        async logout() {
            await logout();
            this.$Message.success('退出成功');
            this.goLogin();
        }
    }
};
</script>

<style lang="less">
span:hover {
    cursor: pointer;
}
</style>
