<!--主布局-->
<template>
    <div class="main-content">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'MainView',
};
</script>

<style lang="less" scoped>
.main-content {
    height: 100%;
}
</style>
