import { resetRouter } from '@/plugins';
/**
 * @method currentSecret 自定义加密与解密
 * @param {string} text 需要加密或解密的文本
 * @param {string} type encode|decrypt
 */
function currentSecret(text, type) {
    if (!text) return text;

    let result = '',
        str = '';
    const special = [
        'a',
        '@',
        'C',
        'e',
        'f',
        '^',
        'Y',
        'i',
        '(',
        ')',
        't',
        '=',
        '~',
        'P',
        '[',
        'k',
        'm',
        '}',
        'g',
        '>',
        'Q',
        'r',
        '%',
        'W',
        '_',
        'd',
        'b',
        '!',
        '`',
        'n',
        'U',
        'z',
        '?',
        'l'
    ];

    try {
        switch (type) {
        case 'encode':
            let k;
            for (k of text) {
                const i = Math.floor(Math.random() * (special.length - 1)),
                    code = k.codePointAt();

                result += `${special[i]}${code}`;
            }
            result += 'D';
            return result;
        case 'decrypt':
            let j;
            for (j of text) {
                if (special.indexOf(j) === -1) {
                    str += j;
                } else if (str !== '') {
                    result += String.fromCodePoint(str);
                    str = '';
                }
                if (j === 'D') {
                    result += String.fromCodePoint(
                        str.substr(0, str.length - 1)
                    );
                }
            }
            return result;
        }
    } catch (error) {
        return '';
    }
}
/**
 * @description 处理后端返回的菜单数据
 * @param {Array} data 菜单数据
 * @param {String} childrenName 子菜单名
 * @param {String} componentPath 路由路径字段
 */
function sortOutMenu({
    data = [],
    childrenName = 'children',
    componentPath = 'componentPath'
}) {
    const {
            $store,
            $router,
            $comon: { getType }
        } = this,
        forEach = d => {
            for (let i = d.length - 1, item; i >= 0; i--) {
                item = d[i];
                item = Object.assign(item, {
                    component: () => import(`@/views/${item[componentPath]}`)
                });
                if (getType(item[childrenName]) === 'Array') {
                    item = Object.assign(item, {
                        component: {
                            render() {
                                return <router-view />;
                            }
                        }
                    });
                    forEach(item[childrenName]);
                }
            }
        };

    forEach(data);
    resetRouter();
    $router.addRoutes(data);
    $router.addRoute({
        path: '*',
        redirect: '/notFound'
    });
    // $store.dispatch('comon/updateRouters', data.concat($router.options.routes));
}

export default { currentSecret, sortOutMenu };
export { currentSecret, sortOutMenu };
