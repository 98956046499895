<template>
	<Modal v-model="showModal" title="自定义列设置" width="1000px" @on-ok="ok">
		<Table :columns="columns" :data="settingData" draggable height="600" @on-drag-drop="editModalDragDrop">
			<template #isShow="{ column, index }">
				<div @mousedown="e => e.stopPropagation()">
					<Checkbox v-model="settingData[index][column.key]" />
				</div>
			</template>
			<template #width="{ column, index }">
				<div @mousedown="e => e.stopPropagation()">
					<InputNumber v-model="settingData[index][column.key]" :min="1" />
				</div>
			</template>
		</Table>
	</Modal>
</template>

<script>
import modalMixin from "../mixins/modalMixin";
import {deepCopy} from "@/utils/func";
export default {
    name: "EditColumnModal",
    mixins: [modalMixin],
    props: {
    	data: {
    		type: Array,
		    default() { return [];}
	    }
    },
    data() {
        return {
            columns: [
                { key: 'isShow', title: '是否显示', align: 'center', slot: 'isShow' },
                { key: 'title', title: '列标题' },
                { key: 'order', title: '调整顺序', render: () => <div><Icon type="ios-list" size={22} /></div> }
            ],
	        settingData: []
        };
    },
    watch: {
    	showModal(status) {
    		if (status) {
    			this.initData();
		    }
	    }
    },
    methods: {
    	  // 拖拽回调
        editModalDragDrop(index1, index2) {
            this.settingData = this.swapArray(this.settingData, index1, index2);
        },
	      // 初始化列设置
        initData() {
            this.settingData = deepCopy(this.data);
        },
	      // 数组调换顺序
        swapArray(arr, index1, index2) {
            arr[index1] = arr.splice(index2, 1, arr[index1])[0];
            return arr;
        },
	      // 确定
	      ok() {
        	this.$emit('confirm', this.settingData);
	      }
    }
};
</script>

<style scoped>

</style>
