<template>
  <RadioGroup v-model="reactiveValue" v-on="$listeners">
    <Radio v-for="(item, index) in dictList" :key="index" :label="item.value" :disabled="item.disabled">{{ item.name }}</Radio>
  </RadioGroup>
</template>

<script>
import {isNotEmpty} from "@/utils/func";

export default {
    name: "DictRadioGroup",
    props: {
        dict: {
            type: String
        },
        isSearch: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Boolean],
            default: null
        }
    },
    computed: {
        dictList() {
            if (this.dict === 'switch') return [{ value: '1', name: '是' }, { value: '0', name: '否' }];
            if (isNotEmpty(this.dict)) return this.$comon.$dict(this.dict) || [];
            return [];
        },
        reactiveValue: {
            set(val) {
                this.$emit('input', val);
            },
            get() {
                return this.value;
            }
        }
    }
};
</script>

<style scoped>

</style>
