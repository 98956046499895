<template>
	<div class="form-title">
		<span class="title">{{ title }}</span>
		<slot name="extra"></slot>
	</div>
</template>

<script>
export default {
    name: "FormTitle",
    props: {
    	title: {
    		type: String,
		    default: '表单标题'
	    }
    }
};
</script>

<style scoped>

</style>
