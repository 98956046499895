<template>
    <Dropdown @on-click="dropMenuClick">
        <Button type="text">
            {{ currentMenu }}
            <Icon type="ios-arrow-down" />
        </Button>
        <template #list>
            <DropdownMenu>
                <DropdownItem
                    v-for="item in userInfo.orgList"
                    :key="item.orgId"
                    :name="item.orgId"
                >{{ item.orgName }}</DropdownItem>
                <!-- <DropdownItem name="湖南三峰科技有限公司">湖南三峰科技有限公司</DropdownItem> -->
            </DropdownMenu>
        </template>
    </Dropdown>
</template>

<script>
import { mapState } from 'vuex';
import eventBus from "@/utils/eventBus";
import {switchCompany} from "@/api/base";
export default {
    name: "OrgInfo",
    data() {
        return {
            currentMenu: '',
        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.comon.userInfo
        }),
    },
    created() {
        this.currentMenu = this.userInfo.orgName;
    },
    methods: {
        /**
         * 公司下拉点击事件
         * @param {string} name - 菜单候选项的name值
         */
        async dropMenuClick(name, orgId) {
            const item = this.userInfo.orgList.find(item => item.orgId === name || orgId === item.orgId) || {};
            const res = await switchCompany({
                orgId: orgId || item.orgId,
                applicationCode: '1048',
            });
            if (!res.data || !Array.isArray(res.data.resourceList) || res.data.resourceList.length === 0) {
                this.$Message.error('当前机构无访问权限');
                this.dropMenuClick('', this.userInfo.orgId);
                return;
            }
            res.data.userCode = res.data?.user?.userCode;
            res.data.orgId = orgId || item.orgId;
            res.data.orgName = item.orgName;
            this.currentMenu = item.orgName;
            this.$store.dispatch('comon/updateUserInfo', res.data);
            this.$store.dispatch('comon/updateRouters', res.data.resourceList);
            eventBus.$emit('org-change', {
                ...item,
                employee: res.data.employee
            });
        },
        // async dropMenuClick(name) {
        //     const item = this.userInfo.orgList.find(item => item.orgId === name) || {};
        //     const res = await switchCompany({
        //         orgId: item.orgId,
        //         applicationCode: '1048',
        //     });
        //     this.currentMenu = item.orgName;
        //     // this.$store.dispatch('comon/updateRouters', res.data.resourceList);
        //     eventBus.$emit('org-change', item);
        // }
    }
};
</script>

<style scoped>

</style>
