<template>
    <Alert closable class="g-tip">
        <Icon class="g-tip-icon" type="ios-information-circle-outline" size="20" />
        <span>{{ notice }}</span>
    </Alert>
</template>

<script>
// 必要时可使用外部样式覆盖
export default {
    name: "GlobalTip",
    props: {
        notice: {
            type: String,
            default: null,
        }
    }
};
</script>

<style scoped>

</style>
