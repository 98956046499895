<template>
  <div :id="editorName"></div>
</template>

<script>
import { attachUpload, pictureView } from '@/api/foundation';
import { prefixUrl } from '@/api';
export default {
    name: 'WangEditor',
    props: {
        editorName: {
            type: String,
            default: 'wangEditor',
        },
        height: {
            type: Number,
            default: 500,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            editorInstance: null,
            menu: [
                'head', // 标题
                'bold', // 粗体
                'fontSize', // 字号
                'fontName', // 字体
                'italic', // 斜体
                'underline', // 下划线
                'strikeThrough', // 删除线
                'foreColor', // 文字颜色
                'backColor', // 背景颜色
                'link', // 插入链接
                'list', // 列表
                'justify', // 对齐方式
                'undo', // 撤销
                'redo', // 重复
            ],
            currentText: '',
        };
    },
    watch: {
        disabled: {
            handler(status) {
                if (status) this.editorInstance.disable();
                else this.editorInstance.enable();
            },
        },
    },
    mounted() {
        this.initEditor();
    },
    methods: {
    // 初始化富文本编辑器
        initEditor() {
            // eslint-disable-next-line new-cap
            const editor = new window.wangEditor(`#${this.editorName}`);
            editor.config.height = this.height;
            editor.config.menu = this.menu;
            editor.config.onchange = this.onChange;
            editor.config.zIndex = 10;

            editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
                const formData = new FormData();
                formData.append('file', resultFiles[0]);
                const res = await attachUpload(formData);
                if (res.success) {
                    // 上传图片，返回结果，将图片插入到编辑器中
                    insertImgFn(prefixUrl + res.data.attachPath);
                }
            };
            editor.create();
            this.editorInstance = editor;
            if (this.value) this.editorInstance.txt.html(this.value);
            if (this.disabled) editor.disable();
        },
        // 编辑器输入变化
        onChange(content) {
            // debugger;
            if (!this.editorInstance) return;
            this.reactiveValue = content;
            this.$emit('on-change', content);
        },
        // 设置内容
        setContent(content) {
            this.editorInstance.txt.html(content);
        },
    },
};
</script>
