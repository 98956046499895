<template>
    <Upload
        :action="action"
        :max-size="maxSize"
        :data="data"
        :name="name"
        :headers="headers"
        :format="format"
        :show-upload-list="showUploadList"
        :on-success="onSuccess"
        :on-error="onError"
        :on-format-error="onFormatError"
        :on-exceeded-size="onExceededSize"
        v-bind="$attrs"
        v-on="$listeners"
    >   
        <slot name="uploadText">
           <Button icon="ios-cloud-upload-outline">上传文件</Button>
        </slot>
        
        <slot name="tip"></slot>
    </Upload>
</template>
<script>
import { Upload } from "view-design";
import API from "@/api";
export default {
    components: {
        Upload,
    },
    props: {
        // 文件大小,kb
        maxSize: {
            type: Number,
            default: 0,
        },
        // 额外参数
        data: {
            type: Object,
            default: () => {},
        },
        // 上传文件的参数名
        name: {
            type: String,
            default: "file",
        },
        // 是否显示上传文件列表
        showUploadList: {
            type: Boolean,
            default: true,
        },
        // 支持的文件后缀名
        format: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            action: API.server + "entrance/attach/upload",
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        };
    },
    methods: {
        /**
         * 文件上传成功时
         * @return res 服务器返回结果
         * @return file 当前文件
         * @return fileList 文件list
         */
        onSuccess(res, file, fileList) {
            if (!res.success) return this.$Message.error("上传失败");
            this.$Message.success("文件上传成功");
            this.$emit("on-success", { res, file, fileList });
        },
        /**
         * 文件上传失败时
         * @return err 服务器返回的错误信息
         * @return file 当前文件
         * @return fileList 文件list
         */
        onError(err, file, fileList) {
            this.$Message.error("文件上传失败，请重试！");
            this.$emit("on-error", { err, file, fileList });
        },
        // 文件格式验证失败时
        onFormatError() {
            this.$Message.error("请选择符合要求的文件格式！");
        },
        // 文件超出大小限制时
        onExceededSize() {
            const mb = this.maxSize / 1024;

            this.$Message.error(
                `您选择的文件超过${mb}MB，无法进行上传！请进行压缩或精简后重试！`
            );
        },
    },
};
</script>
