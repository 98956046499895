<!--排序展示-->
<template>
    <div class="sort-order">
        <Row
            v-for="item of list"
            :key="item.key"
            type="flex"
            class="bg-white row"
            style="padding: 10px;"
        >
            <Col flex="70px" class="flex-center font-blod">{{
                item.title
            }}</Col>
            <Col flex="auto">
                <ul class="flex-start-center">
                    <li
                        v-for="ele of item.options"
                        :key="ele.key"
                        class="pointer flex-center"
                        :class="{
                            active: ele.hasIcon
                                ? sortOrde[ele.key] === templateUp ||
                                  sortOrde[ele.key] === templateDown
                                : sortOrde[ele.key] === templateSynthetical
                        }"
                        @click="
                            tagsClick(
                                ele,
                                ele.hasIcon
                                    ? sortOrde[ele.key] === templateUp ||
                                          sortOrde[ele.key] === templateDown
                                    : sortOrde[ele.key] === templateSynthetical
                            )
                        "
                    >
                        <span v-text="ele[item.labelKey || 'label']"></span>
                        <div v-if="ele.hasIcon" class="d-flex flex-column">
                            <Icon
                                type="md-arrow-dropup"
                                :style="iconStyle"
                                :color="
                                    sortOrde[ele.key] === templateUp
                                        ? '#ed4014'
                                        : void 0
                                "
                            />
                            <Icon
                                type="md-arrow-dropdown"
                                :style="iconStyle"
                                :color="
                                    sortOrde[ele.key] === templateDown
                                        ? '#ed4014'
                                        : void 0
                                "
                            />
                        </div>
                    </li>
                </ul>
            </Col>
        </Row>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            required: true,
            type: Array
        },
        templateUp: {
            type: [String, Number, null, undefined],
            default: 'up'
        },
        templateDown: {
            type: [String, Number, null, undefined],
            default: 'down'
        },
        templateSynthetical: {
            type: [String, Number, null, undefined],
            default: 'synthetical'
        }
    },
    data() {
        return {
            sortOrde: {
                synthetical: this.templateSynthetical
            }
        };
    },
    computed: {
        iconStyle() {
            return 'line-height: 0.5; margin-top: 2px;';
        }
    },
    methods: {
        tagsClick({ key, hasIcon }, isActive) {
            const {
                sortOrde,
                templateSynthetical,
                templateUp,
                templateDown
            } = this;

            let value = templateSynthetical;

            if (hasIcon) {
                value =
                    sortOrde[key] === templateUp || !isActive
                        ? templateDown
                        : templateUp;
            }
            this.sortOrde = {};
            this.sortOrde[key] = value;

            this.$emit('on-change', this.sortOrde);
        }
    }
};
</script>

<style lang="less" scoped>
.sort-order {
    margin-top: 10px;
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        padding: 0 4px;
        margin-left: 10px;
        &[class='pointer flex-center']:hover {
            color: #1890ff;
        }
    }
    .border {
        border: 1px solid #e8e8e8;
        padding: 0 4px;
        border-radius: 6px;
    }
}
</style>

<style lang="less">
.sort-order {
    .active {
        color: white;
        background: #1890ff;
    }
}
</style>
