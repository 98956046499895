<template>
  <CheckboxGroup v-model="reactiveValue" v-on="$listeners">
    <Checkbox v-for="(item, index) in dictList" :key="index" :label="item.value">{{ item.name }}</Checkbox>
  </CheckboxGroup>
</template>

<script>
import {isNotEmpty} from "@/utils/func";

export default {
    name: "DictCheckboxGroup",
    props: {
        dict: {
            type: String
        },
        value: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        dictList() {
            if (isNotEmpty(this.dict)) return this.$comon.$dict(this.dict) || [];
            return [];
        },
        reactiveValue: {
            set(val) {
                this.$emit('input', val);
            },
            get() {
                return this.value;
            }
        }
    }
};
</script>

<style scoped>

</style>
