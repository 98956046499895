import { currentSecret } from '@/utils/base';
import routes from '@/plugins/Router/base';
import { getConfigByOrgId } from '@/api/setting';
const env = window.VUE_APP_ENV || process.env.VUE_APP_ENV;
// 格式化菜单
const formatRoute = (list = []) => {
    return list.map(item => {
        item.title = item.resourceName;
        if (item.children && item.children.length > 0) {
            item.children = formatRoute(item.children);
        }
        return item;
    });
};

const comon = {
    namespaced: true,
    state: {
        auth: null, // 权限
        userInfo: {
            // orgId: '1111',
            // orgName: '湖南三峰科技有限公司',
            // userId: 'p123456',
            // userName: 'admin'
        }, // 用户信息
        excludeCache: [], // 不需要缓存页面集合
        routers: [], // 路由集合
        menuBtns: [], // 菜单按钮集合
        dictData: {}, // 字典集
        config: {}, // 配置项
        authMap: {}
    },
    mutations: {
        setAuth(state, token) {
            state.auth = token;
        },
        setUserInfo(state, user) {
            state.userInfo = user || {};
        },
        setOrgList(state, orgList) {
            state.orgList = orgList || [];
        },
        setResourceList(state, resourceList) {
            state.resourceList = resourceList || [];
        },
        setExcludeCache(state, excludeCache) {
            state.excludeCache = excludeCache;
        },
        setMenuBtns(state, v) {
            state.menuBtns = v;
        },
        setRouters(state, v) {
            state.routers = v;
        },
        setDictData(state, data) {
            state.dictData = data;
        },
        setConfig(state, v) {
            state.config = v;
        },
        setAuthMap(state, map) {
            state.authMap = map;
        }
    },
    actions: {
        updateUserInfo({ dispatch, commit }, _data) {
            const { user, orgList, resourceList, token, employee } = _data;
            let userInfo = {};
            if (Object.keys(_data).length > 0) {
                let org = {}, resource;

                if (Array.isArray(orgList) && orgList.length > 0) {
                    org = orgList[0];
                }
                if (Array.isArray(resourceList) && resourceList.length > 0) {
                    resource = resourceList[0];
                }
                userInfo = {
                    token,
                    orgId: user.orgId,
                    orgName: user.orgName,
                    user,
                    employee,
                    ...user,
                    ...org,
                    ...resource,
                    ...employee,
                    orgList,
                    userCode: user.userCode,
                    resourceList
                };
            }
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            localStorage.setItem('token', userInfo.token || '');
            if (Object.keys(userInfo).length > 0) {
                userInfo.userName = userInfo.username;
                const route = formatRoute(userInfo.resourceList);

                dispatch('updateRouters', route);
                dispatch('updateConfig', userInfo.orgId);
            }
            commit('setUserInfo', userInfo);
        },
        updateMenuBtns({commit}, v) {
            localStorage.setItem('menuBtns', JSON.stringify(v));
            commit('setMenuBtns', v);
        },
        updateRouters({commit}, v) {
            // commit('setRouters', v);
            if (Array.isArray(v)) {
                commit('setRouters', v);
                const authMap = {};
                function getAuthMap(list) {
                    list.forEach(tmp => {
                        if (tmp.resourceType === '2' && Array.isArray(tmp.children)) {
                            const symbolList = tmp.children
                                .filter(item => item.resourceType === '3');
                            const symbolMap = {};
                            symbolList.forEach(item => {
                                symbolMap[item.permissionSymbol] = item;
                            });
                            authMap[tmp.path || tmp.resourceId] = symbolMap;
                        }
                        if(Array.isArray(tmp.children) && tmp.children.length > 0) {
                            getAuthMap(tmp.children);
                        }
                    });
                }
                getAuthMap(v);
                commit('setAuthMap', authMap);
            }
        },
        // 配置项加载
        async updateConfig({commit}, orgId) {
            const res = await getConfigByOrgId({orgId});

            if (res.success) {
                const obj = {};

                res.data.forEach(item => {
                    obj[item.code] = item.value;
                });
                commit('setConfig', obj);
            }
        },
        exit({ dispatch }, payload) {
            dispatch('updateUserInfo', {});
            // sessionStorage.clear();
            localStorage.removeItem('token', '');
            window.$vueInstance.$router.push({
                name: 'login'
            });
        }
    },
    getters: {
        auth: state => {
            const auth = state.auth || localStorage.getItem('auth');

            return currentSecret(auth, 'decrypt');
        },
        userInfo: state => {
            let userInfo = Object.keys(state.userInfo).length
                ? JSON.stringify(state.userInfo)
                : localStorage.getItem('userInfo');

            userInfo = currentSecret(userInfo, 'decrypt') || userInfo || '{}';

            return JSON.parse(userInfo);
        },
        excludeCache: ({ excludeCache }) => excludeCache,
        menuBtns: ({ menuBtns }) =>
            menuBtns.length
                ? menuBtns
                : JSON.parse(localStorage.getItem('menuBtns') || '[]'),
        routers: ({ routers }) => (routers.filter(m => !m.hide).map(m => {
            return {
                ...m,
                title: m.resourceName || m.title,
                children: m.children
                    ? m.children.map(n => {
                        return {...n, title: n.resourceName || n.title};
                    })
                    : null
            };
        }))
    }
};

export default comon;
