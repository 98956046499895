<template>
  <OriginForm
    v-if="!useDynamicForm"
    ref="form"
    :key="formKey"
    :model="formData"
    :rules="rules"
    :label-width="labelWidth"
    :disabled="disabled"
    class="g-form"
  >
    <Row
      type="flex"
      :justify="formItemFlex === 'left' ? null : 'space-between'"
    >
      <template v-for="(item, index) in showFormList">
        <div
          v-if="
            useFormTitleGroup &&
            item.key !== '_groupTitle' &&
            setFormColHide(item)
          "
          :key="index"
          style="padding-left: 24px"
        >
          <OriginCol
            :style="`width: ${getWidth(item)}; margin-right: 20px;`"
            class="d-flex"
            :class="{
              'flex-end-center': formItemFlex === 'right' && !useFormTitleGroup,
            }"
          >
            <FormItem
              v-if="setFormHide(item)"
              v-show="shouldShowMore(index)"
              :prop="item.key"
              :label="item.title"
              :label-width="item.labelWidth"
              :style="{
                marginBottom: useSearchMode ? '12px' : '20px',
              }"
              :required="requiredFormItems.includes(item.key)"
              v-bind="setFormItemProps(item.formItemProps, item)"
            >
              <!-- 插槽，name同key-->
              <slot :name="item.slotName || item.key" v-bind="{ item, value }">
                <component
                  :is="item.inputType || 'Input'"
                  v-if="!useTextView && !item.useTextView"
                  v-model="formData[item.key]"
                  v-bind="setInputProps(item.inputProps)"
                  :style="
                    item.inputType === 'i-switch'
                      ? {}
                      : {
                          width: item.componentWidth || '200px',
                        }
                  "
                  v-on="setInputOn(item.inputOn)"
                >
                  <template v-if="item.childType">
                    <component
                      :is="item.childType"
                      v-for="n in setChildOptions(item.childOptions)"
                      :key="n.value"
                      :value="n.value"
                      :disabled="n.disabled"
                      v-bind="
                        item.inputType !== 'Select'
                          ? { label: n.value }
                          : { label: n.label }
                      "
                      >{{ n.label }}</component
                    >
                  </template>
                </component>
                <span v-else class="ivu-form-item-label">
                  <slot :name="`${item.textViewSlot}`" v-bind="{ item, value }">
                    {{ formatText(item, formData[item.key]) }}
                  </slot>
                </span>
              </slot>
              <template #label>
                <slot
                  :name="`${item.slotName || item.key}Label`"
                  v-bind="{ item, value }"
                >
                  <span>{{ item.title }}</span>
                </slot>
              </template>
            </FormItem>
          </OriginCol>
        </div>
        <OriginCol
          v-else-if="useFormTitleGroup && item.key === '_groupTitle'"
          :key="index"
          span="24"
          class="d-flex"
        >
          <FormTitle :title="item.title" />
        </OriginCol>
        <template v-else>
          <OriginCol
            v-if="setFormColHide(item)"
            :key="index"
            :style="`width: ${getWidth(item)}; margin-right: 20px;`"
            class="d-flex"
            :class="{
              'flex-end-center': formItemFlex === 'right' && !useFormTitleGroup,
            }"
          >
            <FormItem
              v-if="setFormHide(item)"
              v-show="shouldShowMore(index)"
              :prop="item.key"
              :label="item.title"
              :label-width="item.labelWidth"
              :style="{
                marginBottom: useSearchMode ? '12px' : '16px',
              }"
              :required="requiredFormItems.includes(item.key)"
              v-bind="setFormItemProps(item.formItemProps, item)"
            >
              <!-- 插槽，name同key-->
              <slot :name="item.slotName || item.key" v-bind="{ item, value }">
                <component
                  :is="item.inputType || 'Input'"
                  v-if="!useTextView && !item.useTextView"
                  v-model="formData[item.key]"
                  v-bind="setInputProps(item.inputProps)"
                  :style="
                    item.inputType === 'i-switch'
                      ? {}
                      : {
                          width: item.componentWidth || '200px',
                        }
                  "
                  v-on="setInputOn(item.inputOn)"
                >
                  <template v-if="item.childType">
                    <component
                      :is="item.childType"
                      v-for="n in setChildOptions(item.childOptions)"
                      :key="n.value"
                      :value="n.value"
                      :disabled="n.disabled"
                      v-bind="
                        item.inputType !== 'Select'
                          ? { label: n.value }
                          : { label: n.label }
                      "
                      >{{ n.label }}
                    </component>
                  </template>
                </component>
                <span v-else class="ivu-form-item-label">
                  <slot :name="`${item.textViewSlot}`" v-bind="{ item, value }">
                    {{ formatText(item, formData[item.key]) }}
                  </slot>
                </span>
              </slot>
              <template #label>
                <slot
                  :name="`${item.slotName || item.key}Label`"
                  v-bind="{ item, value }"
                >
                  <span>{{ item.title }}</span>
                </slot>
              </template>
            </FormItem>
          </OriginCol>
        </template>
      </template>
    </Row>
  </OriginForm>

  <OriginForm
    v-else
    ref="form"
    :key="formKey"
    :model="dynamicFormData"
    :label-width="labelWidth"
    :disabled="disabled"
    class="g-form"
  >
    <Row
      type="flex"
      :justify="formItemFlex === 'left' ? null : 'space-between'"
    >
      <template v-for="(item, index) in dynamicFormList">
        <OriginCol :key="index" span="24">
          <FormTitle :title="item[0].groupTitle">
            <template #extra>
              <slot name="titleExtra" v-bind="{ item, index }"></slot>
            </template>
          </FormTitle>
        </OriginCol>
        <template v-for="(tmp, num) in item">
          <OriginCol
            :key="`s_${index}_${num}`"
            :style="`width: ${getWidth(tmp)}; margin-right: 20px;`"
            class="d-flex"
            :class="{
              'flex-end-center': formItemFlex === 'right' && !useFormTitleGroup,
            }"
          >
            <FormItem
              v-if="setFormHide(tmp)"
              v-show="shouldShowMore(index)"
              :prop="`items.${index}.${tmp.key}`"
              :label="tmp.title"
              :label-width="tmp.labelWidth"
              :style="{
                marginBottom: useSearchMode ? '12px' : '16px',
              }"
              :rules="tmp.rules"
            >
              <slot
                :name="tmp.slotName || tmp.key"
                v-bind="{
                  item: tmp,
                  value: dynamicFormData.items[index][tmp.key],
                  index,
                }"
              >
                <component
                  :is="tmp.inputType || 'Input'"
                  v-if="!useTextView && !tmp.useTextView"
                  v-model="dynamicFormData.items[index][tmp.key]"
                  v-bind="setInputProps(tmp.inputProps, index)"
                  :style="
                    tmp.inputType === 'i-switch'
                      ? {}
                      : {
                          width: tmp.componentWidth || '200px',
                        }
                  "
                  v-on="setInputOn(tmp.inputOn)"
                >
                  <template v-if="tmp.childType">
                    <component
                      :is="tmp.childType"
                      v-for="n in setChildOptions(tmp.childOptions)"
                      :key="n.value"
                      :value="n.value"
                      :disabled="n.disabled"
                      v-bind="
                        item.inputType !== 'Select'
                          ? { label: n.value }
                          : { label: n.label }
                      "
                      >{{ n.label }}
                    </component>
                  </template>
                </component>
              </slot>
            </FormItem>
          </OriginCol>
        </template>
      </template>
    </Row>
  </OriginForm>
</template>

<script>
import {
    isEmpty,
    isNotEmpty,
    isObject,
    deepClone,
    groupBy,
    fixedDecimalString,
} from "@/utils/func";
import { Form as OriginForm, Col as OriginCol } from "view-design";
import moment from "moment";
import FormTitle from "@/components/FormTitle";
import {
    checkBankCardNo,
    checkFax,
    checkIdCard,
    checkPhoneNumber,
} from "@/utils/formValidators";

export default {
    name: "GlobalForm",
    components: {
        FormTitle,
        OriginForm,
        OriginCol,
    },
    props: {
    // 是否使用动态表单项
        useDynamicForm: {
            type: Boolean,
            default: false,
        },
        // 表单配置
        formList: {
            type: Array,
            default() {
                return [];
            },
        },
        // 表单值，绑定v-model用
        value: {
            type: [Object, Array],
            default() {
                return {};
            },
        },
        // 横向(vertical)，纵向模式(horizontal)
        mode: {
            type: String,
            default: "horizontal",
        },
        // 一行展示几个表单元素
        oneLineNum: {
            type: Number,
            default: 3,
        },
        // 标题(label)的宽度
        labelWidth: {
            type: Number,
            default: 84,
        },
        // 是否开启纯文本显示
        useTextView: {
            type: Boolean,
            default: false,
        },
        // 是否禁用整个表单
        disabled: {
            type: Boolean,
            default: false,
        },
        // 展示更多
        useShowMore: {
            type: Boolean,
            default: false,
        },
        // 展示更多开关
        isShowMore: {
            type: Boolean,
            default: false,
        },
        showMoreIndex: {
            type: Number,
            default: 8,
        },
        // 表单左/右对齐
        formItemFlex: {
            type: String,
            default: "left",
        },
        // 是否使用查询模式样式
        useSearchMode: {
            type: Boolean,
            default: false,
        },
        // 是否使用FormTitle组件进行分组
        useFormTitleGroup: {
            type: Boolean,
            default: false,
        },
        // 不重置禁用项
        noResetDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            insideFormRules: {}, // 校验规则
            requiredFormItems: [], // 必填项字段
            formData: {
                pca: [],
            },
            insideFormList: [],
            formKey: 0, // force update
            dynamicFormData: {
                items: [],
            },
            dynamicFormList: [],
        };
    },
    computed: {
        rules() {
            return this.init();
        },
        showFormList() {
            if (this.useFormTitleGroup) {
                const groupList = groupBy(this.formList, (tmp) => tmp.groupTitle);
                let arr = [];

                Object.keys(groupList).forEach((key) => {
                    arr.push({
                        key: "_groupTitle",
                        title: key,
                    });
                    arr = [...arr, ...groupList[key]];
                });

                return arr;
            }
            return this.formList;
        },
    },
    watch: {
        formData: {
            handler(val) {
                this.$emit("input", val);
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.initDynamicFormData();
    },
    methods: {
    /**
     * 重渲染动态表单
     * @param {Object} [initData]
     */
        initDynamicFormData(initData) {
            if (!this.useDynamicForm) return;
            this.dynamicFormData.items = this.formList.map((tmp, index) => {
                let obj = {};

                if (this.dynamicFormData.items.length > 0) {
                    obj = { ...this.dynamicFormData.items[index] };
                }
                tmp.forEach((m) => {
                    const item = this.dynamicFormData.items[index] || {};
                    obj[m.key] = item[m.key] || null;
                    if (index === this.formList.length - 1 && isObject(initData)) {
                        obj[m.key] = initData[m.key];
                    }
                    const phoneTitles = ["电话", "手机", "联系方式", "传真"];
                    const idCardTitles = ["身份证", "身份证号"];
                    const bankAccountTitles = ["银行账号", "账号", "银行账户", "账户"];
                    const moneyTitles = [
                        "金额",
                        "价",
                        "费",
                        "价格",
                        "总额",
                        "余额",
                        "补退",
                        "收付",
                        "税额",
                    ];
                    const weightTitles = ["重量", "吨数"];
                    m.rules = m.rules || {};
                    phoneTitles.forEach((str) => {
                        if (
                            typeof m.title === "string" &&
              m.title.includes(str) &&
              !m.rules.validator
                        ) {
                            if (m.title.includes("传真")) {
                                m.rules.validator = checkFax();
                            } else {
                                m.rules.validator = checkPhoneNumber();
                            }
                        }
                    });
                    idCardTitles.forEach((str) => {
                        if (
                            typeof m.title === "string" &&
              m.title === str &&
              !m.rules.validator
                        ) {
                            m.rules = m.rules || {};
                            m.rules.validator = checkIdCard();
                        }
                    });
                    bankAccountTitles.forEach((str) => {
                        if (
                            typeof m.title === "string" &&
              m.title === str &&
              !m.rules.validator
                        ) {
                            m.rules.validator = checkBankCardNo();
                        }
                    });
                    moneyTitles.forEach((str) => {
                        if (
                            typeof m.title === "string" &&
              m.title.endsWith(str) &&
              !m.title.includes("元")
                        ) {
                            m.title = `${m.title}(元)`;
                        }
                    });
                    weightTitles.forEach((str) => {
                        if (
                            typeof m.title === "string" &&
              m.title.endsWith(str) &&
              !m.title.includes("吨")
                        ) {
                            m.title = `${m.title}(吨)`;
                        }
                    });
                });
                return obj;
            });
            this.dynamicFormList = [...this.formList];
            this.$emit("input", this.dynamicFormData.items);
        },
        /**
     * 获取动态表单组件值
     * @return {Object[]}
     */
        getDynamicFormData() {
            return this.showFormList
                .map((item) => {
                    const obj = {};
                    item.forEach((tmp) => {
                        if (isNotEmpty(tmp._value)) {
                            obj[tmp.key] = tmp._value;
                        }
                    });
                    return obj;
                })
                .filter((m) => Object.keys(m).length > 0);
        },
        /**
     * 是否展示更多
     * @param {number} index - 下标
     * @return {Boolean}
     */
        shouldShowMore(index) {
            if (index <= this.showMoreIndex || !this.useShowMore) return true;
            return index > this.showMoreIndex && this.useShowMore && this.isShowMore;
        },
        /**
     * 设置是否隐藏当前FormItem
     * @param {Object} item - 当前formItem配置
     * @param {Boolean | function(item: Object): Boolean} item.hideFormItem - 是否隐藏
     * @return {Boolean}
     */
        setFormHide(item) {
            if (item.key === "_groupTitle") return false;
            if (item.hideFormItem instanceof Function) {
                return !item.hideFormItem(item);
            }
            return !item.hideFormItem;
        },
        /**
     * 设置是否隐藏当前FormItem的列
     * @param {Object} item - 当前formItem配置
     * @param {Boolean | function(item: Object): Boolean} item.hideFormColItem - 是否隐藏列
     * @return {Boolean}
     */
        setFormColHide(item) {
            if (item.key === "_groupTitle") return false;
            if (item.hideFormColItem instanceof Function) {
                return !item.hideFormColItem(item, this.formData);
            }
            return !item.hideFormColItem;
        },
        /**
     * 初始化formData
     * @param {Object | Object[]} data
     */
        initFormData(data) {
            if (!this.useDynamicForm) {
                if (!isObject(data))
                    throw new Error("固定表单模式下传入的初始值必须是一个对象");
                this.formData = deepClone(data);
            } else {
                if (!Array.isArray(data))
                    throw new Error("动态表单模式下传入的初始值必须是一个对象数组");
                this.dynamicFormData.items = deepClone(data);
            }
        },
        // 强制重置
        reset() {
            const obj = {};
            if (this.noResetDisabled) {
                let disabledKeys = [];

                if (!this.useDynamicForm) {
                    disabledKeys = this.formList
                        .filter((tmp) => tmp.inputProps && tmp.inputProps.disabled)
                        .map((m) => m.key);
                }
                Object.keys(this.formData).forEach((key) => {
                    if (disabledKeys.includes(key)) {
                        obj[key] = this.formData[key];
                    }
                });
            }
            this.formData = obj;
            this.formKey++;
        },
        // 获取实时宽度
        getWidth(item) {
            if (this.mode === "vertical") {
                return `100%`;
            }
            if (item.formWidth) {
                return `${item.formWidth}${
                    typeof item.formWidth === "string" && item.formWidth.includes("px")
                        ? ""
                        : "%"
                }`;
            }
            return `306px`;
        },
        // 初始化表单配置
        init() {
            const rules = {};
            const phoneTitles = ["电话", "手机", "联系方式", "传真"];
            const idCardTitles = ["身份证", "身份证号", "项目经理身份证"];
            const bankAccountTitles = ["银行账号", "账号", "银行账户", "账户"];
            const moneyTitles = [
                "金额",
                "价",
                "费",
                "价格",
                "总额",
                "余额",
                "补退",
                "收付",
                "税额",
            ];
            const weightTitles = ["重量", "吨数"];

            // 填充默认的校验错误提示信息和校验字段类型
            this.formList.forEach((item) => {
                if (moneyTitles.find((tmp) => item.title.endsWith(tmp))) {
                    item.title = `${item.title}(元)`;
                }
                if (weightTitles.find((tmp) => item.title.endsWith(tmp))) {
                    item.title = `${item.title}(吨)`;
                }
                if (
                    phoneTitles.find((tmp) => item.title.includes(tmp)) ||
          idCardTitles.find(
              (tmp) => item.title === tmp || item.title.endsWith(tmp)
          ) ||
          bankAccountTitles.find(
              (tmp) => item.title === tmp || item.title.endsWith(tmp)
          )
                ) {
                    item.rules = item.rules || {};
                }
                if (isObject(item.rules) && !item.useTextView && !this.useTextView) {
                    rules[item.key] = {};
                    rules[item.key].required = item.rules.required || false;
                    rules[item.key].validator = item.rules.validator || null;
                    rules[item.key].type = item.rules.type || "string";
                    rules[item.key]._title = item.title;
                    rules[item.key]._key = item.key;
                    rules[item.key].trigger = item.rules.trigger || "blur";
                    phoneTitles.forEach((str) => {
                        if (
                            typeof item.title === "string" &&
              item.title.includes(str) &&
              !rules[item.key].validator
                        ) {
                            if (item.title.includes("传真")) {
                                rules[item.key].validator = checkFax();
                            } else {
                                rules[item.key].validator = checkPhoneNumber();
                            }
                        }
                    });
                    idCardTitles.forEach((str) => {
                        if (
                            typeof item.title === "string" &&
              (item.title === str || item.title.endsWith(str)) &&
              !rules[item.key].validator
                        ) {
                            rules[item.key].validator = checkIdCard();
                        }
                    });
                    bankAccountTitles.forEach((str) => {
                        if (
                            typeof item.title === "string" &&
              (item.title === str || item.title.endsWith(str)) &&
              !rules[item.key].validator
                        ) {
                            rules[item.key].validator = checkBankCardNo();
                        }
                    });
                    if (!(rules[item.key].validator instanceof Function)) {
                        rules[item.key].message = isNotEmpty(item.message)
                            ? item.message
                            : `${item.title}不能为空`;
                    }
                }
            });
            return rules;
        },

        /**
     * 供外部调用的校验方法
     * @return {function(): Promise<boolean>}
     */
        validate() {
            return this.$refs.form.validate();
        },
        /**
     * 局部校验
     * @param {string} prop
     * @return {Promise<boolean>}
     */
        validateField(prop) {
            return new Promise((r) => this.$refs.form.validateField(prop, r));
        },
        /**
     * 自定义FormItem props (此方法只针对单个表单，多行动态表单无法使用)
     * @param {Object<string | function>} props
     * @param {Object} options 当前表单配置项
     */
        setFormItemProps(props, options) {
            if (!props) return {};
            const copyOption = {};

            Object.keys(props).forEach((key) => {
                if (props[key] instanceof Function) {
                    copyOption[key] = props[key](options);
                    if (key !== "rules") return;
                    const item = copyOption[key];
                    // 判断对象形式的rules
                    if (
                        isObject(item) &&
            item.required &&
            !item.message &&
            !item.validator
                    ) {
                        item.message = `${options.title}不能为空`;
                    }
                    if (item.validator instanceof Function) {
                        const func = item.validator;
                        item.validator = (rule, value, cb) => {
                            rule._title = options.title;
                            func(rule, value, cb);
                        };
                    }
                    // 判断数组形式的rules
                    if (Array.isArray(props)) {
                        const findRequired = props.find((tmp) => tmp.required);
                        if (
                            findRequired &&
              !findRequired.message &&
              !findRequired.validator
                        ) {
                            findRequired.message = `${options.title}不能为空`;
                        }
                    }
                } else {
                    copyOption[key] = props[key];
                }
            });
            return copyOption;
        },
        /**
     * 自定义表单props
     * @param {Object<string | function>} props
     * @param {Number} index 启用动态表单时当前表单下标
     */
        setInputProps(props, index) {
            if (!props) return {};
            const copyOption = {};

            Object.keys(props).forEach((key) => {
                if (props[key] instanceof Function) {
                    copyOption[key] = props[key](index);
                } else {
                    copyOption[key] = props[key];
                }
            });
            copyOption.disabled = copyOption.disabled || this.disabled;
            return copyOption;
        },
        /**
     * 自定义表单childOptions
     * @list {<Array | function>} list
     */
        setChildOptions(list) {
            if (isEmpty(list)) return [];
            if (list instanceof Array) return list;
            if (list instanceof Function) return list();
        },
        /**
     * 自定义表单listeners
     * @param {Object<function>} listeners
     */
        setInputOn(listeners) {
            const copyOption = {};

            if (!listeners) return copyOption;
            // 实时获取formData
            const getValue = () => this.value;

            Object.keys(listeners).forEach((key) => {
                // eslint-disable-next-line no-console
                if (!(listeners[key] instanceof Function))
                    return console.error(`${key}不是一个函数`);
                copyOption[key] = (...rest) => listeners[key](...rest, getValue);
            });
            return copyOption;
        },
        /**
     * 开启纯文本显示时，对回显数据做处理
     * @param {Object} item - 当前formItem配置
     * @param {string} value - 当前formItem的值
     */
        formatText(item, paramsValue) {
            let value = paramsValue;

            if (item.inputType && isNotEmpty(value)) {
                // 是否为字典类型
                if (
                    item.inputType.indexOf("Dict") !== -1 ||
          (item.inputProps && item.inputProps.dict)
                ) {
                    if (Array.isArray(value)) {
                        value = value.map((n) =>
                            this.$comon.$dict(item.inputProps.dict, n)
                        );
                        return value.join(",");
                    } else {
                        return this.$comon.$dict(item.inputProps.dict, value);
                    }
                    // 是否为时间类型
                } else if (item.inputType.indexOf("Date") !== -1) {
                    if (item.inputProps && item.inputProps.type) {
                        if (item.inputProps.type === "month") {
                            return moment(value).format("YYYY-MM");
                        } else if (item.inputProps.type === "daterange") {
                            return [
                                moment(value[0]).format("YYYY-MM-DD"),
                                moment(value[1]).format("YYYY-MM-DD"),
                            ].join("~");
                        } else if (item.inputProps.type === "datetime") {
                            return moment(value).format("YYYY-MM-DD HH:mm:ss");
                        }
                    } else {
                        return moment(value).format("YYYY-MM-DD");
                    }
                    // 是否为radio类型
                } else if (item.inputType.indexOf("Radio") !== -1) {
                    const options =
              item.childOptions instanceof Function
                  ? item.childOptions()
                  : item.childOptions,
                        row = options.find((n) => n.value === value + 0) || {};

                    return row.label;
                    // 是否为下拉选择框
                } else if (item.inputType.indexOf("Select") !== -1) {
                    const field = item.key.replace("Id", item.renderField || "Name");

                    return this.formData[field];
                }
            }
            if (isNotEmpty(item.toFixed) && typeof item.toFixed === "number") {
                return fixedDecimalString(value, item.toFixed, item.useThousands);
            }
            return value;
        },
    },
};
</script>

<style scoped></style>
