<script>
import { Button } from "view-design";

export default {
    name: "Button",
    props: {
        ...Button.props,
        authSymbol: {
            type: String,
            default: null
        },
        useNormalContent: {
            type: Boolean,
            default: false
        }
    },
    render(createElement, context) {
        const authMap = this.AUTH_MAP[this.$route.path] || {};
        const btnSymbolInfo = authMap[this.authSymbol];
        if (this.authSymbol && !btnSymbolInfo) return null;
        const btnText = (this.useNormalContent || !this.authSymbol) && this.$scopedSlots.default instanceof Function ? this.$scopedSlots.default() : btnSymbolInfo?.resourceName;
        return <Button on={this.$listeners} props={this.$props} attrs={this.$attrs}>{btnText}</Button>;
    }
};
</script>

