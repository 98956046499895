import Vue from "vue";
import iviewArea from 'iview-area';
import getPDF from "@/plugins/getPDF";

import RemoteSelect from "../components/RemoteSelect/RemoteSelect";
import DictSelect from "../components/DictSelect";

const components = {
    RemoteSelect,
    DictSelect,
};

Object.keys(components).forEach(key => {
    Vue.component(key, components[key]);
});
Vue.use(iviewArea);
Vue.use(getPDF);
