
import {isEmpty, isNotEmpty} from "./func";
import { getBankCardReg } from "@/utils/bankCardReg";
const { datas } = getBankCardReg();
/**
 * 检查输入字符长度
 * @param {number} length - 字符长度
 * @param {function(rest: any)} [exCallback] - 额外回调
 * @return {function(rule: Object, value: any, callback: function(cb: string | Error))}
 */
export function checkStringLength(length, exCallback) {
    return (rule, value, callback) => {
        if (isEmpty(value)) {
            return callback(new Error(`${rule._title || rule.label}不能为空`));
        }
        if (value.length > length) {
            if (exCallback) exCallback(value);
            return callback(new Error(`输入不能超过${length}个字`));
        }
        callback();
    };
}

/**
 * 验证手机号
 * @return {function(rule: Object, value: any, callback: function(cb: string | Error))}
 */
export function checkPhoneNumber() {
    return (rule, value, callback) => {
        if (isEmpty(value) && rule.required) {
            return callback(new Error(`${rule._title}不能为空`));
        }
        const reg = /^1[3456789]\d{9}$/;

        if (isNotEmpty(value) && !reg.test(value)) {
            return callback(new Error('请输入正确的手机号'));
        }
        callback();
    };
}

/**
 * 验证银行卡号
 * @return {function(rule: Object, value: any, callback: function(cb: string | Error))}
 */
// export function checkBankCardNo() {
//     const regList = datas.map((tmp) => tmp.patterns).flat(2);
//     return (rule, value, callback) => {
//         if (isEmpty(value) && rule.required) {
//             return callback(new Error(`${rule._title}不能为空`));
//         }
//         let pass = false;
//         for (let i = 0; i < regList.length; ++i) {
//             if (pass) break;
//             const item = regList[i];
//             let regStr = item.reg;
//             regStr = regStr.replace(new RegExp("/", "g"), "");
//             regStr = regStr.replace(new RegExp("g", "g"), "");
//             regStr = regStr.replace(new RegExp("d{", "g"), "d{");
//             const reg = new RegExp(regStr);
//             pass = reg.test(value);
//         }
//         if (isNotEmpty(value) && !pass) {
//             return callback(new Error("请输入正确的银行卡号"));
//         }
//         callback();
//     };
// }

/**
 * 验证银行卡号
 * @return {function(rule: Object, value: any, callback: function(cb: string | Error))}
 */
export function checkBankCardNo() {
    return (rule, value, callback) => {
        if (isEmpty(value) && rule.required) {
            return callback(new Error(`${rule._title}不能为空`));
        }
        // const cardReg = new RegExp(bankCardReg);
        // if (isNotEmpty(value) && !cardReg.test(value)) {
        //     return callback(new Error('请输入正确的银行卡号'));
        // }
        callback();
    };
}

/**
 * 验证身份证号码
 * @return {function(rule: Object, value: any, callback: function(cb: string | Error))}
 */
export function checkIdCard() {
    return (rule, value, callback) => {
        if (isEmpty(value) && rule.required) {
            return callback(new Error(`${rule._title}不能为空`));
        }
        const reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

        if (isNotEmpty(value) && !reg.test(value)) {
            return callback(new Error('请输入正确的身份证号码'));
        }
        callback();
    };
}
/**
 * 验证传真号
 * @return {function(rule: Object, value: any, callback: function(cb: string | Error))}
 */
export function checkFax() {
    return (rule, value, callback) => {
        if (isEmpty(value) && rule.required) {
            return callback(new Error(`${rule._title}不能为空`));
        }
        const mobileReg = /^(\+\d{1,2}-)?(0\d{2,3}-)?\d{7,8}(?:-\d{1,6})?$/;
        if (isNotEmpty(value) && !mobileReg.test(value)) {
            return callback(new Error('请输入正确的传真号'));
        }
        callback();
    };
}
/**
 * 保留两位小数
 * @return {function(rule: Object, value: any, callback: function(cb: string | Error))}
 */
export function checkFloat() {
    return (rule, value, callback) => {
        if (isEmpty(value) && rule.required) {
            return callback(new Error(`${rule._title}不能为空`));
        }
        const reg = /^(\d+)(.\d{0,2})?$/;

        if (!reg.test(value)) {
            return callback(new Error('版本号必须大于0且最多保留两位小数'));
        }
        callback();
    };
}
/**
 * 整数校验
 * @return {function(rule: Object, value: any, callback: function(cb: string | Error))}
 */
export function checkInteger() {
    return (rule, value, callback) => {
        if (isEmpty(value) && rule.required) {
            return callback(new Error(`${rule._title}不能为空`));
        }
        const reg = /^-?[1-9]\d*$/;

        if (!reg.test(value)) {
            return callback(new Error(`${rule._title}必须为整数`));
        }
        callback();
    };
}

export function validatePass(params) {
    return (rule, value, callback) => {
        const reg = /^-?[1-9]\d*$/;
        if (isEmpty(value) && rule.required) {
            callback(new Error('积分上限不能为空'));
        }
        if (!reg.test(value)) {
            return callback(new Error(`积分上限必须为整数`));
        }
        if (value%params !== 0) {
            return callback(new Error(`积分上限必须是单次积分值的自然数倍数`));
        }
        callback();
    };
}
export function checkResource() {
    return (rule, value, callback) => {
        callback();
    };
}
