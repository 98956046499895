import Vue from 'vue';
import VueRouter from 'vue-router';
import base from './base';


const OriginalPush = VueRouter.prototype.push,
    createRouter = () =>
        new VueRouter({
            mode: 'history',
            routes: [...base]
        }),
    Router = createRouter(),
    resetRouter = () => {
        const newRouter = createRouter();

        Router.matcher = newRouter.matcher;
    };

VueRouter.prototype.push = function push(location) {
    return OriginalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

export { resetRouter };
export default Router;
