<template>
    <RadioGroup
        v-model="reactiveValue"
        v-bind="$attrs"
        type="button"
        class="radio-tab"
        v-on="$listeners"
    >
        <Radio
            v-for="(item, index) in tabList"
           :key="index"
           :label="item.key || item.title">
            <CustomIcon v-if="index > 0" type="note" />
            {{ item.title }}
            <span>{{ item.number }}</span>
        </Radio>
    </RadioGroup>
</template>

<script>
import CustomIcon from "@/components/CustomIcon";
export default {
    name: "RadioTab",
    components: {
        CustomIcon,
    },
    props: {
        tabList: {
            type: Array,
            default() {
                return [];
            }
        },
        value: {
            type: String,
            default: null,
        }
    },
    computed: {
        reactiveValue: {
            set(val) {
                this.$emit('input', val);
            },
            get() {
                return this.value;
            }
        }
    },
};
</script>

<style scoped>

</style>
