import httpRequest from "../utils/httpRequest";
import Store from "@/plugins/Store";
const SERVER_NAME = "entrance";

/**
 * 数据字典-搜索字典
 * @param {Object} data
 */
export function contractsUnitAdd(data) {
    data.applicationId = Store.getters["comon/userInfo"].applicationId;
    return httpRequest({
        url: "/contactsUnit/add",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 数据字典-搜索字典
 * @param {Object} data
 * @param {number} data.pageNum - 页码
 * @param {number} data.pageSize - 条目数
 */
export function contractsUnitQueryPage(data) {
    return httpRequest({
        url: "/contactsUnit/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 获取往来跳转编辑获取数据详情
 * @param {string} id - 单位id
 * @return {Promise<AxiosResponse<any>>}
 */
export function getEditUnitDetail(id) {
    return httpRequest({
        url: `/contactsUnit/getContactsUnitById?id=` + id,
        serverName: SERVER_NAME,
        method: "get",
    });
}

/**
 * 往来单位-修改方式
 * @param {Object} data
 */
export function editUnit(data) {
    return httpRequest({
        url: "/contactsUnit/updateContactsUnit",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 获取附件列表
 * @param {Object} data
 * @param {string} data.voucherNum 附件所属id
 * @return {Promise<AxiosResponse<any>>}
 */
export function getUploadFileList(data) {
    return httpRequest({
        url: "/attach/listPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

export function getUploadFileList2(data) {
    return httpRequest({
        url: "/attach/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 附件上传成功后将文件信息保存到数据库
 * @param {Object} data
 * @param {string} data.attachName 附件名称
 * @param {string} data.attachPath 附件保存路径
 * @param {string} data.attachType 附件类型
 * @param {string} data.createDate 附件创建时间
 * @param {string} data.voucherNum 附件所属id
 * @return {Promise<AxiosResponse<any>>}
 */
export function addUploadFile(data) {
    return httpRequest({
        url: "/attach/add",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 附件上传成功后将文件信息保存到数据库-批量
 * @param {Array<Object>} data
 * @param {string} data.attachName 附件名称
 * @param {string} data.attachPath 附件保存路径
 * @param {string} data.attachType 附件类型
 * @param {string} data.createDate 附件创建时间
 * @param {string} data.voucherNum 附件所属id
 * @return {Promise<AxiosResponse<any>>}
 */
export function addUploadFileBatch(data) {
    return httpRequest({
        url: "/attach/batch/add",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 根据附件id删除附件信息
 * @param {Object} params
 * @param {string} params.id 附件id
 * @return {Promise<AxiosResponse<any>>}
 */
export function delUploadFile(params) {
    return httpRequest({
        url: "/attach/removeById",
        serverName: SERVER_NAME,
        method: "get",
        params,
    });
}

/**
 * 根据附件id删除附件信息-批量
 * @param {Object} data
 * @param {Array} data.ids 附件ids
 * @return {Promise<AxiosResponse<any>>}
 */
export function delUploadFileBatch(data) {
    return httpRequest({
        url: "/attach/batchRemoveByIds",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 提货通知列表
 * @param {Object} data
 * @param {string} [data.searchCondition] - 综合搜索(提货凭证、客户、收货区域、操作人)
 * @param {string} [data.deliveryCode] - 提货通知编号
 * @param {string} [data.salesOrderNo] - 销售订单编号
 * @param {string} [data.startDate] - 开始时间
 * @param {string} [data.endDate] - 结束时间
 * @param {string} [data.warehouseId] - 仓库id
 * @param {string} [data.deliveryStatus] - 提货单状态
 * @param {string} data.orgId - 本方公司id
 * @param {number} data.pageNum - 页码
 * @param {number} data.pageSize - 条目数
 * @return {Promise<*>|*}
 */
export function deliveryPage(data) {
    return httpRequest({
        url: "/delivery/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 进项未收票列表
 * @param {Object} data
 * @param {number} data.pageNum - 页码
 * @param {number} data.pageSize - 条目数
 * @return {Promise<*>|*}
 */
export function uncollectedPageQuery(data) {
    return httpRequest({
        url: "/apWriteOff/uncollectedPageQuery",
        method: "post",
        serverName: SERVER_NAME,
        data,
    });
}

/**
 * 进项发票核销选择业务单明细(采购)
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function queryPurchaseOrderDetailByOrderCode(data) {
    return httpRequest({
        url: "/apWriteOff/chooseBillPageQuery",
        method: "post",
        serverName: SERVER_NAME,
        data,
    });
}

/**
 * 进项发票核销选择开平数据
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function queryIncomeDataById(data) {
    return httpRequest({
        url: "/apWriteOff/chooseInvoicePageQuery",
        method: "post",
        serverName: SERVER_NAME,
        data,
    });
}

/**
 * 核销新增
 * @param {Object} data
 * @return {Promise<*>|*}
 */
export function apWriteOffAdd(data) {
    return httpRequest({
        url: "/apWriteOff/add",
        method: "post",
        serverName: SERVER_NAME,
        data,
    });
}

/**
 * 获取所属银行信息
 * @param {Object} data
 */
export function getBank(data = {}) {
    return httpRequest({
        url: "/bankCode/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 获取开户行信息
 * @param {Object} data
 */
export function getAccountBank(data = {}) {
    return httpRequest({
        url: "/bank/queryPage",
        serverName: SERVER_NAME,
        method: "post",
        data,
    });
}

/**
 * 获取基础表公司信息
 * @param {Object} data
 * @param {string} data.unitName
 * @return {Promise<never>|Promise<AxiosResponse<any>>}
 */
export function getBaseUnit(data = {}) {
    data.unitName = data.unitName || "";
    return httpRequest({
        url: "/unit/getUnitList",
        serverName: "base",
        method: "post",
        data,
    });
}
