var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.useDynamicForm)?_c('OriginForm',{key:_vm.formKey,ref:"form",staticClass:"g-form",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":_vm.labelWidth,"disabled":_vm.disabled}},[_c('Row',{attrs:{"type":"flex","justify":_vm.formItemFlex === 'left' ? null : 'space-between'}},[_vm._l((_vm.showFormList),function(item,index){return [(
          _vm.useFormTitleGroup &&
          item.key !== '_groupTitle' &&
          _vm.setFormColHide(item)
        )?_c('div',{key:index,staticStyle:{"padding-left":"24px"}},[_c('OriginCol',{staticClass:"d-flex",class:{
            'flex-end-center': _vm.formItemFlex === 'right' && !_vm.useFormTitleGroup,
          },style:(("width: " + (_vm.getWidth(item)) + "; margin-right: 20px;"))},[(_vm.setFormHide(item))?_c('FormItem',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowMore(index)),expression:"shouldShowMore(index)"}],style:({
              marginBottom: _vm.useSearchMode ? '12px' : '20px',
            }),attrs:{"prop":item.key,"label":item.title,"label-width":item.labelWidth,"required":_vm.requiredFormItems.includes(item.key)},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t(((item.slotName || item.key) + "Label"),function(){return [_c('span',[_vm._v(_vm._s(item.title))])]},null,{ item: item, value: _vm.value })]},proxy:true}],null,true)},'FormItem',_vm.setFormItemProps(item.formItemProps, item),false),[_vm._t(item.slotName || item.key,function(){return [(!_vm.useTextView && !item.useTextView)?_c(item.inputType || 'Input',_vm._g(_vm._b({tag:"component",style:(item.inputType === 'i-switch'
                    ? {}
                    : {
                        width: item.componentWidth || '200px',
                      }),model:{value:(_vm.formData[item.key]),callback:function ($$v) {_vm.$set(_vm.formData, item.key, $$v)},expression:"formData[item.key]"}},'component',_vm.setInputProps(item.inputProps),false),_vm.setInputOn(item.inputOn)),[(item.childType)?_vm._l((_vm.setChildOptions(item.childOptions)),function(n){return _c(item.childType,_vm._b({key:n.value,tag:"component",attrs:{"value":n.value,"disabled":n.disabled}},'component',
                      item.inputType !== 'Select'
                        ? { label: n.value }
                        : { label: n.label }
                    ,false),[_vm._v(_vm._s(n.label))])}):_vm._e()],2):_c('span',{staticClass:"ivu-form-item-label"},[_vm._t(("" + (item.textViewSlot)),function(){return [_vm._v(" "+_vm._s(_vm.formatText(item, _vm.formData[item.key]))+" ")]},null,{ item: item, value: _vm.value })],2)]},null,{ item: item, value: _vm.value })],2):_vm._e()],1)],1):(_vm.useFormTitleGroup && item.key === '_groupTitle')?_c('OriginCol',{key:index,staticClass:"d-flex",attrs:{"span":"24"}},[_c('FormTitle',{attrs:{"title":item.title}})],1):[(_vm.setFormColHide(item))?_c('OriginCol',{key:index,staticClass:"d-flex",class:{
            'flex-end-center': _vm.formItemFlex === 'right' && !_vm.useFormTitleGroup,
          },style:(("width: " + (_vm.getWidth(item)) + "; margin-right: 20px;"))},[(_vm.setFormHide(item))?_c('FormItem',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowMore(index)),expression:"shouldShowMore(index)"}],style:({
              marginBottom: _vm.useSearchMode ? '12px' : '16px',
            }),attrs:{"prop":item.key,"label":item.title,"label-width":item.labelWidth,"required":_vm.requiredFormItems.includes(item.key)},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t(((item.slotName || item.key) + "Label"),function(){return [_c('span',[_vm._v(_vm._s(item.title))])]},null,{ item: item, value: _vm.value })]},proxy:true}],null,true)},'FormItem',_vm.setFormItemProps(item.formItemProps, item),false),[_vm._t(item.slotName || item.key,function(){return [(!_vm.useTextView && !item.useTextView)?_c(item.inputType || 'Input',_vm._g(_vm._b({tag:"component",style:(item.inputType === 'i-switch'
                    ? {}
                    : {
                        width: item.componentWidth || '200px',
                      }),model:{value:(_vm.formData[item.key]),callback:function ($$v) {_vm.$set(_vm.formData, item.key, $$v)},expression:"formData[item.key]"}},'component',_vm.setInputProps(item.inputProps),false),_vm.setInputOn(item.inputOn)),[(item.childType)?_vm._l((_vm.setChildOptions(item.childOptions)),function(n){return _c(item.childType,_vm._b({key:n.value,tag:"component",attrs:{"value":n.value,"disabled":n.disabled}},'component',
                      item.inputType !== 'Select'
                        ? { label: n.value }
                        : { label: n.label }
                    ,false),[_vm._v(_vm._s(n.label)+" ")])}):_vm._e()],2):_c('span',{staticClass:"ivu-form-item-label"},[_vm._t(("" + (item.textViewSlot)),function(){return [_vm._v(" "+_vm._s(_vm.formatText(item, _vm.formData[item.key]))+" ")]},null,{ item: item, value: _vm.value })],2)]},null,{ item: item, value: _vm.value })],2):_vm._e()],1):_vm._e()]]})],2)],1):_c('OriginForm',{key:_vm.formKey,ref:"form",staticClass:"g-form",attrs:{"model":_vm.dynamicFormData,"label-width":_vm.labelWidth,"disabled":_vm.disabled}},[_c('Row',{attrs:{"type":"flex","justify":_vm.formItemFlex === 'left' ? null : 'space-between'}},[_vm._l((_vm.dynamicFormList),function(item,index){return [_c('OriginCol',{key:index,attrs:{"span":"24"}},[_c('FormTitle',{attrs:{"title":item[0].groupTitle},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_vm._t("titleExtra",null,null,{ item: item, index: index })]},proxy:true}],null,true)})],1),_vm._l((item),function(tmp,num){return [_c('OriginCol',{key:("s_" + index + "_" + num),staticClass:"d-flex",class:{
            'flex-end-center': _vm.formItemFlex === 'right' && !_vm.useFormTitleGroup,
          },style:(("width: " + (_vm.getWidth(tmp)) + "; margin-right: 20px;"))},[(_vm.setFormHide(tmp))?_c('FormItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowMore(index)),expression:"shouldShowMore(index)"}],style:({
              marginBottom: _vm.useSearchMode ? '12px' : '16px',
            }),attrs:{"prop":("items." + index + "." + (tmp.key)),"label":tmp.title,"label-width":tmp.labelWidth,"rules":tmp.rules}},[_vm._t(tmp.slotName || tmp.key,function(){return [(!_vm.useTextView && !tmp.useTextView)?_c(tmp.inputType || 'Input',_vm._g(_vm._b({tag:"component",style:(tmp.inputType === 'i-switch'
                    ? {}
                    : {
                        width: tmp.componentWidth || '200px',
                      }),model:{value:(_vm.dynamicFormData.items[index][tmp.key]),callback:function ($$v) {_vm.$set(_vm.dynamicFormData.items[index], tmp.key, $$v)},expression:"dynamicFormData.items[index][tmp.key]"}},'component',_vm.setInputProps(tmp.inputProps, index),false),_vm.setInputOn(tmp.inputOn)),[(tmp.childType)?_vm._l((_vm.setChildOptions(tmp.childOptions)),function(n){return _c(tmp.childType,_vm._b({key:n.value,tag:"component",attrs:{"value":n.value,"disabled":n.disabled}},'component',
                      item.inputType !== 'Select'
                        ? { label: n.value }
                        : { label: n.label }
                    ,false),[_vm._v(_vm._s(n.label)+" ")])}):_vm._e()],2):_vm._e()]},null,{
                item: tmp,
                value: _vm.dynamicFormData.items[index][tmp.key],
                index: index,
              })],2):_vm._e()],1)]})]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }