<template>
	<Button
		v-bind="$props"
		:icon="icon ? icon : (btnTypeList[type] || {}).icon"
		:size="size"
		type="primary"
		:ghost="ghostMap.includes(type)"
		:class="[
      'c-button',
      (btnTypeList[type] || {}).class || '',
      { 'btn-line-style': type === 'diy' },
    ]"
		v-on="{ ...$listeners, click: clickBtn }"
	>
		<slot>{{ (btnTypeList[type] || {}).btnText }}</slot>
	</Button>
</template>

<script>
import { Button } from 'view-design';
export default {
    props: {
        ...Button.props,
        // 按钮类型
        type: {
            type: String,
            default: '',
        },
        // 按钮大小
        size: {
            type: String,
            default: 'default',
        },
        // 自定义按钮图标
        icon: {
            type: String,
            default: '',
        },
        // 是否需要清除缓存
        clearCache: {
            type: Boolean,
            default: false,
        },
        // 需要清除缓存的路由名称
        clearRouter: {
            type: String,
            default: '',
        },
        // 是否返回
        isGoList: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            // 线框类按钮集合
            ghostMap: ['diy', 'reset', 'close', 'goBack', 'cancel', 'copy'],
            btnTypeList: {
                // 主要按钮按钮
                add: {
                    icon: 'md-add-circle',
                    btnText: '新增',
                    class: 'btn-primary-style',
                },
                search: {
                    icon: 'md-search',
                    btnText: '搜索',
                    class: 'btn-primary-style',
                },
                save: {
                    icon: 'md-checkmark-circle-outline',
                    btnText: '保存',
                    class: 'btn-primary-style',
                },
                confirm: {
                    icon: 'md-checkmark-circle',
                    btnText: '确认',
                    class: 'btn-primary-style',
                },
                temporarySave: {
                    icon: 'md-checkmark',
                    btnText: '暂存',
                    class: 'btn-primary-style',
                },
                download: {
                    icon: 'ios-cloud-download',
                    btnText: '下载',
                    class: 'btn-primary-style',
                },

                // 绿色按钮
                edit: {
                    icon: 'ios-create',
                    btnText: '编辑',
                    class: 'btn-edit-style',
                },

                // 红色按钮
                del: {
                    icon: 'md-trash',
                    btnText: '删除',
                    class: 'btn-del-style',
                },
                toVoid: {
                    icon: 'md-close-circle',
                    btnText: '作废',
                    class: 'btn-del-style',
                },

                // 默认底色按钮
                export: {
                    icon: 'ios-share',
                    btnText: '导出',
                    class: 'btn-default-style',
                },
                importBtn: {
                    icon: 'ios-redo',
                    btnText: '导入',
                    class: 'btn-default-style',
                },
                print: {
                    icon: 'ios-print',
                    btnText: '打印',
                    class: 'btn-default-style',
                },
                review: {
                    icon: 'ios-paper',
                    btnText: '查看详情',
                    class: 'btn-default-style',
                },
                enable: {
                    icon: 'md-checkmark-circle',
                    btnText: '启用',
                    class: 'btn-default-style',
                },
                disable: {
                    icon: 'md-close-circle',
                    btnText: '停用',
                    class: 'btn-default-style',
                },
                audit: {
                    icon: 'md-git-compare',
                    btnText: '审核',
                    class: 'btn-default-style',
                },
                revoke: {
                    icon: 'ios-redo',
                    btnText: '撤销',
                    class: 'btn-default-style',
                },
                auditRecord: {
                    icon: 'md-git-network',
                    btnText: '审批详情',
                    class: 'btn-default-style',
                },
                terminal: {
                    icon: 'md-remove-circle',
                    btnText: '终止',
                    class: 'btn-default-style',
                },

                // 线框按钮
                reset: {
                    icon: 'md-refresh',
                    btnText: '重置',
                    class: 'btn-line-style',
                },
                close: {
                    icon: 'md-close-circle',
                    btnText: '关闭',
                    class: 'btn-line-style',
                },
                goBack: {
                    icon: 'ios-arrow-dropleft-circle',
                    btnText: '返回',
                    class: 'btn-line-style',
                },
                cancel: {
                    icon: 'md-close-circle',
                    btnText: '取消',
                    class: 'btn-line-style',
                },
                copy: {
                    icon: 'ios-copy',
                    btnText: '复制',
                    class: 'btn-line-style',
                },
                settlement: {
                    icon: 'md-checkmark-circle-outline',
                    btnText: '结算',
                    class: 'btn-primary-style',
                },
            },
        };
    },
    methods: {
        clickBtn(e) {
            e.stopPropagation();
            // 是否为返回 默认返回列表页
            if (this.isGoList && this.type === 'goBack') {
                const name = this.$route.name.replace(/-edit$/, '-list');

                try {
                    this.$router.push({ name });
                } catch (err) {
                    this.$router.go(-1);
                }
            }
            this.$emit('click', e);
        },
    },
};
</script>
