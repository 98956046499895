<!--主布局-->
<template>
    <div class="h-100">
        <template v-if="!noLayoutRouteList.includes($route.path)">
            <header class="g-header">
                <LayoutHeader
                    :is-collapsed="isMainCollapsed"
                    :width="mainCollapsedWidth"
                    :crumbs="$route.meta ? $route.meta.title : '--'"
                    @collapsedSider="$refs.SiderRef.toggleCollapse()"
                />
            </header>
            <main class="d-flex w-100 h-100">
                <aside class="aside">
                    <Sider
                        ref="SiderRef"
                        v-model="isMainCollapsed"
                        class="scroll main-sider"
                        :collapsed-width="mainCollapsedWidth"
                        collapsible
                        hide-trigger
                        :width="width"
                    >
                        <!--                    <SiderMenu :is-collapsed="isCollapsed" />-->
                        <MainMenu :width="width" :close-width="mainCollapsedWidth" :is-collapsed="isMainCollapsed"  @getChildren="getChildren" />
                        <div class="special-menus">
                            <div class="special-menu-item" @click="settingClick">
                                <CustomIcon type="setting" />
                            </div>
                            <div class="special-menu-item" @click="isMainCollapsed = !isMainCollapsed">
                                <CustomIcon :type="`${isMainCollapsed ? 'expand-menu' : 'close-menu'}`" />
                            </div>
                        </div>
                    </Sider>
                    <SubMainMenu v-show="false" :tree-data="treeData" />
                </aside>
                <article class="bg-grey flex-1 right-content">
                    <div class="d-flex content">
                        <Sider
                            v-model="isCollapsed"
                            class="bg-white right-10"
                            collapsible
                            hide-trigger
                            :collapsed-width="1"
                            width="138px"
                            style="border-right: 1px solid #efefef; z-index: 10;"
                        >
                            <SubMainMenu ref="subMainMenu" mode="default" :is-collapsed="isCollapsed" :tree-data="treeData" @isShow="isShow" />
                        </Sider>
                        <div
                            class="content-view bg-grey flex-1"
                            :class="{ 'is-collapsed': !isCollapsed, 'is-fixed-btn': isFixedBtn }"
                        >
                            <!-- <div class="g-page-title">
                                {{ $route.meta ? $route.meta.title : '--' }}
                            </div> -->
                            <slot />
                            <LayoutFooter v-if="!hideFooter" />
                        </div>
                    </div>
                </article>
            </main>
        </template>
        <template v-else>
            <slot />
        </template>
    </div>
</template>

<script>
import { LayoutFooter, LayoutHeader } from './';
import { Sider } from 'view-design';
import MainMenu from "./MainMenu";
import SubMainMenu from "./SubMainMenu";
import CustomIcon from "@/components/CustomIcon";
import { mapGetters } from 'vuex';

export default {
    name: 'MainLayout',
    components: {
        CustomIcon,
        SubMainMenu,
        MainMenu,
        Sider,
        // SiderMenu,
        LayoutFooter,
        LayoutHeader,
    },
    data() {
        return {
            isCollapsed: true,
            isMainCollapsed: false,
            width: '100px',
            mainCollapsedWidth: 40,
            treeData: {},
            noLayoutRouteList: ['/login']
        };
    },
    computed: {
        ...mapGetters({
            routers: 'comon/routers',
        }),
        isFixedBtn() {
            return this.$route.meta.isFixedBtn;
        },
        hideFooter() {
            return this.$route.meta.hideFooter;
        }
    },
    methods: {
        settingClick() {
            const setting = this.routers.find(item => item.path === '/setting');

            this.getChildren(setting);
        },
        getChildren(data) {
            this.treeData = data;
            this.isCollapsed = false;
            this.$nextTick(() => {
                this.$refs.subMainMenu.reset();
            });
        },
        isShow() {
            this.isCollapsed = !this.isCollapsed;
        }
    }
};
</script>

<style lang="less" scoped>
.right-content {
    height: calc(100% - 55px);
}
.content {
    margin: 0 10px 0 0;/* height: calc(100% - 60px - 34px - 34px - 20px); */
    height: 100%;
    .ivu-layout-sider {
        & + .content-view {
            min-width: 1022px;
        }
    }
    .ivu-layout-sider.ivu-layout-sider-collapsed {
        & + .content-view {
            min-width: 1160px;
        }
    }
}
</style>
