import httpRequest from "../utils/httpRequest";
const SERVER_NAME = 'entrance';
// const SERVER_NAME = '';

/**
 * 获取仓库列表
 * @param {Object} data
 */
export function getWarehouse(data) {
    return httpRequest({
        url: '/warehouse/queryList',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取仓库自动完成框列表
 * @param {Object} data
 */
export function getWarehouseAll(data) {
    return httpRequest({
        url: '/warehouse/queryAll',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    }, {
        closeErrorMessage: true
    });
}

/**
 * 获取仓库详情
 * @param {Object} params
 */
export function getWarehouseDetail(params) {
    return httpRequest({
        url: '/warehouse/get',
        serverName: SERVER_NAME,
        method: 'get',
        params,
    });
}

/**
 * 新增仓库
 * @param {*} data 
 */
export function addWarehouse(data) {
    return httpRequest({
        url: '/warehouse/add',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改仓库
 * @param {*} data 
 */
export function editWarehouse(data) {
    return httpRequest({
        url: '/warehouse/edit',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 禁用仓库
 * @param {stirng} warehouseId 仓库id
 *  @param {boolean} enableFlag 仓库状态
 */
export function disableWarehouse(data) {
    return httpRequest({
        url: '/warehouse/disable',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 仓库管理获取仓库列表
 * @param {Object} data
 */
export function queryPage(data) {
    return httpRequest({
        url: '/commonWarehouse/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}