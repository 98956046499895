<template>
    <Button v-bind="$attrs" :type="btnType" v-on="$listeners">
        <slot></slot>
        <CustomIcon :type="type" size="16" style="vertical-align: -1px;" />
    </Button>
</template>

<script>
import CustomIcon from "@/components/CustomIcon";
export default {
    name: "GlobalButton",
    components: {CustomIcon},
    props: {
        type: {
            type: String,
            default: null
        },
        btnType: {
            type: String,
            default: 'text'
        }
    },
};
</script>

<style scoped>

</style>
