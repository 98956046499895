<template>
    <ISelect v-bind="$props" v-on="$listeners"><slot /></ISelect>
</template>

<script>
import { Select as ISelect } from 'view-design';

export default {
    name: 'Select',
    components: { ISelect },
    props: ISelect.props
};
</script>
