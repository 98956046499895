<template>
    <ICol v-bind="$props" v-on="$listeners"><slot /></ICol>
</template>

<script>
import { Col as ICol } from 'view-design';

export default {
    name: 'Col',
    components: { ICol },
    props: ICol.props
};
</script>
