<template>
	<Select v-model="reactiveValue" v-bind="$attrs" filterable transfer v-on="$listeners">
		<Option v-if="isSearch" value="-1">全部</Option>
		<Option v-for="(item, index) in dictList" :key="index" :value="item.value">{{ item.name }}</Option>
	</Select>
</template>

<script>
import { isNotEmpty } from "../utils/func";

export default {
    name: "DictSelect",
    props: {
        dict: {
            type: String
        },
		  isSearch: {
			  type: Boolean,
			  default: false
		  },
	      value: {
		        type: [String, Array],
		        default: null
	      }
    },
    computed: {
    	dictList() {
    		if (isNotEmpty(this.dict)) return this.$comon.$dict(this.dict).filter(item => item.status) || [];
    		return [];
	    },
	    reactiveValue: {
		    set(val) {
			    this.$emit('input', val);
		    },
		    get() {
			    return this.value;
		    }
	    }
    }
};
</script>

<style scoped>

</style>
