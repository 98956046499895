import Store from "../plugins/Store";
import httpRequest from "../utils/httpRequest";
const SERVER_NAME = 'base';

// 角色管理
/**
 * 获取角色列表
 * @param {Object} data
 */
export function getRoleList(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/role/query',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取所有角色--新增员工时调用
 * @param {Object} data
 */
export function getRoleListAll(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/role/queryList',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取角色资源详情
 * @param {Object} data
 */
export function getRoleResourceDetail(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/resource/roleResourceTree',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 新增角色
 * @param {Object} data
 */
export function addRole(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/role/save',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改角色
 * @param {Object} data
 */
export function editRole(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/role/update',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取资源
 * @param {Object} params
 */
export function getResource(params = {}) {
    params.orgId = Store.getters['comon/userInfo'].orgId;
    params.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/resource/queryTree',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 删除角色
 * @param {Object} data
 */
export function delRole(params) {
    params.orgId = Store.getters['comon/userInfo'].orgId;
    params.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/role/delete',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

// 员工管理
/**
 * 获取员工
 * @param {Object} data
 */
export function getStaff(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/employee/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取所属部门下拉数据
 * @param {Object} data
 */
export function getDepartmentByStaff(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/department/queryTree',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取员工详情
 * @param {Object} params
 */
export function getStaffDetail(params = {}) {
    params.orgId = Store.getters['comon/userInfo'].orgId;
    params.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/employee/get',
        serverName: SERVER_NAME,
        method: 'get',
        params,
    });
}

/**
 * 获取员工列表-根据部门
 * @param {Object} params
 */
export function getStaffByDepartment(data= {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/department-employee/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 新增员工
 * @param {Object} data
 */
export function addStaff(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/employee/invitationEmployee',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改员工
 * @param {Object} data
 */
export function editStaff(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/employee/updateEmployee',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 启用/禁用员工
 * @param {Object} data
 */
export function enableStaff(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/employee/updateEmployeeEnable',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 删除员工
 * @param {Object} data
 */
export function delStaff(params) {
    params.orgId = Store.getters['comon/userInfo'].orgId;
    params.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/employee/delete',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

// 组织机构
/**
 * 部门树结构
 * @param {Object} data
 */
export function getDepartmentTree(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/department/queryTree',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

// 根据id查询下级部门
export function getDepartmentById(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/department/queryDepartmentById',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 新增部门
 * @param {Object} data
 */
export function addDepartment(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/department/save',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改部门
 * @param {Object} data
 */
export function editDepartment(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/department/update',
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 删除部门
 * @param {Object} data
 */
export function delDepartment(params) {
    params.orgId = Store.getters['comon/userInfo'].orgId;
    params.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/department/delete',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

//配置项
/**
 * 获取配置项列表
 * @param {Object} data
 */
export function getConfigList(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/config/queryPage',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 根据orgId获取配置项
 * @param {Object} data
 */
export function getConfigByOrgId(params = {}) {
    return httpRequest({
        url: 'config/queryByOrgId',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 新增配置项
 * @param {Object} data
 */
export function addConfig(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/config/save',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 修改配置项
 * @param {Object} data
 */
export function editConfig(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/config/save',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

//本方公司
/**
 * 获取本方公司列表
 * @param {Object} data
 */
export function getCompanyList(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    return httpRequest({
        url: '/org/queryConfig',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取本方公司详情
 * @param {Object} params
 */
export function getCompanyDetail(params = {}) {
    return httpRequest({
        url: '/org/get',
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 修改本方公司
 * @param {Object} data
 */
export function editCompany(data = {}) {
    return httpRequest({
        url: '/org/update',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取开票信息详情
 * @param {Object} params
 */
export function queryUnitTicketList(unitId) {
    return httpRequest({
        url: `/unitTicket/list/${unitId}`,
        serverName: SERVER_NAME,
        method: 'get'
    });
}

/**
 * 获取角色分配的数据权限
 * @param {Object} params
 */
export function getRoleData(data) {
    return httpRequest({
        url: `/roleData/getRoleData`,
        serverName: SERVER_NAME,
        method: 'post',
        data
    });
}

/**
 * 获取基础的数据权限
 * @param {Object} params
 */
export function getBaseRoleData(params) {
    return httpRequest({
        url: `/roleData/getBaseRoleData`,
        serverName: SERVER_NAME,
        method: 'get',
        params
    });
}

/**
 * 启用/禁用角色
 * @param {Object} data
 */
export function enableRole(data = {}) {
    data.orgId = Store.getters['comon/userInfo'].orgId;
    data.applicationId = Store.getters['comon/userInfo'].applicationId;
    return httpRequest({
        url: '/role/startAndStop',
        serverName: SERVER_NAME,
        method: 'post',
        data,
    });
}

/**
 * 获取流程是否启动
 * @param {Object} params
 */
export function getFlowByCode(params) {
    return httpRequest({
        url: `/redis/getByCode`,
        serverName: 'foundation',
        method: 'get',
        params
    });
}
