<template>
    <Menu :width="isCollapsed ? `${closeWidth}px` : width" theme="dark" class="main-menu left-menu">
        <MenuItem v-for="(item, index) in routers" v-show="!item.hide && item.path !== '/setting'" :key="index" :name="item.path">
            <div class="menu-item-box" @click="postChildren(item)">
                <Icon :custom="`iconfont ${item.icon || item.meta.icon}`" />
                <span v-if="!isCollapsed" class="menu-title">{{ item.title || item.meta.title || item.resourceName }}</span>
            </div>
        </MenuItem>
    </Menu>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "MainMenu",
    props: {
        width: {
            type: [String, Number],
            default: '240px'
        },
        closeWidth: {
            type: Number,
            default: 40
        },
        isCollapsed: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        ...mapGetters({
            routers: 'comon/routers',
            menuBtns: 'comon/menuBtns'
        }),
    },
    methods: {
        // emit子菜单
        postChildren(item) {
            if (Array.isArray(item.children)) {
                this.$emit('getChildren', item);
            }
        }
    },
};
</script>

<style scoped>

</style>
