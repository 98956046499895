import router, { resetRouter } from './Router';
import store from './Store';
import './ViewDesign';
import './globalComponents';

export default {
    router,
    store
};

export { router, store, resetRouter };
