<template>
    <Menu
        ref="menu"
        :active-name="activeName"
        :open-names="openNames"
        class="main-menu side-menu"
        :style="{ left: width, width: 'auto', position: mode === 'default' ? '': 'absolute' }">
        <div class="side-menu-box">
            <div v-if="!isCollapsed" class="side-menu-header" style="overflow: hidden">
                {{ treeData.title }}
            </div>
            <div class="sub-menu-content">
                <TreeMenu :children="treeData.children" is-top />
            </div>
            <div class="icon-box" @click="isShow">
                <svg v-if="!isCollapsed" id="icon-menu-open" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 15 50">
                    <g id="icon-menu-open_菜单栏" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="icon-menu-open_画板" transform="translate(-175.000000, -80.000000)">
                            <g id="icon-menu-open_编组备份" transform="translate(175.000000, 79.000000)">
                                <path id="icon-menu-open_形状结合" d="M0,0.807 L11.6575959,2.74867848 C13.5863478,3.07013713 15,4.73889757 15,6.69425418 L15,6.69425418 L15,45.3057458 C15,47.2611024 13.5863478,48.9298629 11.6575959,49.2513215 L11.6575959,49.2513215 L0,51.193 L0,50.1804604 L11.493197,48.2649276 L11.6719566,48.2295855 C13.0262734,47.9191629 14,46.7111584 14,45.3057458 L14,45.3057458 L14,6.69425418 L13.9945267,6.51211656 C13.910976,5.12519349 12.8794874,3.96612081 11.493197,3.73507241 L11.493197,3.73507241 L0,1.81953958 L0,0.807 Z" fill="#EBEDF2" fill-rule="nonzero"></path>
                                <path id="icon-menu-open_形状结合" d="M0,1.81953958 L11.493197,3.73507241 C12.8794874,3.96612081 13.910976,5.12519349 13.9945267,6.51211656 L14,6.69425418 L14,45.3057458 C14,46.7111584 13.0262734,47.9191629 11.6719566,48.2295855 L11.493197,48.2649276 L0,50.1804604 L0,1.81953958 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                                <polygon id="icon-menu-open_路径-5" fill="#1F2126" transform="translate(6.500000, 26.250000) scale(-1, 1) translate(-6.500000, -26.250000) " points="4 22 4 30.5 9 26"></polygon>
                            </g>
                        </g>
                    </g>
                </svg>
                <svg v-else id="icon-menu-retract" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 15 50">

                    <g id="icon-menu-retract_菜单栏" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="icon-menu-retract_画板" transform="translate(-99.000000, -80.000000)">
                            <g id="icon-menu-retract_编组备份-2" transform="translate(99.000000, 79.000000)">
                                <path id="icon-menu-retract_形状结合" d="M0,0.807 L11.6575959,2.74867848 C13.5863478,3.07013713 15,4.73889757 15,6.69425418 L15,6.69425418 L15,45.3057458 C15,47.2611024 13.5863478,48.9298629 11.6575959,49.2513215 L11.6575959,49.2513215 L0,51.193 L0,50.1804604 L11.493197,48.2649276 L11.6719566,48.2295855 C13.0262734,47.9191629 14,46.7111584 14,45.3057458 L14,45.3057458 L14,6.69425418 L13.9945267,6.51211656 C13.910976,5.12519349 12.8794874,3.96612081 11.493197,3.73507241 L11.493197,3.73507241 L0,1.81953958 L0,0.807 Z" fill="#EBEDF2" fill-rule="nonzero"></path>
                                <path id="icon-menu-retract_形状结合" d="M0,1.81953958 L11.493197,3.73507241 C12.8794874,3.96612081 13.910976,5.12519349 13.9945267,6.51211656 L14,6.69425418 L14,45.3057458 C14,46.7111584 13.0262734,47.9191629 11.6719566,48.2295855 L11.493197,48.2649276 L0,50.1804604 L0,1.81953958 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                                <polygon id="icon-menu-retract_路径-5" fill="#1F2126" points="5 22 5 30.5 10 26"></polygon>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    </Menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { deepCopy } from "@/utils/func";
import TreeMenu from "@/components/layout/TreeMenu";
export default {
    name: "SubMainMenu",
    components: {TreeMenu},
    provide() {
        return {
            rootMenu: this
        };
    },
    props: {
        width: {
            type: String,
            default: "100px",
        },
        treeData: {
            type: Object,
            default() {
                return {};
            }
        },
        mode: {
            type: String,
        },
        isCollapsed: {
            type: Boolean,
        }
    },
    data() {
        return {
            currentPath: null,
            activeName: null,
            openNames: []
        };
    },
    computed: {
        ...mapGetters({
            routers: 'comon/routers',
            menuBtns: 'comon/menuBtns'
        })
    },
    watch: {
        '$route.path': {
            handler(val) {
                const menu = this.getMenu(this.treeData.children);
                if (Array.isArray(menu)) {
                    let array = [];
                    menu.forEach(item => {
                        if (Array.isArray(item.children)) {
                            array = [...array, ...item.children];
                        }
                    });
                    const activeMenu = array.find(item => item.path === val);
                    if (activeMenu && !activeMenu.hide) {
                        this.activeName = activeMenu.path;
                    }
                }
            },
            deep: true
        }
    },
    methods: {
        /**
         * 过滤按钮
         * @param {Object[]} list
         * @return {Array}
         */
        getMenu(list) {
            if (Array.isArray(list)) return list.filter(tmp => tmp.resourceType !== '3' && !tmp.hide);
            return [];
        },
        setDefaultMenu() {
            let firstData;
            const menu = this.getMenu(this.treeData.children);
            if (Array.isArray(menu) && menu.length > 0) {
                if (!menu[0].children || menu[0].children.length === 0) {
                    firstData = menu[0];
                    this.activeName = firstData.path;
                    this.$router.push(firstData.path);
                }else {
                    const first = menu[0] || {};
                    firstData = first.children.find(tmp => !tmp.hide);
                    this.$router.push(firstData.path);
                }
                this.currentPath = firstData.path;
                this.activeName = firstData.path;
                this.openNames = menu.map(m => m.path);
                this.$store.commit('comon/setCurrentAuthBtn', firstData.children.filter(tmp => tmp.resourceType === '3'));
                this.$nextTick(this.$refs.menu.updateOpened);
            }
        },
        reset() {
            this.setDefaultMenu();
        },
        isShow() {
            this.$emit('isShow');
        },
        menuSelect(name) {
            this.currentPath= name;
            this.$router.push(name);
        },
        /**
         * 自定义节点渲染函数
         * @param {Vue.$createElement} h - 渲染函数
         * @param {Object} options - 节点信息
         * @param {Object} options.data - 当前节点内容
         */
        renderContent (h, options) {
            const { data } = options;
            return (
                <span
                    class={{
                        'ivu-tree-title-selected': data.path === this.currentPath,
                        'render-tree-content': !Array.isArray(data.children) || data.children.length === 0
                    }}
                    onClick={() => this.treeSelectChange(null, data)}>
                    {data.title}
                </span>);
        },
        /**
         * 展开和收起子列表
         * @param {Object} currentNode - 当前节点数据
         */
        treeToggleExpand(currentNode) {
            if (Array.isArray(this.treeData.children) && this.treeData.children.length > 0) {
                this.treeData.children.forEach(item => {
                    if (item.path === currentNode.path) {
                        this.$set(item, 'expand', !item.expand);
                    }
                });
            }
        },
        /**
         * 树节点选中回调
         * @param {Object[]} selection - 当前已选中的节点数组
         * @param {Object} currentNode - 当前项
         */
        treeSelectChange(selection, currentNode) {
            if (!currentNode) return;
            // 判断是不是末级节点, 不是的话则点击无响应
            if (Array.isArray(currentNode.children) && currentNode.children.length > 0) {
                return;
            }
            this.currentPath = currentNode.path;
            this.activeName = currentNode.path;
            this.$router.push(currentNode.path);
        },
        /**
         * 过滤隐藏菜单
         * @param {Object[]} menu - 菜单列表
         */
        getChildrenMenu(menu) {
            if (!Array.isArray(menu)) return menu;
            const list = deepCopy(menu);

            list.forEach(tmp => {
                if (Array.isArray(tmp.children)) {
                    tmp.children = deepCopy(tmp.children.filter(m => !m.hide).map(m => ({
                        ...m,
                        title: m.title || m.resourceName
                    })));
                }
            });
            return list.filter(m => !m.hide);
        },
    },
};
</script>

<style lang="less" scoped>
.icon-box {
    position: absolute;
    top: 50%;
    width: 17px;
    height: 50px;
    cursor: pointer;
    transform: translateY(-100%);
    right: -16px;
    background-color: transparent;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
}
.sub-menu-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 55px);
}
</style>
