<template>
    <div @click="remoteFocus">
        <OriginSelect
            ref="select"
            v-model="reactiveValue"
            filterable
            clearable
            transfer
            :loading="loading"
            :multiple="multiple"
            :allow-create="allowCreate"
            v-bind="$attrs"
            v-on="$listeners"
            @on-query-change="queryChange"
            @on-select="onSelect"
            @on-create="handleCreate"
        >
            <Option
                v-for="(item, index) in options"
                v-show="typeof filterFn === 'function' ? filterFn(item) : true"
                :key="index"
                :value="item[queryValue.value]"
                :label="item[queryValue.label]"
            />
        </OriginSelect>
    </div>
</template>

<script>
import queryEnum from "./queryEnum";
import { Select as OriginSelect } from "view-design";
import { debounce, isEmpty, isNotEmpty } from "@/utils/func";
import lodash from "lodash";
import { mapState } from "vuex";

export default {
    name: "RemoteSelect",
    components: {
        OriginSelect,
    },
    props: {
        value: {
            type: [String, Number, Array],
            default: null,
        },
        // 请求类型，根据枚举定义请求方法和回显字段
        queryType: {
            type: String,
            default: null,
        },
        // 是否允许用户创建条目
        allowCreate: {
            type: Boolean,
            default: false,
        },
        // 是否多选
        multiple: {
            type: Boolean,
            default: false,
        },
        // 额外参数
        exParams: {
            type: [Object, Function],
            default: null,
        },
        // 创建条目的最大长度
        maxlength: {
            type: Number,
            default: 0,
        },
        // 是否过滤掉已停用的数据
        useEnableFlag: {
            type: Boolean,
            default: false,
        },
        // 是否关注第一次加载
        useFirstLoad: {
            type: Boolean,
            default: true,
        },
        // 在创建词条模式下调用接口回显
        useCreateRequest: {
            type: Boolean,
            default: false,
        },
        // 过滤option的方法
        filterFn: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            options: [],
            selectedStatus: false,
            currentValue: null,
            shouldCreate: true,
            loading: false,
            isFirstLoad: true,
            oldExParams: null,
        };
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.comon.userInfo,
        }),
        queryValue() {
            if (!this.queryType) return queryEnum.unit;
            return queryEnum[this.queryType];
        },
        reactiveValue: {
            set(val) {
                this.$emit("input", val);
            },
            get() {
                // 处理初次输入时下标为-1的问题
                if (Array.isArray(this.value) && this.value["-1"]) {
                    return [this.value["-1"]];
                }
                return this.value;
            },
            cache: false,
        },
    },
    watch: {
        value: {
            /**
             * 值变化
             * @param {any} newVal
             * @param {any} oldVal
             */
            handler(newVal, oldVal) {
                const oldParams = JSON.stringify(this.oldExParams);
                const newParams = JSON.stringify(this.exParams);
                if (
                    this.allowCreate &&
                    this.useCreateRequest &&
                    isNotEmpty(newVal)
                ) {
                    this.currentValue = newVal;
                    if (this.queryValue.value !== this.queryValue.label) {
                        this.remoteMethod(null, newVal);
                    } else {
                        this.remoteMethod(newVal, null);
                    }
                    return;
                }
                if (
                    !this.allowCreate &&
                    this.options.find(
                        (m) => m[this.queryValue.value] === newVal
                    )
                )
                    return;
                if (
                    Array.isArray(newVal) &&
                    JSON.stringify(newVal) !== JSON.stringify(oldVal) &&
                    newVal.length > 0 &&
                    this.shouldCreate
                ) {
                    this.options = newVal.map((m) => {
                        return {
                            [this.queryValue.label]: m,
                            [this.queryValue.value]: m,
                        };
                    });
                    this.shouldCreate = false;
                }

                if (
                    (newVal !== oldVal || oldParams !== newParams) &&
                    isNotEmpty(newVal) &&
                    !Array.isArray(newVal) &&
                    !this.allowCreate
                ) {
                    // 当value改变时，list没有匹配值会导致value被置为null，所以此处需要缓存value，在请求完成后进行回填
                    this.currentValue = newVal;
                    this.remoteMethod(null, newVal);
                }
            },
            immediate: true,
        },
        exParams: {
            handler(newVal) {
                this.oldExParams = newVal;
            },
            deep: true,
        },
    },
    mounted() {
        if (this.maxlength !== 0) {
            const $input =
                this.$refs.select.$el.getElementsByClassName(
                    "ivu-select-input"
                )[0];

            $input.setAttribute("maxlength", this.maxlength);
        }
    },
    methods: {
        // 输入字符变更
        queryChange(query) {
            if (isEmpty(query)) {
                this.reactiveValue = null;
                this.callRemoteMethod(null, null, true);
            } else {
                this.callRemoteMethod(query, null, false);
            }
        },
        // 聚焦
        remoteFocus() {
            if (!this.useFirstLoad)
                return this.remoteMethod(this.queryWords, this.value, true);
            if (this.isFirstLoad) {
                this.remoteMethod(this.queryWords, this.value, true);
                this.isFirstLoad = false;
            }
        },
        // 点击选中回调
        onSelect(item) {
            const row =
                this.options.find(
                    (n) => n[this.queryValue.value] === item.value
                ) || {};

            this.selectedStatus = true;
            this.$emit("on-trigger-select", { ...row, ...item });
        },
        // 远程调用
        callRemoteMethod: debounce(function (query, id, isInit) {
            if (!query && !id && !isInit) return;
            if (this.allowCreate && this.useFirstLoad) return;
            if (this.selectedStatus) {
                this.selectedStatus = false;
                return;
            }
            this.remoteMethod(query, id);
        }, 500),
        /**
         * 远程搜索方法
         * @param {string | Null} [query] 搜索词
         * @param {string | Null} [id] 精确搜索用的id
         * @param {boolean} [isFocus] 是否来自点击聚焦
         */
        async remoteMethod(query, id, isFocus) {
            if (this.selectedStatus) {
                this.selectedStatus = false;
                return;
            }
            const exParams =
                    this.exParams instanceof Function
                        ? this.exParams()
                        : this.exParams,
                queryOption = { ...exParams };

            queryOption.orgId = this.userInfo.orgId;
            queryOption[this.queryValue.label] = query;
            queryOption[this.queryValue.value] =
                this.queryValue.label !== this.queryValue.value
                    ? id
                    : query || id;
            if (isFocus) {
                queryOption[this.queryValue.value] = null;
            }
            queryOption.pageNum = 1;
            queryOption.pageSize = 20;
            this.loading = true;
            const res = await this.queryValue
                .method(queryOption)
                .finally(() => {
                    this.loading = false;
                });
            let data;
            if (this.queryValue.resultGetPath) {
                data = lodash.get(res, this.queryValue.resultGetPath, []);
            } else {
                data = [...res.data];
            }

            if (!Array.isArray(data)) return;
            if (!this.allowCreate) {
                this.reactiveValue = id;
            }
            // 截断防止未分页
            data = data.slice(0, 20);
            // 判断创建模式下的回显
            if (data.length === 0 && this.allowCreate) {
                this.options.push({
                    [this.queryValue.value]: this.value,
                    [this.queryValue.label]: this.value,
                    _remoteLabel: this.value,
                    _remoteValue: this.value,
                });

                return;
            }
            // 过滤掉已停用的选项
            if (this.useEnableFlag) {
                data = data.filter((item) => {
                    return (
                        item.enableFlag ||
                        item[this.queryValue.value] === this.reactiveValue
                    );
                });
            }
            this.options = data.map((m) => {
                return {
                    ...m,
                    _remoteLabel: m[this.queryValue.label],
                    _remoteValue: m[this.queryValue.value],
                };
            });
            const arr = [];

            this.options = this.options.filter((item) => {
                const has = arr.includes(item._remoteLabel);

                if (!has) {
                    arr.push(item._remoteLabel);
                }
                return !has;
            });
            // 当value改变时，list没有匹配值会导致value被置为null，所以此处需要重新回填value
            // this.reactiveValue = this.currentValue;
            const findData = this.options.find(
                (m) => m[this.queryValue.value] === this.reactiveValue
            );

            if (findData) {
                this.$emit("getItem", findData);
            }
        },

        /**
         * 创建条目回调
         * @param {string} value
         */
        handleCreate(value) {
            if (!this.allowCreate) return;
            const key = this.queryValue.value;

            if (!this.options.find((m) => m[key] === value)) {
                this.options.push({
                    [this.queryValue.value]: value,
                    [this.queryValue.label]: value,
                });
                // 此处延迟设置值, 避免与内部回调冲突导致设置失败
                setTimeout(() => {
                    if (Array.isArray(this.reactiveValue)) {
                        this.$set(
                            this.reactiveValue,
                            this.reactiveValue.length - 1,
                            value
                        );
                    } else {
                        this.reactiveValue = value;
                    }
                    this.$emit("on-remote-change", value);
                    this.$emit("on-remote-create", value);
                }, 100);
            }
        },
    },
};
</script>
